import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import config from "../../../../config";
import toasts from "../../../../utils/toast";
import useFetch from "../../../../hooks/useFetch";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

let courseApi = config.endpoints.api.course;

type courseType = {
  label: string;
  value: string;
};

type PropsType = {
  data?: {
    id: number;
    title: string;
    module_id: string;
    amount: number;
    no_of_tests: number;
    description: string;
    start_date: string;
    is_premium:boolean;
    is_questionSet: boolean;
  };
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const TestSeriesForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const {
    reset,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data?.id,
        title: data && data?.title,
        module_id: data && data?.module_id,
        amount: data && data?.amount,
        no_of_tests: data && data?.no_of_tests,
        description: data && data?.description,
        start_date: data && moment(data?.start_date).format("yyyy-MM-DD"),
        is_premium: data && data?.is_premium,
        is_questionSet: data && data?.is_questionSet
      }
    }
  );

  const handleReset = () => {
    setSelectedCourse(undefined);
    setValue("amount", undefined);
    setValue("no_of_tests", undefined);
    reset({
      title: "",
      module_id: "",
      description: "",
      start_date: "",
      is_premium:false,
      is_questionSet:false
    });
  };

  const handleClear = () => {
    handleReset();
  };

  const handleSelectChange = (data, e) => {
    setSelectedCourse(data);
    setValue("module_id", data.value);
  };

  const {
    error: courseError,
    fetchedData: fetchedCourse,
    fetchNewData: fetchNewCourse
  } = useFetch();

  courseError && toasts.error("Something went wrong!");

  const watchCourse = watch("module_id");

  const [coursesOptions, setCourseOptions] = useState<courseType[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<any>(
    editform && data && data.module_id
  );

  const booleanOptions = [
    {
      label: 'TRUE',
      value: true
    },
    {
      label: 'FALSE',
      value: false
    },
  ]

  const [isPremium, setIsPremium] = useState({
    label: 'FALSE',
    value: false
  });
  const [isQuestionSet, setIsQuestionSet] = useState({
    label: 'FALSE',
    value: false
  });

  useEffect(()=>{
    if(editform){
      if(data.is_premium){
        setValue("is_premium", true);
        setIsPremium({
          label: 'TRUE',
          value: true
        });
      }else{
        setValue("is_premium", false);
        setIsPremium({
          label: 'FALSE',
          value: false
        });
      }
      if(data.is_questionSet){
        setValue("is_questionSet", true);
        setIsQuestionSet({
          label: 'TRUE',
          value: true
        });
      }else{
        setValue("is_questionSet", false);
        setIsQuestionSet({
          label: 'FALSE',
          value: false
        });
      }
    }
  },[data])

  const convertToOptions = lists => {
    let options: { value: string; label: string }[] = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);
        setValue("module_id", options[0].value);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
          setValue("module_id", course?.value);
        }
      } else {
        setCourseOptions([]);
        setValue("module_id", undefined);
      }
    }
  }, [fetchedCourse, setValue, data, editform]);  

  return (
    <form
      className="live-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Course <span>*</span>
              </label>
              <CustomSelect
                id="module_id"
                required={true}
                register={register}
                name="module_id"
                value={selectedCourse || ""}
                handleChange={(e, data) => {
                  setValue("module_id", data.value);
                  handleSelectChange(e, data);
                }}
                options={coursesOptions}
                disabled={false}
              />
              {errors?.module_id?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate title">
              <InputField
                required
                type="text"
                label="Series Title"
                placeholder={"Enter the series title"}
                {...register("title", {
                  required: true
                })}
              />
              {errors?.title?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Is Premium <span>*</span>
              </label>
              <CustomSelect
                id="is_premium"
                required={false}
                register={register}
                name="is_premium"
                value={isPremium}
                handleChange={(e, data) => {
                  setValue("is_premium", e.value);
                  setIsPremium(e);
                }}
                options={booleanOptions}
                disabled={false}
              />
            </div>
            <div className="fieldAndValidate title">
            <label htmlFor="course">
                Is Question Set <span>*</span>
              </label>
              <CustomSelect
                id="is_questionSet"
                required={false}
                register={register}
                name="is_questionSet"
                value={isQuestionSet}
                handleChange={(e, data) => {
                  setValue("is_questionSet", e.value);
                  setIsQuestionSet(e);
                }}
                options={booleanOptions}
                disabled={false}
              />
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate period">
              <InputField
                required
                type="number"
                label="Amount"
                placeholder={"Enter the Amount"}
                {...register("amount", {
                  required: true
                })}
              />
              {errors?.amount?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate title">
              <InputField
                required
                type="number"
                label="Number of tests"
                placeholder={"Enter the Number of tests."}
                {...register("no_of_tests", {
                  required: true
                })}
              />
              {errors?.no_of_tests?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>

          <div className="row-container">
            <div className="fieldAndValidate date">
              <InputField
                disabled={false}
                required
                label="Start Date"
                type="date"
                {...register("start_date", {
                  required: true
                })}
              />
              {errors?.start_date?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate title">
              <InputField
                required
                type="text"
                label="Series Description"
                placeholder={"Enter the series description"}
                {...register("description", {
                  required: false
                })}
              />
              {errors?.description?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading ? "Submitting" : editform ? "Update Series" : "Add Series"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default TestSeriesForm;
