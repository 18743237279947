import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Feedback from "../pages/Feedback/Feedback";

import { getAccessToken } from "../services/token";

export default function SubscriptionRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Feedback />} />
    </Routes>
  );
}
