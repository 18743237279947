import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import config from "../../../config";
import "./ProgressBar.scss";
import http from "../../../utils/http";
import { Icon } from "@iconify/react";

interface ProgressProps {
  cashGatewayCount: number;
  khaltiGatewayCount: number;
  esewaGatewayCount: number;
}

const ProgressBar = () => {
  const [gatewayCount, setGatewayCount] = useState<ProgressProps>({
    cashGatewayCount: 0,
    khaltiGatewayCount: 0,
    esewaGatewayCount: 0
  });

  const apiUrl = `${config.baseURI}/api/v1/count/dataCount`;

  useEffect(() => {
    http.GET(apiUrl).then(response => {
      let gateway = response.data.data;
      setGatewayCount(gateway);
    });
  }, []);

  if (!gatewayCount) {
    return <Spin tip="Loading" size="large" />;
  }

  let cashPaidUsers = gatewayCount?.cashGatewayCount;
  let khaltiPaidUsers = gatewayCount?.khaltiGatewayCount;
  let esewaPaidUsers = gatewayCount?.esewaGatewayCount;
  let totalPaidUsers = cashPaidUsers + khaltiPaidUsers + esewaPaidUsers;

  let cashPaidUsersPercentage = Math.round(
    (cashPaidUsers / totalPaidUsers) * 100
  );
  let khaltiPaidUsersPercentage = Math.round(
    (khaltiPaidUsers / totalPaidUsers) * 100
  );
  let esewaPaidUsersPercentage = Math.round(
    (esewaPaidUsers / totalPaidUsers) * 100
  );

  return (
    <div className="progress_bar">
      <h6>Payment Gateway Users</h6>
      <div className="progress" style={{ height: "5px", width: "95%" }}>
        <div
          className="progress-bar "
          role="progressbar"
          style={{
            width: `${cashPaidUsersPercentage}%`,
            backgroundColor: "#fcd34d"
          }}
          aria-valuenow={cashPaidUsers}
          aria-valuemin={0}
          aria-valuemax={totalPaidUsers}
        ></div>
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${khaltiPaidUsersPercentage}%`,
            backgroundColor: "#6b21a8"
          }}
          aria-valuenow={khaltiPaidUsers}
          aria-valuemin={0}
          aria-valuemax={totalPaidUsers}
        ></div>
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${esewaPaidUsersPercentage}%`,
            backgroundColor: "#22c55e"
          }}
          aria-valuenow={esewaPaidUsers}
          aria-valuemin={0}
          aria-valuemax={totalPaidUsers}
        ></div>
      </div>
      <section className="legend_container">
        <div>
          <Icon
            icon="radix-icons:dot-filled"
            color="#fcd34d"
            width="20"
            height="20"
          />
          <span>Cash {cashPaidUsers}</span>
        </div>
        <div>
          <Icon
            icon="radix-icons:dot-filled"
            color="#6b21a8"
            width="20"
            height="20"
          />
          <span>Khalti {khaltiPaidUsers}</span>
        </div>
        <div>
          <Icon
            icon="radix-icons:dot-filled"
            color="#22c55e"
            width="20"
            height="20"
          />
          <span>Esewa {esewaPaidUsers}</span>
        </div>
      </section>
    </div>
  );
};

export default ProgressBar;
