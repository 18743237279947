import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import CsvDownload from "react-json-to-csv";
import DataTable from "react-data-table-component";

import config from "../../../config";
import http from "../../../utils/http";

import "./QuizResult.scss";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const customStyles = {
  headRow: {
    style: {
      height: "30px",
      padding: "0px",
      minHeight: "15px !important"
    }
  },

  headCells: {
    style: {
      color: "#202124",
      fontSize: "12px",
      fontWeight: "600"
    }
  },

  rows: {
    highlightOnHoverStyle: {
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF",
      backgroundColor: "rgb(231,235,244)"
    },

    style: {
      fontSize: "11px",
      minHeight: "30px !important"
    }
  },

  pagination: {
    style: {
      right: 0,
      bottom: "10px",
      fontSize: "12px",
      minHeight: "10px",
      position: "absolute"
    }
  }
};

function QuizResult({ testId, selectedCourse }) {
  let { url } = {
    moduleId: selectedCourse,
    url: `${config.baseURI}/api/v1/${selectedCourse}`
  };

  const [test, setTest] = useState([]);
  const [loading, setLoading] = useState(null);
  const [csvData, setCsvData] = useState(null);

  const columns = [
    {
      name: "Rank",
      cell: (row, index) => <div> {row.rank} </div>,
      width: "93px"
    },

    {
      name: "Student",
      cell: row => <p className="font-weight-bold mb-0">{row.user.name}</p>,
      width: "203px"
    },

    {
      name: "Correct",
      cell: row => row.cntCorrect,
      sortable: true,
      width: "120px"
    },

    {
      name: "Wrong",
      cell: row => row.cntWrong,
      width: "120px"
    },

    {
      name: "Attempted",
      sortable: true,
      cell: row => <div> {row.cntCorrect + row.cntWrong} </div>,
      width: "128px"
    }

    // {
    //   name: "Open",
    //   cell: row => (
    //     <Link
    //       to={`/${moduleId}/teacher/test/userResult?testId=${testId}&userId=${row.user.id}`}
    //     >
    //       <button className="btn btn-outline-dark m-0 py-0">
    //         <i className="far fa-eye"></i>
    //       </button>
    //     </Link>
    //   ),

    //   width: "65px"
    // }
  ];

  const paginationComponentOptions = {
    rowsPerPage: 15
  };

  useEffect(() => {
    setLoading(true);

    http.GET(`${url}/test/result?testId=${testId}`).then(res => {
      if (res.data && res.data.testResults) {
        let csvData = [];
        res.data.testResults = res.data.testResults.map((test, index) => {
          csvData.push({
            Rank: index + 1,
            ResultId: test.id,
            Name: test.user.name,
            Correct: test.cntCorrect,
            Wrong: test.cntWrong,
            Attempted: test.cntCorrect + test.cntWrong
          });
          return { ...test, rank: index + 1 };
        });
        setCsvData(csvData);
        setTest(res.data);
      } else {
        setCsvData([]);
        setTest({ testResults: [] });
      }
      setLoading(false);
    });
  }, [url, testId]);

  return test ? (
    <div className="row result_table">
      <div className="result_table_header">
        <h5 style={{ fontWeight: 600 }}>{test.name} Results</h5>

        <div className="data_export">
          <CsvDownload
            data={csvData}
            filename={`${test.name}-Result.csv`}
            className="btn btn-outline-dark"
          >
            <div className="d-flex justify-content-row">
              <i
                className="bx bx-export"
                style={{
                  fontSize: "18px"
                }}
              ></i>
              &nbsp; Export
            </div>
          </CsvDownload>
        </div>
      </div>

      <div className="result_table_container">
        <DataTable
          pointerOnHover
          highlightOnHover
          pagination={true}
          columns={columns}
          fixedHeader={true}
          expandOnRowClicked
          expandableRows={false}
          paginationPerPage={14}
          data={test.testResults}
          progressPending={loading}
          customStyles={customStyles}
          fixedHeaderScrollHeight={"32.4rem"}
          paginationComponentOptions={paginationComponentOptions}

          // ref={dRef}
          // noHeader={true}
          // paginationServer
          // theme="solarized"
          // paginationRowsPerPageOptions={["13", "26"]}
        />
      </div>
    </div>
  ) : (
    <p>Loading...</p>
  );
}

export default withRouter(QuizResult);
