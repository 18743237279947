import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import config from "../../../config";
import http from "../../../utils/http";
import toast from "../../../utils/toast";

import conditions from "../../../utils/uploadImageAudioConditions";

import "./AddQuizQuestion.scss";
import Button from "../../../components/Button/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import TextImageInput from "../../../components/TextImageInput/TextImageInput";
import InputField from "../../../components/InputField/InputField";

const quizChoices = [
  { value: "A", label: "Option A" },
  { value: "B", label: "Option B" },
  { value: "C", label: "Option C" },
  { value: "D", label: "Option D" }
];

function AddQuizQuestion() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const testId = state.testId;
  const selectedCourse = state.selectedCourse;

  let { url } = {
    url: `${config.baseURI}/api/v1/${selectedCourse}`
  };

  const [test, setTest] = useState();
  const [questions, setQuestions] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const total = test && test.total;
  const activeQuestion = questions && questions[activeIndex];

  const uploadQuestion = async (question, index) => {
    let dir = "testQuestions";

    //question
    let qCondition = conditions.conditions(
      question.question,
      index,
      dir,
      testId,
      "question"
    );
    console.log(qCondition.image, "image=====56");
    question.question = qCondition.text;
    question.questionImage = qCondition.image;
    question.questionAudio = qCondition.audio;

    //optionA
    let aCondition = conditions.conditions(
      question.optionA,
      index,
      dir,
      testId,
      "optionA"
    );
    question.optionA = aCondition.text;
    question.aImage = aCondition.image;
    question.aAudio = aCondition.audio;

    //optionB
    let bCondition = conditions.conditions(
      question.optionB,
      index,
      dir,
      testId,
      "optionB"
    );
    question.optionB = bCondition.text;
    question.bImage = bCondition.image;
    question.bAudio = bCondition.audio;

    //optionC
    let cCondition = conditions.conditions(
      question.optionC,
      index,
      dir,
      testId,
      "optionC"
    );
    question.optionC = cCondition.text;
    question.cImage = cCondition.image;
    question.cAudio = cCondition.audio;

    //optionD
    let dCondition = conditions.conditions(
      question.optionD,
      index,
      dir,
      testId,
      "optionD"
    );
    question.optionD = dCondition.text;
    question.dImage = dCondition.image;
    question.dAudio = dCondition.audio;

    // description
    let desCondition = conditions.conditions(
      question.description,
      index,
      dir,
      testId,
      "description"
    );
    question.description = desCondition.text;
    question.descriptionImage = desCondition.image;
    question.descriptionAudio = desCondition.audio;

    question.testId = testId;
    setUploading(true);

    await http
      .POST(`${url}/teacher/test/question`, JSON.stringify(question))
      .then(res => {
        let question = res.data.question;
        question.optionA = {
          text: question.optionA
            ? question.optionA.split("<br>")[0]
            : question.optionA,
          image: question.aImage,
          audio: question.aAudio
        };
        question.optionB = {
          text: question.optionB
            ? question.optionB.split("<br>")[0]
            : question.optionB,
          image: question.bImage,
          audio: question.bAudio
        };
        question.optionC = {
          text: question.optionC
            ? question.optionC.split("<br>")[0]
            : question.optionC,
          image: question.cImage,
          audio: question.cAudio
        };
        question.optionD = {
          text: question.optionD
            ? question.optionD.split("<br>")[0]
            : question.optionD,
          image: question.dImage,
          audio: question.dAudio
        };
        question.question = {
          text: question.question
            ? question.question?.split("<br>")[0]
            : question.question,
          image: question.questionImage,
          audio: question.questionAudio
        };

        question.description = {
          text: question.description
            ? question.description?.split("<br>")[0]
            : question.description,
          image: question.descriptionImage,
          audio: question.descriptionAudio
        };

        let newQuestions = questions.map(q => {
          if (q.index === index) {
            question.index = q.index;
            return question;
          }
          return q;
        });
        toast.success("Question updated in draft.");
        setUploading(false);
        setQuestions(newQuestions);
      })
      .catch(err => {
        let errMsg = String(err?.response?.data?.error);
        toast.error(errMsg);
        setUploading(false);
      });
  };

  const finish = () => {
    setFinishing(true);

    http
      .POST(`${url}/teacher/test/finish?testId=${test.id}`)
      .then(res => {
        setFinishing(false);
        toast.success("Quiz scheduled successfully");
        navigate("../");
      })
      .catch(err => {
        toast.error("Something went wrong", err);
      });
  };

  const previous = () => {
    activeIndex > 0 && setActiveIndex(activeIndex - 1);
  };

  const next = () => {
    activeIndex < total - 1 && setActiveIndex(activeIndex + 1);
  };

  const jump = index => {
    index >= 0 && index < total && setActiveIndex(index);
  };

  useEffect(() => {
    http.GET(`${url}/teacher/test/question?testId=${testId}`).then(res => {
      let test = res.data.newTest;
      setTest(test);
      let questions = test && test.questions;
      if (questions) {
        let newQuestions = Array(test.total)
          .fill(null)
          .map((_, index) => {
            questions[index] && (questions[index].index = index);
            return questions[index] || { index };
          });
        setActiveIndex(0);
        setQuestions(newQuestions);
      }
    });
  }, [testId, url]);

  return (
    <div className="row add_questions_page">
      <div className="col question_form_container">
        {test && activeQuestion && (
          <QuestionForm
            test={test}
            activeQuestion={activeQuestion}
            next={next}
            previous={previous}
            uploadQuestion={uploadQuestion}
            uploading={uploading}
            last={activeIndex === questions.length - 1}
            finish={finish}
            finishing={finishing}
          />
        )}
      </div>

      <div className="navigator">
        {questions && (
          <TestNavigator
            questions={questions}
            activeIndex={activeIndex}
            jump={jump}
          />
        )}
      </div>
    </div>
  );
}

function QuestionForm({
  test,
  activeQuestion,
  uploading,
  previous,
  next,
  uploadQuestion,
  last,
  finish,
  finishing
}) {
  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isDirty, errors }
  } = useForm({
    defaultValues: activeQuestion
  });

  let { index, id, answer, qmark, negative_mark } = activeQuestion;
  let { name, duration, start, type, total } = test;

  const [choiceOptions, setChoiceOptions] = useState([]);
  const [selectedChoice, setSelectedChoice] = useState();

  const watchQuestImage = watch("question.image");
  const watchOptionAImage = watch("optionA.image");
  const watchOptionBImage = watch("optionB.image");
  const watchOptionCImage = watch("optionC.image");
  const watchOptionDImage = watch("optionD.image");
  const watchDescImage = watch("description.image");

  const watchQuestAudio = watch("question.audio");
  const watchOptionAAudio = watch("optionA.audio");
  const watchOptionBAudio = watch("optionB.audio");
  const watchOptionCAudio = watch("optionC.audio");
  const watchOptionDAudio = watch("optionD.audio");
  const watchDescAudio = watch("description.audio");

  const [ImageWatch, setImageWatch] = useState();
  const [ImageRemove, setImageRemove] = useState(false);
  const [audioWatch, setAudioWatch] = useState();
  const [audioRemove, setAudioRemove] = useState(false);

  useEffect(() => {
    setImageWatch(
      (watchQuestImage && typeof watchQuestImage !== typeof "") ||
      (watchOptionAImage && typeof watchOptionAImage !== typeof "") ||
      (watchOptionBImage && typeof watchOptionBImage !== typeof "") ||
      (watchOptionCImage && typeof watchOptionCImage !== typeof "") ||
      (watchOptionDImage && typeof watchOptionDImage !== typeof "") ||
      (watchDescImage && typeof watchDescImage !== typeof "")
    );
  }, [
    watchQuestImage,
    watchOptionAImage,
    watchOptionBImage,
    watchOptionCImage,
    watchOptionDImage,
    watchDescImage
  ]);

  useEffect(() => {
    setAudioWatch(
      (watchQuestAudio && typeof watchQuestAudio !== typeof "") ||
      (watchOptionAAudio && typeof watchOptionAAudio !== typeof "") ||
      (watchOptionBAudio && typeof watchOptionBAudio !== typeof "") ||
      (watchOptionCAudio && typeof watchOptionCAudio !== typeof "") ||
      (watchOptionDAudio && typeof watchOptionDAudio !== typeof "") ||
      (watchDescAudio && typeof watchDescAudio !== typeof "")
    );
  }, [
    watchQuestAudio,
    watchOptionAAudio,
    watchOptionBAudio,
    watchOptionCAudio,
    watchOptionDAudio,
    watchDescAudio
  ]);

  const testDate = useMemo(
    () => moment(start).local().format("MMMM DD, YYYY - HH:mm"),
    [start]
  );

  useEffect(() => {
    setValue("question.text", "");
    setValue("optionA.text", "");
    setValue("optionB.text", "");
    setValue("optionC.text", "");
    setValue("optionD.text", "");
    setValue("answer.text", "");
    setSelectedChoice("");
    setValue("description.text", "");
  }, [activeQuestion.id, setValue]);

  useEffect(() => {
    reset(activeQuestion);
  }, [activeQuestion, reset]);

  useEffect(() => {
    setChoiceOptions(quizChoices);
    if (answer) {
      let choice = quizChoices.find(item => {
        if (item.value === answer.trim().toUpperCase()) return item;
        return null;
      });
      setSelectedChoice(choice);
      setValue("answer", answer);
    }
  }, [activeQuestion, answer, reset, setValue]);

  const handleQuestion = question => {
    setImageWatch(false);
    setImageRemove(false);
    setAudioWatch(false);
    setImageRemove(false);
    question.id = id;
    uploadQuestion(question, index);
  };

  const handleChoiceChange = (data, e) => {
    setSelectedChoice(data);
    setValue("answer", data.value, { shouldDirty: true });
  };

  const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <form onSubmit={handleSubmit(handleQuestion)} className="add_question_form">
      <div className="form_header">
        <div className="left_header">
          <h6>
            Question {index + 1} of {total}
          </h6>

          <ul className="details list-inline text-muted">
            <li className="list-inline-item">
              <i className="far fa-clock" />
              <span>{duration} Mins</span>
            </li>

            {/* <li className="list-inline-item">
              <i className="fas fa-bullseye" />
              <span>{mark} Marks</span>
            </li> */}

            <li className="list-inline-item">
              <i className="fas fa-desktop" />
              <span>{total} Questions</span>
            </li>

            <li className="list-inline-item">
              <i className="fas fa-users" />
              <span>{capitalize(type)}</span>
            </li>
          </ul>
        </div>

        <div className="right_header">
          <h6 className="font-weight-bold float-right">{name}</h6>
          <ul className="details list-inline text-muted">
            <li className="list-inline-item float-right">
              <i className="far fa-calendar-alt" />
              <span>{testDate}</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="form_body">
        <div className="question">
          <label>Question :</label>
          <TextImageInput
            id={id}
            rows={5}
            name="question"
            placeholder="Enter your question here."
            setValue={setValue}
            register={register}
            imagePreview={activeQuestion.questionImage}
            audioPreview={activeQuestion.questionAudio}
            setImageRemove={setImageRemove}
            setAudioRemove={setAudioRemove}
          />
        </div>

        <div className="options_container">
          <label>Options : </label>
          <div className="options">
            <TextImageInput
              id={id}
              rows={1}
              option="A"
              name="optionA"
              placeholder="Option A."
              setValue={setValue}
              register={register}
              imagePreview={activeQuestion.aImage}
              audioPreview={activeQuestion.aAudio}
              setImageRemove={setImageRemove}
              setAudioRemove={setAudioRemove}
            />
            <TextImageInput
              id={id}
              rows={1}
              option="B"
              name="optionB"
              placeholder="Option B."
              setValue={setValue}
              register={register}
              imagePreview={activeQuestion.bImage}
              audioPreview={activeQuestion.bAudio}
              setImageRemove={setImageRemove}
              setAudioRemove={setAudioRemove}
            />
            <TextImageInput
              id={id}
              rows={1}
              option="C"
              name="optionC"
              placeholder="Option C."
              setValue={setValue}
              register={register}
              imagePreview={activeQuestion.cImage}
              audioPreview={activeQuestion.cAudio}
              setImageRemove={setImageRemove}
              setAudioRemove={setAudioRemove}
            />
            <TextImageInput
              id={id}
              rows={1}
              option="D"
              name="optionD"
              placeholder="Option D."
              setValue={setValue}
              register={register}
              imagePreview={activeQuestion.dImage}
              audioPreview={activeQuestion.dAudio}
              setImageRemove={setImageRemove}
              setAudioRemove={setAudioRemove}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="fieldAndValidate course">
            <label htmlFor="answer">
              Which option is the correct answer? <span>*</span>
            </label>
            <CustomSelect
              id="answer"
              required={true}
              register={register}
              name="answer"
              value={selectedChoice || ""}
              handleChange={(e, data) => {
                handleChoiceChange(e, data);
              }}
              options={choiceOptions}
            />
            {errors?.answer?.type === "required" && (
              <p>This field is required</p>
            )}
          </div>
          <div className="text-danger ml-2">
            {errors.answer && errors.answer.message}
          </div>
        </div>

        <div className="fieldAndValidate description">
          <label htmlFor="description">Description :</label>
          <TextImageInput
            id={id}
            rows={7}
            name="description"
            placeholder="Explain the answer here."
            setValue={setValue}
            register={register}
            imagePreview={activeQuestion.descriptionImage}
            audioPreview={activeQuestion.descriptionAudio}
            setImageRemove={setImageRemove}
            setAudioRemove={setAudioRemove}
          />
        </div>

        <div className="row-container" style={{ justifyContent: "space-between", gap: "10px" }}>
          <div className="fieldAndValidate">
            <label htmlFor="description">Mark :</label>
            <InputField
              type="number"
              min="0"
              placeholder={"Enter the mark for this question."}
              {...register("qmark", {
                required: true
              })}
            />
            {errors?.mark?.type === "required" && (
              <p>This field is required</p>
            )}
          </div>
          {
            <div className="fieldAndValidate title">
              <label htmlFor="description">Negative Mark :</label>
              <InputField
                step="any"
                type="number"
                min="0"
                placeholder={"Enter the neagtive mark."}
                {...register("negative_mark", {
                  required: true
                })}
              />
              {errors?.negative_mark?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          }
        </div>
      </div>

      <div className="form_action">
        {isDirty || ImageWatch || ImageRemove || audioWatch || audioRemove ? (
          <Button
            type="submit"
            buttonName={uploading ? "Saving..." : "Save Draft"}
            iconName="bx bx-plus-medical"
          />
        ) : (
          <div className="double_button">
            <Button
              type="button"
              disabled={!index}
              color="danger"
              buttonName="Previous"
              iconName="bx bxs-left-arrow"
              clickHandler={previous}
            />

            {!last ? (
              <Button
                type="button"
                color="success"
                buttonName="Next"
                iconRight="bx bxs-right-arrow"
                clickHandler={next}
              />
            ) : (
              <Button
                type="button"
                color="success"
                iconRight="bx bxs-right-arrow"
                buttonName={!finishing ? "Finish" : "Finishing.."}
                clickHandler={finish}
              />
            )}
          </div>
        )}
      </div>
    </form>
  );
}

function TestNavigator({ questions, activeIndex, jump }) {
  const navigate = useNavigate();
  return (
    <div className="quiz_navigator">
      <div className="navigator_header">
        <div className="flex-column">
          <h6>Quiz Navigator</h6>
          <p className="text-muted">Click box below to jump to the question.</p>
        </div>
        <div className="back_button">
          <Button
            type="button"
            buttonName="< Back"
            color="success"
            clickHandler={() => {
              navigate("../");
            }}
          />
        </div>
      </div>

      <div className="question_link_list">
        {questions.map((each, index) => {
          let color = "light";
          each.question && (color = "done");
          activeIndex === index && (color = "active");

          return (
            <div
              key={index}
              onClick={() => jump(index)}
              className={`question_link ${color}`}
            >
              <p>{index + 1}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AddQuizQuestion;
