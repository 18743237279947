import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../config";
import http from "../../utils/http";
import toast from "../../utils/toast";

import Button from "../../components/Button/Button";
import ReferralForm from "../Referral/components/referralForm/ReferralForm";

import "./AddReferral.scss";

let referralApi = config.endpoints.api.referral;

const AddReferral = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = data => {
    setIsLoading(true);

    data.hasLimit = Boolean(data.hasLimit);
    data.code = data.code.toUpperCase();

    http
      .POST(referralApi.create(data.course), data)
      .then(() => {
        toast.success("Code Added Successfully!");
        navigate("../");
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Referral Codes | Add Code</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_referral_form">
        <ReferralForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AddReferral;
