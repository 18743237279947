import React, { useState, useEffect } from "react";

import config from "../../../../config";
import http from "../../../../utils/http";
import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
// import Textarea from "../../../../components/Textarea/Textarea";
import ImageInput from "../../../../components/ImageInput/ImageInput";
import InputField from "../../../../components/InputField/InputField";
import RichTextarea from "../../../../components/Textarea/RichTextarea";
import CustomSelect from "./../../../../components/CustomSelect/CustomSelect";

import "./SubjectForm.scss";
import "react-toastify/dist/ReactToastify.css";

type courseType = {
  label: string;
  value: string;
};

type liveGroupType = {
  label: string;
  value: string;
};

interface ISubject {
  id: number;
  type: string;
  subject_name: string;
  module_id: string;
  thumbnail: string;
  description: string;
  subjectlivegroups: any[];
}

type PropsType = {
  data?: ISubject;
  editform?: any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const SubjectForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const [image, setImage] = useState(
    editform
      ? `${config.imageURL}/subjectLight/icons/${
          data?.module_id
        }/${data?.subject_name.toLowerCase()}.png`
      : ""
  );

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        type: data && data.type ? data.type : null,
        subject_name: data && data.subject_name,
        module_id: data && data.module_id,
        subjectlivegroups: data && data.subjectlivegroups,
        description: data && data.description
      }
    }
  );
  let courseApi = config.endpoints.api.course;
  let liveGroupApi = config.endpoints.api.livegroup;

  const [coursesOptions, setCourseOptions] = useState<courseType[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<courseType | null>();

  const [liveGroupOptions, setLiveGroupOptions] = useState<liveGroupType[]>([]);
  const [selectedLiveGroups, setSelectedLiveGroups] =
    useState<liveGroupType[]>([]);

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data, true);
        setCourseOptions(options);
        setValue("module_id", options[0].value);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
          setValue("module_id", course?.value);
        }
      } else {
        setCourseOptions([]);
        setValue("module_id", undefined);
      }
    }
    fetchCourses();
  }, [courseApi, data, editform, setValue]);

  useEffect(() => {
    async function fetchLiveGroups(mouleId) {
      let liveGroups = await http.GET(liveGroupApi.list(mouleId));

      if (liveGroups?.data?.rows?.length) {
        let options = convertToOptions(liveGroups.data.rows, false);
        setLiveGroupOptions(options);
        setValue("liveGroups", []);

        if (editform) {
          let livegroups = data?.subjectlivegroups || [];
          let liveGroup = options.filter(item => livegroups.includes(Number(item.value)));
          setSelectedLiveGroups(liveGroup);
          setValue("liveGroups", liveGroup);
        }
      } else {
        setLiveGroupOptions([]);
        setValue("liveGroups", []);
      }
    }
    selectedCourse && fetchLiveGroups(selectedCourse.value);
  }, [liveGroupApi, data, editform, setValue, selectedCourse]);

  const handleReset = () => {
    reset({
      name: "",
      thumbnail: "",
      description: "",
      course: "",
      liveGroups: []
    });
  };

  const handleClear = () => {
    setSelectedCourse(null);
    setSelectedLiveGroups([]);
    handleReset();
    setImage("");
  };

  /**
   * Function to convert object to options for select list
   * @param lists: {id:string, name:string}[]
   * @returns {value:string, label:string}[]
   */
  const convertToOptions = (lists, isCourse) => {
    if (isCourse) {
      let options: { value: string; label: string }[] = lists.map(item => {
        return {
          value: item.module_id,
          label: item.module_id.toUpperCase()
        };
      });
      return options;
    } else {
      let options: { value: string; label: string }[] = lists.map(item => {
        return {
          value: item.id,
          label: item.title
        };
      });
      let modifiedOptions = [
        {
          value: "",
          label: "None"
        },
        ...options
      ];
      return modifiedOptions;
    }
  };

  const handleSelectChange = (data, e) => {
    setSelectedCourse(data);
    setValue("module_id", data.value);
  };

  const handleSelectGroupChange = (data, e) => {
    let temp = data && data.map(el=>{
      return el.value;
    })

    setSelectedLiveGroups(data);
    setValue("liveGroups", temp);
  };

  const addDefaultSrc = e => {
    e.target.src = "fallback/bookIcon.png";
  };

  return (
    <form
      className="subject-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="unit_image">
              <ImageInput
                image={image}
                editform={editform}
                setImage={setImage}
                {...register("thumbnail", {
                  required: false
                })}
                onError={addDefaultSrc}
              />
            </div>

            <div className="col-container">
              <div className="row-container">
                <div className="fieldAndValidate">
                  <label htmlFor="course">
                    Course <span>*</span>
                  </label>
                  <CustomSelect
                    id="module_id"
                    required={true}
                    register={register}
                    name="module_id"
                    value={selectedCourse}
                    handleChange={(e, data) => {
                      handleSelectChange(e, data);
                    }}
                    options={coursesOptions}
                    disabled={false}
                  />
                  {errors?.module_id?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate">
                  <label htmlFor="live_group_id">Live Group</label>
                  <CustomSelect
                    id="liveGroups"
                    required={false}
                    isMulti={true}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    placeholder={`${selectedLiveGroups.length || 0} items selected.`}
                    register={register}
                    name="liveGroups"
                    value={selectedLiveGroups}
                    handleChange={(e, data) => {
                      handleSelectGroupChange(e, data);
                    }}
                    options={liveGroupOptions}
                    disabled={false}
                  />
                  {errors?.live_group_id?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
              <div className="row-container">
                <div className="fieldAndValidate">
                  <InputField
                    required
                    type="text"
                    label="Subject Name"
                    placeholder={"Enter the subject name"}
                    {...register("subject_name", {
                      required: true
                    })}
                  />
                  {errors?.subject_name?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="fieldAndValidate">
            {/* <Textarea
              label="Description"
              rows={4}
              placeholder="Enter subject description"
              {...register("description")}
            /> */}
            <RichTextarea
              required
              control={control}
              name="description"
              label="Description"
              editform={editform}
              placeholder={"Enter the subject description"}
            />
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            clickHandler={() => {}}
            buttonName={
              isLoading
                ? "Submitting"
                : editform
                ? "Update Subject"
                : "Add Subject"
            }
            disabled={isLoading ? true : false}
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default SubjectForm;
