const testStatus = (currentDate, startDate, endDate, isQuestionSet) => {
  let status, color;
  if(isQuestionSet){
    status = "Active";
    color = "green";
  }else if (startDate > currentDate) {
    status = "Scheduled";
    color = "orange";
  } else if (endDate < currentDate) {
    status = "Expired";
    color = "red";
  } else if (startDate <= currentDate && endDate >= currentDate) {
    status = "Active";
    color = "green";
  }
  return { status, color };
};

export default testStatus;
