import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PATH } from "../../../../constants/routes";

import Button from "../../../../components/Button/Button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./MasterDetailsCard.scss";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import httpMethods from "../../../../utils/http";
import toasts from "../../../../utils/toast";
import config from "../../../../config";

let videoContentApi = config.endpoints.api.videoContent;

function MasterDetailsCard({
  activeChapter,
  contents,
  isLoading,
  toggleEditContentModal,
  toggleDeleteContentModal,
  setDeleteContentData,
  setUpdateContentData
}) {
  const [contentList, setContentList] = useState(contents);
  const [disableDrag, setDisableDrag] = useState(false);

  const handleDeleteContent = (id, title, contentType) => {
    toggleDeleteContentModal();
    setDeleteContentData(id, title, activeChapter.module_id, contentType);
  };

  const handleUpdateContent = data => {
    setUpdateContentData(data);
    toggleEditContentModal();
  };

  useEffect(() => {
    setContentList(contents);
  }, [contents]);

  const ContentCard = ({ data, pos }) => {
    return (
      <div className="content">
        {/* <div className="content_thumbnail">
          {data.contentType === undefined ? (
            data.thumbnail ? (
              <>
                <img
                  src={`${config.imageURL}/videos/${data.thumbnail}`}
                  alt={"video-thumbnail"}
                />
                <i className="bx bx-play-circle overlay"></i>
              </>
            ) : (
              <i className="bx bxs-videos"></i>
            )
          ) : null}

          {data.contentType === "IMAGE" && <i className="bx bxs-image"></i>}
          {data.contentType === "AUDIO" && <i className="bx bxs-music"></i>}
          {data.contentType === "DOCUMENT" && <i className="bx bxs-file"></i>}
        </div> */}

        <div className="content_about">
          <div className="content_title">
            <h6>
              {pos + 1 + ". "}&nbsp;
              {data.title}
            </h6>
          </div>

          <div className="content_description">
            <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
          </div>
        </div>

        <div className="content_actions">
          <i title="Edit Content" onClick={() => handleUpdateContent(data)}>
            <FontAwesomeIcon className="edit" icon={faEdit} />
          </i>
          <i
            title="Delete Content"
            onClick={() =>
              handleDeleteContent(data.id, data.title, data.content_type)
            }
          >
            <FontAwesomeIcon className="trash" icon={faTrashCan} />
          </i>
        </div>
      </div>
    );
  };

  const navigate = useNavigate();

  const navigateToAdd = () => {
    navigate(PATH.ADD_CONTENT, {
      state: {
        id: activeChapter.id,
        title: activeChapter.chapter_name,
        activeChapter: activeChapter
      }
    });
  };

  const handlePositionUpdate = async orderedContents => {
    await httpMethods
      .POST(videoContentApi.updatePosition(activeChapter?.module_id), {
        data: orderedContents?.map(item => {
          return item?.id;
        })
      })
      .then(() => {
        toasts.success("Chapter Position Updated Successfully");
        // fetchNewData(chapterApi.list);
      })
      .catch(error => {
        toasts.error(error);
      });
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedContents = reorder(
      contentList,
      result.source.index,
      result.destination.index
    );

    setContentList(orderedContents);
    handlePositionUpdate(orderedContents);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 8px 0`,
    width: "100%",
    height: "100px",
    background: isDragging ? "lightblue" : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <>
      <div className="header">
        <h5>Content for unit {activeChapter.chapter_name}</h5>
        <div className="actions">
          {/* Arrange&nbsp;&nbsp;
          {disableDrag ? (
            <i
              className="bx bxs-sort-alt"
              onClick={() => setDisableDrag(pre => !pre)}
              title="Reorder Contents"
            ></i>
          ) : (
            <i
              className="bx bx-check tick"
              onClick={() => setDisableDrag(pre => !pre)}
              title="Save Changes"
            ></i>
          )} */}
          <Button
            buttonName="Add Content"
            color="success"
            clickHandler={navigateToAdd}
            disabled={isLoading ? true : !activeChapter.id && true}
          />
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // className={`draggableList ${!disableDrag && "ordering"}`}
              className={`draggableList`}
            >
              {contentList.map((item, index) => (
                <Draggable
                  key={String(item.id)}
                  draggableId={String(item.id)}
                  index={index}
                  isDragDisabled={disableDrag}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {<ContentCard key={index} data={item} pos={index} />}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default MasterDetailsCard;
