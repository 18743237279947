import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import config from "../../../config";
import httpMethods from "../../../utils/http";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface AmountProps {
  last12EachMonthsPaymentAmount: {
    date: string;
    total_amount: number;
  }[];
}

const PaymentChart = () => {
  const [amount, setAmount] = useState<AmountProps | null>(null);
  const apiUrl = `${config.baseURI}/api/v1/count/paymentCount`;

  useEffect(() => {
    httpMethods.GET(apiUrl).then(response => {
      let payAmt = response.data.data;
      setAmount(payAmt);
    });
  }, []);

  if (!amount) {
    return <Spin tip="Loading" size="large" />;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: { intersect: false },
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Monthly Payment"
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    }
  };

  const labels = amount.last12EachMonthsPaymentAmount.map(item => item.date);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Amount",
        data: amount.last12EachMonthsPaymentAmount.map(
          item => item.total_amount
        ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)"
      }
    ]
  };

  return (
    <div style={{ height: "100%" }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default PaymentChart;
