import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import moment from "moment";

import http from "../../utils/http";

import Button from "../../components/Button/Button";
import LiveTable from "../../components/LiveTable/LiveTable";
import LiveForm from "./components/liveForm/LiveForm";

import toast from "../../utils/toast";
import { PATH } from "../../constants/routes";

import useFetch from "../../hooks/useFetch";

import Search from "../../components/Search/Search";

import "./Live.scss";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";

import config from "../../config";
import toReadableDate from "../../utils/toReadableDate";

let liveApi = config.endpoints.api.live;

function Live() {
  const selectedElementRef = useRef<null | HTMLElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const module_id = queryParams.get("module_id");
  const liveGroupId = queryParams.get("live_group_id");

  const [lives, setLives] = useState([]);

  const [isFormUpdating, setIsFormUpdating] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  useEffect(() => {
    if (!(module_id && liveGroupId)) {
      navigate("/livegroup");
    } else {
      fetchNewData(liveApi.list(module_id, liveGroupId));
    }
  }, []);

  useEffect(() => {
    if (fetchedData) {
      fetchedData.rows && setLives(fetchedData.rows);
    } else {
      setLives([]);
    }
  }, [fetchedData]);

  const columns = [
    {
      Header: "Live Title",
      accessor: "title"
    },
    {
      Header: "Start Time",
      accessor: row => toReadableDate(row.start_time)
    },
    {
      Header: "Duration(Mins)",
      accessor: "duration"
    },
    {
      Header: "Meet Id",
      accessor: "meet_id"
    },
    {
      Header: "Status",
      Cell: tableRows => {
        const is_live = tableRows.row.original.is_live;

        return is_live ? (
          <i
            className="fas fa-globe-asia"
            style={{ color: "green", fontSize: "16px", marginLeft: "1em" }}
          ></i>
        ) : (
          <i
            className="fas fa-globe-asia"
            style={{ color: "#c70000", fontSize: "16px", marginLeft: "1em" }}
          ></i>
        );
      }
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(`${liveApi.delete(module_id)}/${id}/`);
      if (response.status === 200) {
        toast.success("Live deleted successfully");
        if (selectedElementRef?.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setLives(lives => {
              return lives.filter(course => (course.id !== id ? course : null));
            });
            fetchNewData(liveApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the plan"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsFormUpdating(true);

    data.z_start_time = data.start_time;
    data.z_recuur_end_time = data.recuur_end_time
      ? data.recuur_end_time
      : moment.utc();

    data.start_time = moment(data.start_time).utc();
    data.recuur_end_time = data.recuur_end_time
      ? moment(data.recuur_end_time).utc()
      : moment.utc();

    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${liveApi.update(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          toast.success("Live updated successfully");
          fetchNewData(liveApi.list(module_id, liveGroupId));
        } else {
          toast.error(new Error("Error in updating plan"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsFormUpdating(false);
    }

    pushUpdate();
  };

  const [searchedLive, setSearchedLive] = useState([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = lives.filter(
        live =>
          live.title.toLowerCase().includes(searchText.toLowerCase()) ||
          live.module_id.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedLive(filteredData);
    };
  }, [lives]);

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Live : {module_id} </h4>

        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link
            to={`${PATH.ADD_LIVE}?module_id=${module_id}&live_group_id=${liveGroupId}`}
          >
            <Button
              buttonName="Add Meeting"
              color="success"
              type="button"
              clickHandler={() => {}}
            />
          </Link>
        </div>
      </div>
      <div className="table_container live_table_container">
        <LiveTable
          data={searchValue ? searchedLive : lives}
          hasError={error}
          columns={columns}
          isLoading={isFormUpdating}
          formToEdit={<LiveForm module_id={module_id} />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        />
      </div>
    </>
  );
}
export default Live;
