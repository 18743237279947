import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import User from "../pages/User/User";

import { getAccessToken } from "../services/token";

export default function UserRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<User />} />
    </Routes>
  );
}
