import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "../../../../../components/Button/Button";
import InputField from "../../../../../components/InputField/InputField";
import ImageInput from "../../../../../components/ImageInput/ImageInput";
import RichTextarea from "../../../../../components/Textarea/RichTextarea";

import { FaPlay } from "react-icons/fa";

import "react-toastify/dist/ReactToastify.css";

import { useUploadContext } from "../../../../../context/UploadContextProvider";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import VideoLinksModal from "../../../../Content/components/contentForm/videoLinks/VideoLinksModal";
import { useNavigate } from "react-router-dom";

const RecordingForm = ({
  data,
  editform,
  handleCancel,
  handleClickUpdate,
  isPreview,
  setIsPreview,
  mainData,
  isSubmitting
}) => {
  const { live_id, module_id, live_title } = mainData;
  const navigate = useNavigate();

  const { uploadRecording } = useUploadContext();

  const {
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform
      ? {
          defaultValues: {
            id: data && data.id,
            title: data && data.title,
            thumbnail: data && data.thumbnail,
            description: data && data.description,
            link: data && data.link,
            module_id: data && data.module_id,
            live_id: data && data.live_id
          }
        }
      : {
          defaultValues: {
            module_id,
            live_id
          }
        }
  );
  const [videoLink, setVideoLink] = useState(
    editform && data && data.link ? data.link : null
  );
  const [image, setImage] = useState(
    editform
      ? data.thumbnail &&
          `${process.env.REACT_APP_IMAGE_BASE_URL}/recordings/${data.thumbnail}`
      : ""
  );
  const [tempFile, setTempFile] = useState(null);
  const [videoLinksModal, setVideoLinksModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState("url");

  const getSelectedTab = tab => {
    if (selectedTab === tab) {
      return "active";
    }
  };

  const handleClear = () => {
    setImage("");
    reset({
      file: null,
      title: "",
      thumbnail: "",
      description: "",
      module_id,
      live_id
    });
    setTempFile(null);
  };

  const handleClickSubmit = data => {
    if (!data.file && !data.link) {
      toast.error("Please upload a file or enter a link");
      return;
    } else {
      uploadRecording(data, false);
      setImage("");
      setTempFile(null);
      navigate(
        `/live/recording?module_id=${module_id}&live_id=${live_id}&live_title=${live_title}`
      );
    }
  };

  const handleFileChange = e => {
    setTempFile(e.target.files[0]);
    setValue("file", e.target.files[0]);
  };

  return (
    <>
      <form
        className="content-form-container"
        onSubmit={handleSubmit(
          editform ? handleClickUpdate : handleClickSubmit
        )}
      >
        <div className="row-container">
          <div className="col-container">
            <div className="row-container">
              <div className="content_image">
                <ImageInput
                  image={image}
                  editform={editform}
                  setImage={setImage}
                  {...register("thumbnail", {
                    required: false
                  })}
                />
              </div>

              <div className="col-container">
                <div className="row-container">
                  <div className="fieldAndValidate">
                    <InputField
                      required
                      type="text"
                      name="title"
                      label={`Recording Title`}
                      placeholder={"Enter the recording title"}
                      {...register("title", {
                        required: true
                      })}
                    />
                    {errors?.title?.type === "required" && (
                      <p>This field is required</p>
                    )}
                  </div>
                </div>

                <div className="row-container">
                  <div className="video-field">
                    <div className="selector-tabs">
                      <p
                        className={`tab ${getSelectedTab("url")}`}
                        onClick={() => setSelectedTab("url")}
                      >
                        Url
                      </p>
                      <p
                        className={`tab ${getSelectedTab("file")}`}
                        onClick={() => setSelectedTab("file")}
                        style={{ textTransform: "capitalize" }}
                      >
                        Recording Video
                      </p>
                    </div>
                    {selectedTab === "file" ? (
                      <div className="file_field">
                        <div className="label_edit">
                          <label>Recording</label>
                        </div>
                        <label htmlFor="file">
                          <div className="upload_container">
                            <div className="upload_icon">
                              <i className="fas fa-upload"></i>
                            </div>
                            <div className="upload_text">
                              <p>
                                {tempFile ? tempFile.name : `Upload Recording`}
                              </p>
                            </div>
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        {errors?.file?.type === "required" && (
                          <p>This field is required</p>
                        )}
                      </div>
                    ) : (
                      <div
                        className="fieldAndValidate"
                        style={{ position: "relative" }}
                      >
                        <InputField
                          required={false}
                          type="text"
                          min={1}
                          name="link"
                          // readOnly
                          label={`Recording Link (If already uploaded)`}
                          placeholder={`Enter recording url of the already uploaded video`}
                          {...register("link")}
                          // onClick={() => setVideoLinksModal(true)}
                          style={
                            {
                              // cursor: "pointer"
                            }
                          }
                        />
                        {editform && videoLink && (
                          <div
                            className="preview-btn"
                            onClick={() => setIsPreview(true)}
                          >
                            <FaPlay className="play-icon" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="fieldAndValidate">
              <RichTextarea
                required
                control={control}
                name="description"
                label="Description"
                editform={editform}
                placeholder={"Enter the video description"}
              />
            </div>
          </div>
        </div>

        <div className="row-container">
          <div className="button-wrapper">
            <Button
              type="submit"
              color="success"
              buttonName={
                isSubmitting
                  ? "Submitting"
                  : editform
                  ? `Update Recording`
                  : `Add Recording`
              }
              disabled={isSubmitting ? true : false}
            />
            {!editform && (
              <Button
                type="button"
                color="danger"
                buttonName="Clear"
                clickHandler={() => handleClear()}
              />
            )}
            {editform && (
              <Button
                type="button"
                color="danger"
                buttonName="Cancel"
                clickHandler={() => handleCancel()}
              />
            )}
          </div>
        </div>
      </form>

      {(isPreview || videoLinksModal) && <div className="backdrop"></div>}

      <Modal centered onHide={setVideoLinksModal} show={videoLinksModal}>
        <Modal.Body
          style={{
            position: "relative"
          }}
        >
          <VideoLinksModal
            setValue={setValue}
            setVideoLinksModal={setVideoLinksModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RecordingForm;
