import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Instructor from "../pages/Instructor/Instructor";

import { PATH } from "../constants/routes";

import { getAccessToken } from "../services/token";

import AddInstructor from "../pages/Instructor/AddInstructor";

export default function InstructorRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Instructor />} />
      <Route path={PATH.ADD_INSTRUCTOR} element={<AddInstructor />} />
    </Routes>
  );
}
