import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";

import CourseGroup from "../pages/CourseGroup/CourseGroup";
import AddCourseGroup from "../pages/CourseGroup/AddCourseGroup";
import { getAccessToken } from "../services/token";

export default function CourseGroupRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<CourseGroup />} />
      <Route path={PATH.ADD_COURSE_GROUP} element={<AddCourseGroup />} />
    </Routes>
  );
}
