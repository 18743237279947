import React, { ReactElement, useMemo, useState } from "react";

import { useTable, useSortBy, usePagination } from "react-table";
import TableActions from "../TableActions/TableActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";

import "../Tables/Tables.scss";

import ErrorPages from "../../components/ErrorPages/ErrorPages";
import { useNavigate } from "react-router-dom";

type PropsType = {
  columns: any[];
  data: any;
  total_pages?: number | undefined;
  limit?: number;
  setOffset?: Function;
  setLimit?: Function;
  feedbackTable?: boolean;
  handleClickUpdate?: Function;
  handleDelete?: Function;
  formToEdit?: ReactElement;
  selectedElementRef: { current: HTMLElement | null };
  isLoading?: boolean;
  isFetching?: boolean;
  hasError?: boolean;
  userForm?: boolean;
  notificationForm?: boolean;
  setSelectedUser?: Function;
  setShowPaymentModal?: Function;
};

export default function LiveTable({
  columns,
  data,
  total_pages,
  limit,
  setOffset,
  feedbackTable,
  handleClickUpdate,
  handleDelete,
  formToEdit,
  selectedElementRef,
  isLoading,
  isFetching,
  hasError,
  userForm,
  notificationForm,
  setSelectedUser,
  setShowPaymentModal
}: PropsType) {
  let memoizedColumns = useMemo(() => columns, [columns]);
  let memoizedDatas = useMemo(() => data, [data]);

  const [pageNo, setPageNo] = useState(0);
  const navigate = useNavigate();

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: memoizedDatas,
      initialState: { pageSize: limit || 8, pageIndex: pageNo },
      manualPagination: total_pages ? true : false,
      ...(total_pages && { pageCount: total_pages })
    },
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage
  } = tableInstance;

  const handleSelectedElement = event => {
    selectedElementRef.current = event.currentTarget;
  };

  return (
    <>
      <div className="table_wrapper">
        <table className="table" {...getTableProps}>
          <thead className="h5">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleDown} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </span>
                  </th>
                ))}
                {!feedbackTable && <th>Actions</th>}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            {page.map(row => {
              prepareRow(row);
              let status = row.original.meet_id ? "Live" : "LiveGroup";
              let module_id = row.original.module_id;
              let live_id = row.original.id;
              let live_title = row.original.title;

              return (
                <tr
                  className="table-row"
                  {...row.getRowProps()}
                  onClick={handleSelectedElement}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          status === "LiveGroup"
                            ? navigate(
                                `/live?module_id=${module_id}&live_group_id=${live_id}`
                              )
                            : navigate(
                                `/live/recording/?module_id=${module_id}&live_id=${live_id}&live_title=${live_title}`
                              );
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {!feedbackTable && (
                    <TableActions
                      handleClickUpdate={handleClickUpdate}
                      handleDelete={handleDelete}
                      data={row.original}
                      formToEdit={formToEdit}
                      isLoading={isLoading}
                      notificationForm={notificationForm}
                      userForm={userForm}
                      setSelectedUser={setSelectedUser}
                      setShowPaymentModal={setShowPaymentModal}
                    />
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {pageOptions.length ? (
        <div className="table_pagination">
          <button
            onClick={() => {
              if (total_pages) {
                setPageNo(0);
                setOffset(0);
              } else {
                gotoPage(0);
              }
            }}
            disabled={!canPreviousPage}
          >
            <b>{"<<"}</b>
          </button>
          <button
            onClick={() => {
              if (total_pages) setOffset(pageIndex * limit);
              if (total_pages) {
                setPageNo(pageIndex - 1);
                setOffset((pageIndex - 1) * limit);
              } else {
                previousPage();
              }
            }}
            disabled={!canPreviousPage}
          >
            <b>{"<"}</b>
          </button>
          <span>
            &nbsp;Page&ensp;
            <input
              type="number"
              value={pageOptions.length ? pageIndex + 1 : 0}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;

                if (total_pages) {
                  setPageNo(page);
                  setOffset(page * limit);
                } else {
                  gotoPage(page);
                }
              }}
              style={{ width: "50px" }}
            />
            &nbsp; of {pageOptions.length} &nbsp;
          </span>
          <button
            onClick={() => {
              if (total_pages) {
                setPageNo(pageIndex + 1);
                setOffset((pageIndex + 1) * limit);
                nextPage();
              } else {
                nextPage();
              }
            }}
            disabled={!canNextPage}
          >
            <b>{">"}</b>
          </button>
          {/* <button
            onClick={() => {
              if (total_pages) {
                setPageNo(total_pages - 1);
                setOffset((total_pages - 1) * limit);
              } else {
                gotoPage(pageCount - 1);
              }
            }}
            disabled={!canNextPage}
          >
            <b>{">> "}</b>
          </button> */}
        </div>
      ) : null}
      <ErrorPages
        isFetching={isFetching}
        data={page.length ? true : false}
        error={hasError ? true : false}
      />
    </>
  );
}
