import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../components/Button/Button";
import config from "../../config";
import http from "../../utils/http";
import toast from "../../utils/toast";

let userApi = config.endpoints.api.user;

function UnsubscribeModel({
  showUnsubscribeModal,
  setShowUnsubscribeModal,
  selectedUser,
  course,
  setRefresh
}) {
  const [userModel, setUserModel] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (course && selectedUser.id) {
      let userData = selectedUser.userModules.find(
        el => el.userModuleId.toUpperCase() === course.toUpperCase()
      );

      setUserModel(userData);
    }
  }, [selectedUser, course]);

  const handleSubmit = () => {
    if (selectedUser.id && userModel) {
      setIsLoading(true);

      http
        .POST(`${userApi.removesubs(userModel.userModuleId)}`, {
          userId: selectedUser.id
        })
        .then(res => {
          toast.success("User subscription removed successfully");
          setRefresh(status => !status);
        })
        .catch(error => {
          toast.error(new Error("Error in updating user subscription."));
        })
        .finally(() => {
          setIsLoading(false);
          setShowUnsubscribeModal(false);
        });
    }
  };
  return (
    <>
      <Modal
        size="lg"
        show={showUnsubscribeModal}
        onHide={() => setShowUnsubscribeModal(false)}
        dialogClassName={"modal_container unsubscribe"}
        centered
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Update Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modalBody">
          <div className="unsubs-container">
            <div>
              {userModel && userModel.paid ? (
                <h1>Do you really want to remove the user's Subscription ?</h1>
              ) : (
                <h1>This user doesnot have subscription.</h1>
              )}

              <p>Module Id: {userModel?.userModuleId}</p>
              <p>Phone: {selectedUser?.phone}</p>
              <p>Email: {selectedUser?.email}</p>
            </div>
            <div className="button-wrapper">
              <Button
                type="button"
                color="success"
                disabled={isLoading || !userModel?.paid ? true : false}
                buttonName={isLoading ? "Submitting" : "Submit"}
                clickHandler={() => handleSubmit()}
              />
              <Button
                type="button"
                color="danger"
                buttonName="Cancel"
                clickHandler={() => {
                  setShowUnsubscribeModal(false);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UnsubscribeModel;
