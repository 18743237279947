import React, { ReactElement, useEffect, useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { useTable, useSortBy, useRowSelect, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { DraggableTableRow } from "./DraggableRow";
import { StaticTableRow } from "./StaticTableRow";
import ErrorPages from "../ErrorPages/ErrorPages";
import "./Tables.scss";

type PropsType = {
  columns: any[];
  data: any;
  setData?: any;
  onSelectRows?: any;
  disableDrag?: boolean;
  hasCheckBox?: boolean;
  feedbackTable?: boolean;
  handleClickUpdate?: Function;
  handleDelete?: Function;
  formToEdit?: ReactElement;
  selectedElementRef: { current: HTMLElement | null };
  isLoading?: boolean;
  isFetching?: boolean;
  hasError?: boolean;
  userForm?: boolean;
  notificationForm?: boolean;
  setSelectedUser?: Function;
  setShowPaymentModal?: Function;
};

export default function DragDropTable({
  columns,
  data,
  setData,
  selectedElementRef,
  hasCheckBox,
  onSelectRows,
  disableDrag,
  isFetching,
  hasError
}: PropsType) {
  const [activeId, setActiveId] = useState<number | null | undefined>();
  const items = useMemo(() => data?.map(({ id }) => id), [data]);

  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedDatas = useMemo(
    () => (data && data?.length ? data : []),
    [data]
  );

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: memoizedDatas,
      initialState: { pageIndex: 0, pageSize: 8 }
    },
    useSortBy,
    usePagination,
    useRowSelect
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    pageCount,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage
  } = tableInstance;
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );
  //   useEffect(() => {
  //     hasCheckBox &&
  //       onSelectRows(selectedFlatRows?.map(d => d.original).map(each => each.id));
  //   }, []);
  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setData(data => {
        const oldIndex = items?.indexOf(active?.id);
        const newIndex = items?.indexOf(over?.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  const handleSelectedElement = event => {
    selectedElementRef.current = event?.currentTarget;
  };

  function handleDragCancel() {
    setActiveId(null);
  }

  const tableRows = disableDrag ? page : rows;

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = tableRows.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, tableRows, prepareRow]);

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <table className={"table"} {...getTableProps}>
          <thead className="h5">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {!(hasCheckBox && index === 0) && column.canSort ? (
                      <span className="checkBox">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faAngleUp} />
                          ) : (
                            <FontAwesomeIcon icon={faAngleDown} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faCaretDown} />
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps}>
            <SortableContext
              items={items && items}
              strategy={verticalListSortingStrategy}
            >
              <tr></tr>
              {tableRows?.map((row, i) => {
                prepareRow(row);
                return (
                  <DraggableTableRow
                    key={row?.original?.id}
                    row={row && row}
                    disableDrag={disableDrag}
                    handleSelectedElement={handleSelectedElement}
                  />
                );
              })}
            </SortableContext>
          </tbody>
        </table>
        <DragOverlay>
          {activeId && (
            <table style={{ width: "100%" }}>
              <tbody>
                <StaticTableRow row={selectedRow} />
              </tbody>
            </table>
          )}
        </DragOverlay>
      </DndContext>
      {pageOptions?.length && disableDrag ? (
        <div className="table_pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <b>{"<<"}</b>
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <b>{"<"}</b>
          </button>
          <span>
            &nbsp;Page&ensp;
            <input
              type="number"
              value={pageOptions.length ? pageIndex + 1 : 0}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "50px" }}
            />
            &nbsp; of {pageOptions.length} &nbsp;
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <b>{">"}</b>
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <b>{">> "}</b>
          </button>
        </div>
      ) : null}
      <ErrorPages
        isFetching={isFetching}
        data={page.length ? true : false}
        error={hasError ? true : false}
      />
    </>
  );
}
