import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function success(msg: string): void {
  toast.success(msg, { autoClose: 3000 });
}

function info(msg: string): void {
  toast.info(msg, { autoClose: 3000 });
}

function warning(msg: string): void {
  toast.warning(msg, { autoClose: 3000 });
}

function error(error) {
  let errMsg =
    typeof error === typeof "string" ? error : "Something went wrong";
  const err = error?.response;

  if (err && err?.data) {
    var errActual = err.data?.message;
  }
  toast.error(errActual || errMsg, { autoClose: 3000 });
}

let toasts = { success, warning, error, info };
export default toasts;
