import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import "./PremiumWithData.scss";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

import config from "../../config";
import useFetch from "../../hooks/useFetch";
import Button from "../../components/Button/Button";

import http from "../../utils/http";
import toast from "../../utils/toast";

import readXlsxFile from "read-excel-file";
import { Modal } from "react-bootstrap";

const courseApi = config.endpoints.api.course;
const giveAccessWithFileApi = config.endpoints.api.giveAccessWithFile;

function CheckNumberOfUser() {
    const {
        handleSubmit,
        watch,
        register,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();
    const {
        loading: courseLoading,
        error: courseError,
        fetchedData: fetchedCourse,
        fetchNewData: fetchNewCourse
    } = useFetch();

    const watchCourse = watch("courseFilter");

    const [courseOptions, setCourseOptions] = useState<any[]>([]);

    const [selectedCourse, setSelectedCourse] = useState<any>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [fileError, setFileError] = useState<boolean>(false);

    const [openModel, setOpenModel] = useState<boolean>(false);

    const [userPhoneNumbers, setUserPhoneNumbers] = useState<any>();

    const [response, setResponse] = useState<any>();

    const [openResModel, setOpenResModel] = useState<boolean>(false);

    useEffect(() => {
        fetchNewCourse(courseApi.list);
    }, [fetchNewCourse]);

    const convertToOptions = lists => {
        let options = lists.map(item => {
            return {
                value: item.module_id,
                label: item.name
            };
        });
        return options;
    };

    useEffect(() => {
        if (fetchedCourse.data) {
            if (fetchedCourse.data.length) {
                let options = convertToOptions(fetchedCourse.data);
                setCourseOptions(options);
                setValue("courseFilter", options[0].value);
                setSelectedCourse(options[0]);
            } else {
                setCourseOptions([]);
                setValue("courseFilter", undefined);
            }
        }
    }, [fetchedCourse, setValue]);

    const handleFileChange = e => {
        setValue("file", e.target.files[0]);
        if (e.target.files[0]) {
            clearErrors("file");
            readXlsxFile(e.target.files[0]).then(rows => {
                let data =
                    rows &&
                    rows.map(r => {
                        return r[0];
                    });
                if (data[0] !== "Phone" && data.length > 1) {
                    setFileError(true);
                } else {
                    data.shift();
                    setUserPhoneNumbers(data);
                    setFileError(false);
                    setOpenModel(true);
                }
            });
        }
    };

    const handleSubmitFile = data => {
        if (!data.file) {
            setError("file", { type: "required" });
        } else if (!fileError) {
            setIsLoading(true);
            clearErrors("file");

            let new_data = {
                moduleId: data.courseFilter,
                phoneNumbers: userPhoneNumbers,
            };

            http
                .POST(giveAccessWithFileApi.checkUser(watchCourse), new_data)
                .then(res => {
                    setResponse(res.data.data)

                    toast.success(res.data.message);
                    setOpenResModel(!openResModel);
                })
                .catch(error => {
                    toast.error(error.response.data.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            <div className="page_header">
                <h4 className="page_title">Give numbers of existing users.</h4>
                <div className="content_filter">
                    <div className="course_filter">
                        <CustomSelect
                            id="courseFilter"
                            required={true}
                            register={register}
                            name="courseFilter"
                            placeholder="Select Course"
                            value={selectedCourse}
                            handleChange={data => {
                                setSelectedCourse(data);
                                setValue("courseFilter", data.value);
                            }}
                            options={courseOptions}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
            <form
                className="premiumWithData-container"
                onSubmit={handleSubmit(handleSubmitFile)}
            >
                <div className="row-container">
                    <div className="col-container">

                        <div className="row-container">
                            <div className="fieldAndValidate">
                                <label htmlFor="file">
                                    File <span>*</span>
                                </label>
                                <input
                                    // accept=".xsl, .xslx"
                                    type="file"
                                    id="file"
                                    name="file"
                                    onChange={handleFileChange}
                                />
                                {errors?.file?.type === "required" && <p>File is required</p>}

                                {fileError && (
                                    <p>
                                        File must only contain Phone numbers with top cell named as
                                        "Phone".
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row-container loader ${isLoading ? "load" : ""}`}>
                    <span></span>
                </div>

                <div className="row-container">
                    <div className="button-wrapper">
                        {userPhoneNumbers ? (
                            <Button
                                type="button"
                                color="success"
                                disabled={false}
                                buttonName="Check"
                                clickHandler={() => {
                                    setOpenModel(!openModel);
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        <Button
                            type="submit"
                            color="success"
                            disabled={isLoading || fileError ? true : false}
                            buttonName={
                                isLoading ? "Submitting" : fileError ? "File Error" : "Submit"
                            }
                        />
                    </div>
                </div>

                <div className="loading">
                    <span></span>
                </div>
            </form>

            <Modal
                size="xl"
                show={openModel}
                onHide={() => {
                    setOpenModel(!openModel);
                }}
                dialogClassName={"modal_container"}
                centered
            >
                <Modal.Header className="modalTitle" closeButton>
                    <Modal.Title>Review User's Numbers</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modalBody">
                    <div className="review-container">
                        {!userPhoneNumbers ? (
                            <p>No numbers found.</p>
                        ) : (
                            <ul>
                                {userPhoneNumbers.map((phone, i) => {
                                    return (
                                        <li key={i}>
                                            {i + 1}
                                            {". "} {phone || "NULL"}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="xl"
                show={openResModel}
                onHide={() => {
                    setOpenResModel(!openResModel);
                }}
                dialogClassName={"modal_container"}
                centered
            >
                <Modal.Header className="modalTitle" closeButton>
                    <Modal.Title>Response Details</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modalBody">
                    <div className="review-container">
                        {!response ? (
                            <p>No numbers found.</p>
                        ) : (
                            <ul>
                                <li>
                                    Total Numbers Provided: {response.total_provided_numbers}
                                </li>
                                <li>
                                    Total Users found: {response.total_users_found}
                                </li>
                                <li>
                                    Total UserModules found: {response.total_user_modules_found}
                                </li>
                            </ul>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CheckNumberOfUser;
