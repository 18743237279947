import React from "react";
import BaseRoutes from "./routes/routes";
import "boxicons/css/boxicons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "./pages/ErrorBoundary/ErrorBoundary";

import "./assets/styles/App.scss";
// import { useUploadContext } from "./context/UploadContextProvider";

function App() {
  // const { uploadQueue, temp } = useUploadContext();

  // useEffect(() => {
  //   window.onbeforeunload = function () {
  //     if (uploadQueue.length > 0 || temp !== undefined || temp !== null)
  //       return "Your upload will be cancelled if you leave this page. Are you sure you want to leave?";
  //   };
  // }, [uploadQueue, temp]);

  return (
    <>
      <ToastContainer />
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<BaseRoutes />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

export default App;
