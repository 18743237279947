import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import config from "../../config";
import http from "../../utils/http";
import toast from "../../utils/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import Search from "../../components/Search/Search";
import DragDropTable from "../../components/Tables/DragDropTable";
import { PATH } from "../../constants/routes";
import useFetch from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import uploadImage from "../../utils/uploadImage";

import "./Subjects.scss";
import SubjectForm from "./components/SubjectForm/SubjectForm";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

type CourseType = {
  id: number;
  name: string;
};

type SubjectType = {
  course: CourseType[];
  description: string;
  id: number;
  subject_name: string;
  thumbnail: string;
  module_id: string;
};

function Subjects() {
  let courseApi = config.endpoints.api.course;
  let subjectApi = config.endpoints.api.subject;

  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const selectedElementRef = useRef<null | HTMLElement>(null);
  const [disableDrag, setDisableDrag] = useState(true);
  const [subjects, setSubjects] = useState<SubjectType[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<SubjectType>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<any>();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [courseApi, setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(subjectApi.list(watchCourse));
  }, [fetchNewData, watchCourse, subjectApi]);

  useEffect(() => {
    if (fetchedData.data.length) {
      setSubjects(fetchedData.data);
    } else {
      setSubjects([]);
    }
  }, [fetchedData]);

  const addDefaultSrc = e => {
    e.target.src = "fallback/bookIcon.png";
  };

  const columns = [
    {
      Header: "Id",
      accessor: row => row?.subject_id
    },
    {
      Header: "Subject Name",
      accessor: row => `${row.subject_name}`,
      Cell: tableProps => {
        let original = tableProps.row.original;

        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              <img
                src={`${config.imageURL}/subjectLight/icons/${
                  original.module_id
                }/${original.subject_name.toLowerCase()}.png`}
                alt={`thumbnail ${original.subject_name}`}
                onError={e => addDefaultSrc(e)}
              />
            </div>
            {`${original.subject_name}`}
          </div>
        );
      }
    },
    {
      Header: "Course",
      accessor: row => row?.module_id.toUpperCase()
    },
    {
      Header: "Description",
      accessor: row => {
        return (
          <div className="feedbackDetails">
            <p>{row.description || "No description"}</p>
          </div>
        );
      }
    },
    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "10px"
          }}
        >
          Actions
        </div>
      ),
      accessor: "action",
      disableSortBy: true,
      Cell: tableProps => {
        const original = tableProps.row.original;
        return (
          <>
            <td
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "baseline",
                justifyItems: "flex-start"
              }}
            >
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowEditModal(true);
                  setSelectedSubject(original);
                }}
                className="edit_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faEdit}
              />
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowDeleteModal(true);
                  setSelectedSubject(original);
                }}
                className="trash_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faTrashCan}
              />
            </td>
          </>
        );
      }
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${subjectApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Subject deleted successfully");
        if (selectedElementRef.current) {
          let selectedElement = selectedElementRef?.current;

          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setSubjects(subjects => {
              return subjects.filter(subject =>
                subject.id !== id ? subject : null
              );
            });
            fetchNewData(subjectApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the subject"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsSubmitting(true);
    if (data.live_group_id === "") {
      data.live_group_id = null;
    }

    let image = data.thumbnail;
    delete data.thumbnail;

    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${subjectApi.update(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          if (image?.length) {
            await uploadImage(
              image[0],
              data.subject_name.toLowerCase(),
              `subjectLight/icons/${data.module_id}`
            );
          }
          toggleModal();
          fetchNewData(subjectApi.list(data.module_id));
          toast.success("Subject updated successfully");
        } else {
          toast.error(new Error("Error in updating unit"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsSubmitting(false);
    }
    pushUpdate();
  };

  const [searchedSubject, setSearchedSubject] = useState<SubjectType[]>([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = subjects.filter(
        subject =>
          subject.subject_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          subject.module_id.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedSubject(filteredData);
    };
  }, [subjects]);

  const handlePositionUpdate = () => {
    http
      .POST(subjectApi.updatePosition(selectedCourse?.value), {
        data:
          subjects &&
          subjects.map(item => {
            return item.id;
          })
      })
      .then(() => {
        toast.success("Subject Position Updated Successfully");
        fetchNewData(subjectApi.list);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Subjects: {watchCourse}</h4>
        <div className="course_filter">
          <CustomSelect
            id="courseFilter"
            required={true}
            register={register}
            name="courseFilter"
            placeholder="Select Course"
            value={selectedCourse}
            handleChange={data => {
              setSelectedCourse(data);
              setValue("courseFilter", data.value);
            }}
            options={courseOptions}
            disabled={false}
          />
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="arrange_dragdrop">
          <div
            className="arrange_button"
            onClick={() => {
              setDisableDrag(pre => !pre);
              !disableDrag && handlePositionUpdate();
            }}
          >
            <div style={{ fontSize: "14px" }}>Arrange</div>
            {disableDrag ? (
              <i className="bx bxs-sort-alt" title="Reorder Contents"></i>
            ) : (
              <i className="bx bx-check tick" title="Save Changes"></i>
            )}
          </div>
          <div className="button_wrapper ">
            <Link to={PATH.ADD_SUBJECT}>
              <Button type="button" buttonName="Add Subject" color="success" />
            </Link>
          </div>
        </div>
      </div>
      <div className="table_container subject_table_container">
        <DragDropTable
          disableDrag={disableDrag}
          data={searchValue ? searchedSubject : subjects}
          columns={columns}
          setData={setSubjects}
          hasCheckBox={true}
          selectedElementRef={selectedElementRef}
          onSelectRows={() => {}}
          isFetching={loading}
          formToEdit={<SubjectForm />}
          handleDelete={handleDelete}
          isLoading={isSubmitting}
          hasError={error}
          handleClickUpdate={data => handleClickUpdate(data)}
        />
      </div>
      <Modal
        size="lg"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName={"modal_container"}
        centered
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Update Subject</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modalBody">
          {React.cloneElement(
            <SubjectForm handleClickUpdate={() => handleClickUpdate} />,
            {
              data: selectedSubject,
              editform: 1,
              handleCancel: () => setShowEditModal(false),
              loading
            }
          )}
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={selectedSubject?.id}
        name={selectedSubject?.subject_name}
        handleDelete={handleDelete}
      />
    </>
  );
}

export default Subjects;
