import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";
import ContentTab from "../pages/Content/ContentTab";
import AddChapter from "../pages/Chapter/AddChapter";

import Content from "../pages/Content/Content";
import { getAccessToken } from "../services/token";

export default function ContentRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Content />} />
      <Route path={PATH.ADD_CONTENT} element={<ContentTab />} />
      <Route path={PATH.ADD_CHAPTER} element={<AddChapter />} />
    </Routes>
  );
}
