import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import http from "../../utils/http";
import toast from "../../utils/toast";

import Button from "../../components/Button/Button";
import SubscriptionForm from "../Subscription/components/subscriptionForm/SubscriptionForm";

import "./AddSubscription.scss";

import config from "../../config";
let subscriptionApi = config.endpoints.api.subscription;

const AddSubscription = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = data => {
    setIsLoading(true);

    http
      .POST(subscriptionApi.create(data.module_id), data)
      .then(res => {
        toast.success("Plan Added Successfully!");
        navigate("../");
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Subscription | Add Plan</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_subscription_form">
        <SubscriptionForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AddSubscription;
