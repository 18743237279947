import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";

import Subjects from "../pages/Subjects/Subjects";
import AddSubject from "../pages/Subjects/AddSubject";
import { getAccessToken } from "../services/token";

export default function SubjectRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Subjects />} />
      <Route path={PATH.ADD_SUBJECT} element={<AddSubject />} />
    </Routes>
  );
}
