import React, { useEffect, useState, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import Tables from "../../components/Tables/Tables";
import Search from "../../components/Search/Search";

import useFetch from "../../hooks/useFetch";

import Tag from "../../components/Tag/Tag";
import "./User.scss";
import PaymentModal from "./PaymentModal";
import { PATH } from "../../constants/routes";

import config from "../../config";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import UnsubscribeModel from "./UnsubscribeModel";

let userApi = config.endpoints.api.user;
let courseApi = config.endpoints.api.course;

interface IUser {
  email: string;
  phone: number;
  id: number;
}

function User() {
  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const {
    loading: courseLoading,
    error: courseError,
    fetchedData: fetchedCourse,
    fetchNewData: fetchNewCourse
  } = useFetch();

  const [courseOptions, setCourseOptions] = useState<any[]>([]);

  const [selectedCourse, setSelectedCourse] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
  }, [fetchedCourse, setValue]);

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const selectedElementRef = useRef<null | HTMLElement>(null);

  const [users, setUsers] = useState<IUser[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [pageCount, setPageCount] = useState<number | undefined>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(9);

  useEffect(() => {
    watchCourse &&
      fetchNewData(userApi.list(watchCourse, offset, limit, searchValue));
  }, [fetchNewData, watchCourse, refresh, offset, limit, searchValue]);

  useEffect(() => {
    setUsers(fetchedData.data);
    setPageCount(fetchedData.total_pages);
  }, [fetchedData]);

  const columns = [
    {
      Header: "Id",
      accessor: row => row.id
    },
    {
      Header: "Email",
      accessor: row => row.email
    },
    {
      Header: "Phone",
      accessor: row => row.phone
    },
    {
      Header: "Address",
      accessor: row => row.address
    },
    {
      Header: "Expire Date",
      accessor: row =>
        row?.userModules[0]?.expireDate
          ? moment(row?.userModules[0]?.expireDate).format("YYYY-MM-DD")
          : "Not Subscribed"
    },
    // {
    //   Header: "Verified",
    //   accessor: row => {
    //     let status = row?.verified;
    //     return (
    //       <div className="verify_tag">
    //         <Tag
    //           text={status ? "Verified" : "Unverified"}
    //           color={status ? "green" : "red"}
    //         />
    //       </div>
    //     );
    //   }
    // },
    {
      Header: "Status",
      accessor: row => {
        let status = row?.userModules[0]?.paid;
        return (
          <div
            className="status_tag"
            onClick={() => {
              setShowUnsubcribeModal(true);
              setSelectedUser(row);
            }}
            style={{ cursor: "pointer" }}
          >
            <Tag
              text={
                status
                  ? new Date(row?.userModules[0]?.expireDate) < new Date()
                    ? "Expired"
                    : "Paid"
                  : "Unpaid"
              }
              color={
                status
                  ? new Date(row?.userModules[0]?.expireDate) < new Date()
                    ? "red"
                    : "green"
                  : "red"
              }
            />
          </div>
        );
      }
    }
  ];

  // const [searchedUser, setSearchedUser] = useState<IUser[]>([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      // let filteredData = users.filter(
      //   user =>
      //     user.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      //     user.phone
      //       ?.toString()
      //       .toLowerCase()
      //       .includes(searchText.toLowerCase()) ||
      //     user.id?.toString().toLowerCase().includes(searchText.toLowerCase())
      // );
      // setSearchedUser(filteredData);
    };
  }, []);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showUnsubscribeModal, setShowUnsubcribeModal] = useState(false);

  return (
    <>
      <div className="page_header user_header">
        <h4 className="page_title">Users: {watchCourse}</h4>
        <div className="content_filter">
          <div className="course_filter">
            <CustomSelect
              id="courseFilter"
              required={true}
              register={register}
              name="courseFilter"
              placeholder="Select Course"
              value={selectedCourse}
              handleChange={data => {
                setSelectedCourse(data);
                setValue("courseFilter", data.value);
              }}
              options={courseOptions}
              disabled={false}
            />
          </div>
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link to={`/${PATH.PREMIUM_WITH_DATA}`}>
            <Button
              buttonName="Give Access"
              color="success"
              type="button"
              clickHandler={() => {}}
            />
          </Link>
        </div>
      </div>
      <div className="table_container user_table_container">
        <Tables
          data={users}
          total_pages={pageCount}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          columns={columns}
          hasError={error || courseError}
          isFetching={loading || courseLoading}
          selectedElementRef={selectedElementRef}
          userForm
          setSelectedUser={setSelectedUser}
          setShowPaymentModal={setShowPaymentModal}
        />
      </div>
      <PaymentModal
        show={showPaymentModal}
        selectedUser={selectedUser}
        setRefresh={setRefresh}
        handleClose={() => {
          setShowPaymentModal(false);
        }}
      />
      <UnsubscribeModel
        showUnsubscribeModal={showUnsubscribeModal}
        setShowUnsubscribeModal={setShowUnsubcribeModal}
        selectedUser={selectedUser}
        course={watchCourse}
        setRefresh={setRefresh}
      />
    </>
  );
}
export default User;
