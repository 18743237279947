import config from "../../config";
import http from "../../utils/http";
import toast from "../../utils/toast";
let notificationApi = config.endpoints.api.notification;

const uploadImage = async (data, newName) => {
  const formData = new FormData();
  formData.append("file", data.notifFile[0]);

  try {
    let res = await http.POST(notificationApi.upload(newName), formData);

    if (res.data.error) {
      toast.error(res.data.message);
      return Promise.reject(res.data);
    }
    return Promise.resolve(res.data);
  } catch (error) {
    toast.error(error);
  }
};

//Note: Do not reorder the parameters
const addNotification = (
  data,
  navigate,
  setIsLoading,
  toggleModal,
  setReload
) => {
  setIsLoading(true);

  let copyData = { ...data };
  const newName = `${Date.now()}.jpg`;
  if (data.notifFile && data.notifFile.length > 0) {
    copyData.notifFile = `images/push-notification/${newName}`;
  } else {
    copyData.notifFile = null;
  }

  http
    .POST(notificationApi.create, copyData)
    .then(async res => {
      if (data.notifFile && data.notifFile.length > 0) {
        var response = await uploadImage(data, newName);
        if (!response.error) {
          toast.success(res.data.message || "Notification added successfully.");
          toggleModal && toggleModal();
          toggleModal ? setReload(pre => !pre) : navigate("../");
        }
      } else {
        toast.success(res.data.message || "Notification added successfully.");
        toggleModal && toggleModal();
        toggleModal ? setReload(pre => !pre) : navigate("../");
      }
    })
    .catch(error => {
      toast.error("Something went wrong!");
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export default addNotification;
