import React, { useState, useEffect } from "react";

import "./Search.scss";

interface Props {
  handleSearch: Function;
}

export default function Search({ handleSearch }: Props) {
  const [searchText, setSearchText] = useState<string>("");
  const [firstAccess, setFirstAccess] = useState<boolean>(true);

  function handleText(e: React.ChangeEvent<HTMLInputElement>): void {
    setSearchText(e.target.value);
  }
  useEffect(() => {
    let timerKey;
    if (!firstAccess) {
      timerKey = setTimeout(() => {
        handleSearch(searchText);
      }, 400);
    }
    return () => {
      setFirstAccess(false);
      clearTimeout(timerKey);
    };
  }, [searchText, handleSearch, firstAccess]);

  return (
    <div className="search_box">
      <div className="form">
        <input
          type="search"
          id="form1"
          className="form_input "
          value={searchText}
          onChange={handleText}
          placeholder=" "
        />
        <label className="form_label" htmlFor="form1">
          Search
        </label>
      </div>
      <button
        type="button"
        className="btn"
        onClick={() => handleSearch(searchText)}
      >
        <i className="fas fa-search"></i>
      </button>
    </div>
  );
}
