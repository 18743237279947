/**
 * for getting token
 */

import { ACCESS_TOKEN_KEY } from "../constants/storage";
import { get, set } from "../utils/storage";

export function getAccessToken() {
  let accessToken: string | null = get(ACCESS_TOKEN_KEY);
  if (accessToken) {
    return JSON.parse(accessToken);
  } else {
    return null;
  }
}

/**
 * set Access-token in localstorage
 * @param {string} accessToken
 */
export function setAccessToken(accessToken: string): void {
  set(ACCESS_TOKEN_KEY, accessToken);
}

/**
 *
 * @param {object} param0 -{accessToken:string }
 */
interface setTokensParams {
  accessToken: string;
}

export function setTokens({ accessToken }: setTokensParams) {
  setAccessToken(accessToken);
}
