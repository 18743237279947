import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import moment from "moment";

import http from "../../utils/http";

import Button from "../../components/Button/Button";
import LiveTable from "../../components/LiveTable/LiveTable";
import GroupForm from "./components/liveForm/GroupForm";

import toast from "../../utils/toast";
import { PATH } from "../../constants/routes";

import useFetch from "../../hooks/useFetch";

import Search from "../../components/Search/Search";

import "./Group.scss";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";

import config from "../../config";

let courseApi = config.endpoints.api.course;
let livegroupApi = config.endpoints.api.livegroup;

function Group() {
  const selectedElementRef = useRef<null | HTMLElement>(null);

  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const [livesGroup, setLivesGroup] = useState([]);

  const [isFormUpdating, setIsFormUpdating] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setSelectedCourse(options[0]);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(livegroupApi.list(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData) {
      fetchedData.rows && setLivesGroup(fetchedData.rows);
    } else {
      setLivesGroup([]);
    }
  }, [fetchedData]);

  const columns = [
    {
      Header: "Group Title",
      accessor: "title"
    },
    {
      Header: "Seat Limit",
      accessor: "seat_limit"
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: tableRows => {
        return <div dangerouslySetInnerHTML={{__html: tableRows.row.original.description || "N/A"}} />
      }
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${livegroupApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Live Group deleted successfully");
        if (selectedElementRef?.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setLivesGroup(lives => {
              return lives.filter(course => (course.id !== id ? course : null));
            });
            fetchNewData(livegroupApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the plan"));
      }
    } catch (error) {
      if (error.hasLive) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsFormUpdating(true);
    if (data.subscription_id === "") {
      data.subscription_id = null;
    }

    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${livegroupApi.update(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          toast.success("Live Group updated successfully");
          fetchNewData(livegroupApi.list(data.module_id));
        } else {
          toast.error(new Error("Error in updating plan"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsFormUpdating(false);
    }
    pushUpdate();
  };

  const [searchedLive, setSearchedLive] = useState([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = livesGroup.filter(
        live =>
          live.title.toLowerCase().includes(searchText.toLowerCase()) ||
          live.module_id.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedLive(filteredData);
    };
  }, [livesGroup]);

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Group : {watchCourse} </h4>
        <div className="course_filter">
          <CustomSelect
            id="courseFilter"
            required={true}
            register={register}
            name="courseFilter"
            placeholder="Select Course"
            value={selectedCourse}
            handleChange={data => {
              setSelectedCourse(data);
              setValue("courseFilter", data.value);
            }}
            options={courseOptions}
            disabled={false}
          />
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <Link className="live-users" to={"/group/live-users"}>
          Users
        </Link>
        <div className="button_wrapper ">
          <Link to={PATH.ADD_GROUP}>
            <Button
              buttonName="Add Group"
              color="success"
              type="button"
              clickHandler={() => {}}
            />
          </Link>
        </div>
      </div>
      <div className="table_container livegroup_table_container">
        <LiveTable
          data={searchValue ? searchedLive : livesGroup}
          hasError={error}
          columns={columns}
          isFetching={courseOptions?.length ? loading : false}
          isLoading={isFormUpdating}
          formToEdit={<GroupForm />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        />
      </div>
    </>
  );
}
export default Group;
