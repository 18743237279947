import React, { useState, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";

import config from "../../config";
import http from "../../utils/http";
import useFetch from "../../hooks/useFetch";

import "./PaymentLog.scss";
import Tag from "../../components/Tag/Tag";
import Tables from "../../components/Tables/Tables";
import Search from "../../components/Search/Search";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import toReadableDate from "../../utils/toReadableDate";
import Button from "../../components/Button/Button";
import axios from "axios";

let courseApi = config.endpoints.api.course;

function PaymentLog() {
  let paymentApi = config.endpoints.api.payment;

  const selectedElementRef = useRef<null | HTMLElement>(null);
  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const [paymentLogs, setPaymentLogs] = useState<any[]>([]);
  const [testPayments, setTestPayments] = useState<any[]>([]);
  const [zoomPayments, setZoomPayments] = useState<any[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const {
    loading: testLoading,
    error: testError,
    fetchedData: testFetchedData,
    fetchNewData: testFetchNewData
  } = useFetch();
  const {
    loading: zoomLoading,
    error: zoomError,
    fetchedData: zoomFetchedData,
    fetchNewData: zoomFetchNewData
  } = useFetch();
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  const tabsList = ["Subscription", "Test & Series", "Zoom Plans"];

  const [activeTab, setActiveTab] = useState<{ index: number; tab: string }>({
    index: 0,
    tab: tabsList[0]
  });

  const handleTabSelect = (tab, index) => {
    document
      .getElementsByClassName("activeTab")[0]
      ?.classList.remove("activeTab");

    document.getElementById(`${tab}`)?.classList.add("activeTab");
    setActiveTab({ tab, index });
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  useEffect(() => {
    activeTab.index === 0 &&
      watchCourse &&
      fetchNewData(paymentApi.logs(watchCourse));
  }, [fetchNewData, watchCourse, paymentApi, activeTab]);

  useEffect(() => {
    if (fetchedData.data) {
      setPaymentLogs(fetchedData.data);
    } else {
      setPaymentLogs([]);
    }
  }, [fetchedData]);

  useEffect(() => {
    activeTab.index === 1 &&
      watchCourse &&
      testFetchNewData(paymentApi.testSerieslogs(watchCourse));
  }, [testFetchNewData, watchCourse, paymentApi, activeTab]);

  useEffect(() => {
    if (testFetchedData.data) {
      setTestPayments(testFetchedData.data);
    } else {
      setTestPayments([]);
    }
  }, [testFetchedData]);

  useEffect(() => {
    activeTab.index === 2 &&
      watchCourse &&
      zoomFetchNewData(paymentApi.zoomPlanlogs(watchCourse));
  }, [zoomFetchNewData, watchCourse, paymentApi, activeTab]);

  useEffect(() => {
    if (zoomFetchedData.data) {
      setZoomPayments(zoomFetchedData.data);
    } else {
      setZoomPayments([]);
    }
  }, [zoomFetchedData]);

  const columns = [
    {
      Header: "User Id",
      accessor: "userId"
    },
    {
      Header: "Phone",
      accessor: "phone"
    },
    {
      Header: "Module Id",
      accessor: "module_id"
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Merchant",
      accessor: row => {
        return (
          <div className="pay_merchant_tag">
            <Tag
              text={row.merchantType}
              color={
                row?.merchantType.includes("cash")
                  ? "orange"
                  : row?.merchantType.includes("esewa")
                  ? "green"
                  : "purple"
              }
            />
          </div>
        );
      }
    },
    {
      Header: "Status",
      accessor: row => {
        let status = row?.status === "Success";
        return (
          <div className="pay_status_tag">
            <Tag text={row.status} color={status ? "green" : "red"} />
          </div>
        );
      }
    },
    {
      Header: "Invoice Id",
      accessor: row => row.esewaRId || row.khaltiToken || row.invoiceId
    },
    {
      Header: "Date",
      accessor: row => toReadableDate(row.created_at)
    }
  ];

  const test_columns = [
    {
      Header: "User Id",
      accessor: "userId"
    },
    {
      Header: "Phone",
      accessor: "phone"
    },
    {
      Header: "Test ID",
      accessor: "testId"
    },
    {
      Header: "Module Id",
      accessor: "module_id"
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Merchant",
      accessor: row => {
        return (
          <div className="pay_merchant_tag">
            <Tag
              text={row.merchantType}
              color={
                row?.merchantType.includes("cash")
                  ? "orange"
                  : row?.merchantType.includes("esewa")
                  ? "green"
                  : "purple"
              }
            />
          </div>
        );
      }
    },
    {
      Header: "Status",
      accessor: row => {
        let status = row?.status === "Success";
        return (
          <div className="pay_status_tag">
            <Tag text={row.status} color={status ? "green" : "red"} />
          </div>
        );
      }
    },
    {
      Header: "Invoice Id",
      accessor: row => row.esewaRId || row.khaltiToken || row.invoiceId
    },
    {
      Header: "Date",
      accessor: row => toReadableDate(row.created_at)
    }
  ];

  const [searchedLog, setSearchedLog] = useState<any[]>([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = paymentLogs.filter(
        log =>
          log.merchantType.toLowerCase().includes(searchText.toLowerCase()) ||
          log.module_id.toLowerCase().includes(searchText.toLowerCase()) ||
          log.status
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.esewaRId
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.khaltiToken
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.userId.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedLog(filteredData);
    };
  }, [paymentLogs]);

  let handleTestSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = testPayments.filter(
        log =>
          log.merchantType.toLowerCase().includes(searchText.toLowerCase()) ||
          log.module_id.toLowerCase().includes(searchText.toLowerCase()) ||
          log.status
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.esewaRId
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.khaltiToken
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.invoiceId
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.userId.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedLog(filteredData);
    };
  }, [testPayments]);

  let handleZoomSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = zoomPayments.filter(
        log =>
          log.merchantType.toLowerCase().includes(searchText.toLowerCase()) ||
          log.module_id.toLowerCase().includes(searchText.toLowerCase()) ||
          log.status
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.esewaRId
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.khaltiToken
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.invoiceId
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          log.userId.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedLog(filteredData);
    };
  }, [zoomPayments]);

  const panelList = [
    <SubscriptionPanel
      searchValue={searchValue}
      searchedLog={searchedLog}
      paymentLogs={paymentLogs}
      error={error}
      columns={columns}
      courseOptions={courseOptions}
      loading={loading}
      selectedElementRef={selectedElementRef}
    />,
    <TestSeriesPanel
      searchValue={searchValue}
      searchedLog={searchedLog}
      paymentLogs={testPayments}
      error={testError}
      columns={test_columns}
      courseOptions={courseOptions}
      loading={testLoading}
      selectedElementRef={selectedElementRef}
    />,
    <ZoomPlanPanel
      searchValue={searchValue}
      searchedLog={searchedLog}
      paymentLogs={zoomPayments}
      error={zoomError}
      columns={columns}
      courseOptions={courseOptions}
      loading={zoomLoading}
      selectedElementRef={selectedElementRef}
    />
  ];

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Payment Logs: {watchCourse}</h4>
        <div className="course_filter">
          <CustomSelect
            id="courseFilter"
            required={true}
            register={register}
            name="courseFilter"
            placeholder="Select Course"
            value={selectedCourse}
            handleChange={data => {
              setSelectedCourse(data);
              setValue("courseFilter", data.value);
            }}
            options={courseOptions}
            disabled={false}
          />
        </div>
        <div className="search_wrapper ">
          <Search
            handleSearch={
              activeTab.index === 0
                ? handleSearch
                : activeTab.index === 1
                ? handleTestSearch
                : handleZoomSearch
            }
          />
        </div>
      </div>
      <div className="table_container paymentLog_table_container">
        <div className="d-flex justify-content-between items-center">
          <div className="tabs">
            {tabsList.map((tab, index) => (
              <div
                id={`${tab}`}
                key={index}
                className={`tab ${!index && "activeTab"}`}
                onClick={() => handleTabSelect(tab, index)}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="d-flex gap-2 align-items-center">
            <button
              type="button"
              style={{
                border: "none",
                whiteSpace: "nowrap",
                color: "#383838",
                padding: "8px",
                backgroundColor: "#f2f1ed",
                borderRadius: "4px"
              }}
              onClick={() =>
                axios
                  .get(
                    `https://paymentexporter.avyaas.com/offline-fq6zktwe3y`,
                    {
                      responseType: "blob"
                    }
                  )
                  .then(response => {
                    const url = window.URL.createObjectURL(response.data);

                    const link = document.createElement("a");
                    link.href = url;

                    link.setAttribute("download", "zoom_liveclass_cash.csv");

                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(url);
                  })
              }
            >
              <i className="fa fa-download"></i> Export Cash CSV
            </button>
            <button
              type="button"
              style={{
                border: "none",
                whiteSpace: "nowrap",
                color: "#383838",
                padding: "8px",
                backgroundColor: "#f2f1ed",
                borderRadius: "4px"
              }}
              onClick={() =>
                axios
                  .get(`https://paymentexporter.avyaas.com/online-fq6zktwe3y`, {
                    responseType: "blob"
                  })
                  .then(response => {
                    const url = window.URL.createObjectURL(response.data);

                    const link = document.createElement("a");
                    link.href = url;

                    link.setAttribute("download", "zoom_liveclass_app.csv");

                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(url);
                  })
              }
            >
              <i className="fa fa-download"></i> Export App CSV
            </button>
          </div>
        </div>
        <div className="panel">{panelList[activeTab.index]}</div>
      </div>
    </>
  );
}
export default PaymentLog;

const SubscriptionPanel = ({
  searchValue,
  searchedLog,
  paymentLogs,
  error,
  columns,
  courseOptions,
  loading,
  selectedElementRef
}) => {
  return (
    <>
      <Tables
        data={searchValue ? searchedLog : paymentLogs}
        feedbackTable
        hasError={error}
        columns={columns}
        isFetching={courseOptions?.length ? loading : false}
        selectedElementRef={selectedElementRef}
      />
    </>
  );
};

const TestSeriesPanel = ({
  searchValue,
  searchedLog,
  paymentLogs,
  error,
  columns,
  courseOptions,
  loading,
  selectedElementRef
}) => {
  return (
    <>
      <Tables
        data={searchValue ? searchedLog : paymentLogs}
        feedbackTable
        hasError={error}
        columns={columns}
        isFetching={courseOptions?.length ? loading : false}
        selectedElementRef={selectedElementRef}
      />
    </>
  );
};

const ZoomPlanPanel = ({
  searchValue,
  searchedLog,
  paymentLogs,
  error,
  columns,
  courseOptions,
  loading,
  selectedElementRef
}) => {
  return (
    <>
      <Tables
        data={searchValue ? searchedLog : paymentLogs}
        feedbackTable
        hasError={error}
        columns={columns}
        isFetching={courseOptions?.length ? loading : false}
        selectedElementRef={selectedElementRef}
      />
    </>
  );
};
