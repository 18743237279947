import React, { useState, useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";

import config from "../../../../config";
import toasts from "../../../../utils/toast";
import useFetch from "../../../../hooks/useFetch";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import "./ReferralForm.scss";
import "react-toastify/dist/ReactToastify.css";

let courseApi = config.endpoints.api.course;
type optionType = {
  label: string;
  value: string | number;
};

type PropsType = {
  data?: {
    id: number;
    code: string;
    course: string;
    hasLimit: number;
    limit: number;
    amount: number;
    discountPercent: number;
  };
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const ReferralForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const {
    reset,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data?.id,
        code: data && data?.code,
        course: data && data?.course,
        hasLimit: data && data?.hasLimit,
        limit: data && data?.limit,
        amount: data && data?.amount,
        discountPercent: data && data?.discountPercent
      }
    }
  );

  const watchLimit = Boolean(watch("hasLimit"));

  const handleReset = () => {
    setSelectedCourse(undefined);
    setValue("limit", undefined);
    setValue("hasLimit", undefined);
    setValue("amount", undefined);
    setValue("discountPercent", undefined);
    reset({
      code: "",
      course: ""
    });
  };

  const handleClear = () => {
    handleReset();
  };

  const handleSelectChange = (data, e) => {
    setSelectedCourse(data);
    setValue("course", data.value);
  };

  const handleLimitChange = (data, e) => {
    setSelectedLimit(data);
    setValue("hasLimit", data.value);
  };

  const {
    error: courseError,
    fetchedData: fetchedCourse,
    fetchNewData: fetchNewCourse
  } = useFetch();

  courseError && toasts.error("Something went wrong!");

  const hasLimitOptions = useMemo(
    () => [
      {
        label: "Yes",
        value: 1
      },
      {
        label: "No",
        value: 0
      }
    ],
    []
  );

  const [coursesOptions, setCourseOptions] = useState<optionType[]>([]);
  const [limitOptions] = useState(hasLimitOptions);
  const [selectedCourse, setSelectedCourse] = useState<optionType | null>();
  const [selectedLimit, setSelectedLimit] = useState<optionType>();

  const convertToOptions = lists => {
    let options: { value: string; label: string }[] = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.course) return item;
            return null;
          });
          setSelectedCourse(course);
          setValue("course", course?.value);

          setValue("hasLimit", data.hasLimit ? 1 : 0);
          setSelectedLimit(
            hasLimitOptions.find(item => {
              if (Boolean(item.value) === Boolean(data?.hasLimit)) return item;
              return null;
            })
          );
        }
      } else {
        setCourseOptions([]);
        setValue("course", undefined);
      }
    }
  }, [fetchedCourse, hasLimitOptions, setValue, data, editform]);

  return (
    <form
      className="referral-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Referral Course <span>*</span>
              </label>
              <CustomSelect
                id="course"
                required={true}
                register={register}
                name="course"
                value={selectedCourse || ""}
                handleChange={(e, data) => {
                  setValue("course", data.value);
                  handleSelectChange(e, data);
                }}
                options={coursesOptions}
                disabled={false}
              />
              {errors?.course?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate code">
              <InputField
                required
                type="text"
                label="Referral Code"
                placeholder={"Enter the referral code"}
                {...register("code", {
                  required: true
                })}
              />
              {errors?.code?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate discountPercent">
              <InputField
                required
                type="text"
                label="Discount %"
                placeholder={"Enter the discount percentage"}
                {...register("discountPercent", {
                  required: true
                })}
              />
              {errors?.discountPercent?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate code">
              <label htmlFor="course" className="mb-3">
                Has Limit? <span>*</span>
              </label>
              <CustomSelect
                id="hasLimit"
                required={true}
                register={register}
                name="hasLimit"
                value={selectedLimit || ""}
                handleChange={(e, data) => {
                  setValue("hasLimit", data.value);
                  handleLimitChange(e, data);
                }}
                options={limitOptions}
                disabled={false}
              />
              {errors?.hasLimit?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            {watchLimit && (
              <div className="fieldAndValidate limit">
                <InputField
                  type="number"
                  label="Limit"
                  placeholder={"Enter the limit number"}
                  {...register("limit", {
                    required: true
                  })}
                  disabled={!watchLimit}
                />
                {errors?.limit?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading ? "Submitting" : editform ? "Update Code" : "Add Code"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default ReferralForm;
