import React from "react";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ToggleButton from "../../../../components/ToggleButton/ToggleButton";

import "./MasterContentCard.scss";
import chapterImage from "../../../../assets/images/chapter.jpg";

function MasterContentCard({
  data,
  pos,
  toggleModal,
  handleSelect,
  setDeleteData,
  setUpdateData,
  activeChapterId,
  // handleVisibility,
  toggleEditMasterModal
}) {
  const handleDelete = () => {
    toggleModal();
    setDeleteData(data.id, data.chapter_name, data.module_id);
  };
  const handleUpdate = () => {
    setUpdateData(data);
    toggleEditMasterModal();
  };

  return (
    <div
      className={`card_container ${
        data.chapters_id === activeChapterId && "selected"
      }`}
      onClick={() => {
        handleSelect(data.chapters_id, data.chapter_name, data);
      }}
    >
      <div className="content_thumbnail">
        <img src={chapterImage} alt={data.chapter_name} />
      </div>
      <div className="master_content">
        <div className="content_details">
          <div className="content_title">
            <h6>{`${pos + 1}. ${data.chapter_name} `}</h6>
          </div>

          <div className="content_description">
            <p>{data.description}</p>
          </div>
        </div>

        <div className="content_actions">
          {/* <ToggleButton
            onLabel={"Public"}
            offLabel={"Private"}
            value={data.isPublic}
            handleChange={() => {
              // handleVisibility(chapters_id);
            }}
          /> */}
          <div className="row_action">
            <i title="Edit Content" onClick={handleUpdate}>
              <FontAwesomeIcon className="edit" icon={faEdit} />
            </i>
            <i title="Delete Content" onClick={handleDelete}>
              <FontAwesomeIcon className="trash" icon={faTrashCan} />
            </i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterContentCard;
