import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

import { UploadContextType, IError, IFile, RFile } from "../@types/context";

import http from "../utils/http";
import uploadImage from "../utils/uploadImage";

import config from "../config";
import toast from "../utils/toast";
let videContentApi = config.endpoints.api.videoContent;
let pdfContentApi = config.endpoints.api.pdfContent;
let recordingApi = config.endpoints.api.recordingContent;

export const UploadContext = createContext<UploadContextType>(
  {} as UploadContextType
);

type Props = {
  children: React.ReactNode;
};

const uploadUrl = config.fileUploadURL;

export const UploadProvider: React.FC<Props> = ({ children }) => {
  // const [file, setFile] = useState<File | null>(null);
  const [value, setValue] = useState<IFile | null>(null);
  const [rValue, setrValue] = useState<RFile | null>(null);
  const [contentType, setContentType] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadQueue, setUploadQueue] = useState<IFile[]>([]);

  const [log, setLog] = useState<IFile[]>([]);
  const [temp, setTemp] = useState<IFile | null>(null);

  const [isRecords, setIsRecords] = useState<boolean>(false);

  const [error, setError] = useState<IError>({
    status: false,
    message: ""
  });

  let alreadyAvailableNames: String[] = [];
  for (let i = 0; i < log.length; i++) {
    alreadyAvailableNames.push(log[i]?.file?.name);
  }
  for (let i = 0; i < uploadQueue.length; i++) {
    alreadyAvailableNames.push(uploadQueue[i]?.file?.name);
  }
  alreadyAvailableNames.push(temp?.file?.name!);

  const uploadFile = async (data, contentType, editform) => {
    setValue(data);
    setIsEditing(editform);
    setContentType(contentType);
    if (!editform && data.file) {
      if (alreadyAvailableNames.includes(data?.file?.name!)) {
        setError({
          status: true,
          message: "Provided File Name Already Exists"
        });
        return;
      }
    }
    if (uploadQueue === undefined) {
      setUploadQueue([data!]);
    } else {
      setUploadQueue([...uploadQueue, data!]);
    }
  };

  useEffect(() => {
    const func = async () => {
      if (uploadQueue !== undefined && uploadQueue.length > 0) {
        if (!uploading) {
          let temporary = uploadQueue.shift();
          setTemp(temporary!);
          setUploading(true);
          try {
            if (temporary) {
              if (isRecords) {
                uploadRecordingFunction(temporary);
              } else {
                uploadFunction(temporary);
              }
            } else {
              if (isRecords) {
                finalUploadRecording(
                  rValue.link,
                  rValue.length,
                  rValue.title
                ).then(() => {
                  setUploading(false);
                  setTemp(null);
                  setIsRecords(false);
                });
              } else {
                finalUploadContent(value.link, value.length, value.title).then(
                  () => {
                    setUploading(false);
                    setTemp(null);
                  }
                );
              }
            }
          } catch (err) {
            setUploading(false);
            setIsRecords(false);
          }
        }
      }
    };
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [log, temp, uploadQueue, uploading]);

  async function uploadFunction(temporary) {
    try {
      let typeName = contentType === "Video" ? "video" : "pdf";
      let key = contentType === "Video" ? "video" : "file";

      if (temporary.file) {
        const formData = new FormData();

        formData.append(key, temporary.file);
        toast.success(
          "Your upload has been queued. We will notify you when it is done."
        );
        const res = await axios.post(
          `${uploadUrl}/api/${typeName}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: process.env.REACT_APP_VIDEO_AUTH
            },
            onUploadProgress: progressEvent => {
              const progressPercentage = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setProgress(progressPercentage);
            }
          }
        );
        setLog([...log, temporary!]);
        await finalUploadContent(
          res.data.url,
          res.data.length,
          temporary.title
        );
        setRefresh(pre => !pre);
      } else {
        await finalUploadContent(temporary.url, 0, temporary.title, false);
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setUploading(false);
      setTemp(null);
    }
  }

  async function finalUploadContent(url, length, title, skipLength = false) {
    let tempLength = 0;
    if (!value.file) {
      if (value.length) {
        tempLength = value.length;
      } else {
        if (contentType === "Video") {
          const res = await axios.post(
            `${uploadUrl}/api/video/length/`,
            {
              video: value.link
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_VIDEO_AUTH
              }
            }
          );
          tempLength = res.data.length;
        }
      }
    }

    let link = "";
    let contentApi = contentType === "Video" ? videContentApi : pdfContentApi;
    switch (isEditing) {
      case true:
        link = `${contentApi.update(value.module_id)}/${value.id}`;
        break;
      case false:
        link = contentApi.create(value.module_id);
        break;
      default:
    }
    try {
      const data = {
        module_id: value.module_id,
        chapterId: value.chapterId,
        subjectId: value.subjectId,
        unitId: value.unitId,
        title: title,
        description: value.description,
        visibility: value.visibility,
        length: value.file ? length : tempLength,
        level: value.level,
        premium: value.premium,
        thumbnail: value.thumbnail,
        link: value.file ? url.split(".com/")[1] : value.link
      };
      let image = data.thumbnail;
      data.thumbnail = "";

      http.POST(link, data).then(async res => {
        if (image?.length) {
          let id = res.data.videoRes.id;
          await uploadImage(
            image[0],
            id,
            contentType === "Video" ? "video" : "pdf"
          );
          value.thumbnail = `${id}.png`;
          await http.POST(`${contentApi.update(value.module_id)}/${id}`, data);
        }
      });
    } catch (err) {
      toast.error(err.response.data.error || "Something went wrong");
    } finally {
      toast.success("Your upload has been completed.");
      setProgress(0);
    }
  }

  const uploadRecording = async (data, editform) => {
    setrValue(data);

    setIsEditing(editform);
    if (!editform && data.file) {
      if (alreadyAvailableNames.includes(data?.file?.name!)) {
        setError({
          status: true,
          message: "Provided File Name Already Exists"
        });
        return;
      }
    }
    setIsRecords(true);
    if (uploadQueue === undefined) {
      setUploadQueue([data!]);
    } else {
      setUploadQueue([...uploadQueue, data!]);
    }
  };

  async function uploadRecordingFunction(temporary) {
    try {
      let typeName = "video";
      let key = "video";

      if (temporary.file) {
        const formData = new FormData();

        formData.append(key, temporary.file);
        toast.success(
          "Your upload has been queued. We will notify you when it is done."
        );
        const res = await axios.post(
          `${uploadUrl}/api/${typeName}/live/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: process.env.REACT_APP_VIDEO_AUTH
            },
            onUploadProgress: progressEvent => {
              const progressPercentage = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setProgress(progressPercentage);
            }
          }
        );
        setLog([...log, temporary!]);
        await finalUploadRecording(
          res.data.url,
          res.data.length,
          temporary.title
        );
        setRefresh(pre => !pre);
      } else {
        await finalUploadRecording(temporary.url, 0, temporary.title, false);
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setUploading(false);
      setTemp(null);
      setIsRecords(false);
    }
  }

  async function finalUploadRecording(url, length, title, skipLength = false) {
    let tempLength = 0;
    if (!rValue.file) {
      if (rValue.length) {
        tempLength = rValue.length;
      } else {
        if (true) {
          const res = await axios.post(
            `${uploadUrl}/api/video/length/`,
            {
              video: rValue.link
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_VIDEO_AUTH
              }
            }
          );
          tempLength = res.data.length;
        }
      }
    }

    let link = "";
    let contentApi = recordingApi;
    let isUpdating = false;
    switch (isEditing) {
      case true:
        link = `${contentApi.update(rValue.module_id)}/${rValue.id}`;
        isUpdating = true;
        break;
      case false:
        link = contentApi.create(rValue.module_id);
        isUpdating = false;
        break;
      default:
    }
    try {
      const data = {
        module_id: rValue.module_id,
        live_id: rValue.live_id,
        title: title,
        description: rValue.description,
        length: rValue.file ? length : tempLength,
        thumbnail: rValue.thumbnail,
        link: rValue.file ? url.split(".com/")[1] : rValue.link
      };
      let image = data.thumbnail;
      if (typeof image !== "string") {
        data.thumbnail = "";
      } else {
        image = "";
      }

      http.POST(link, data).then(async res => {
        if (image?.length) {
          let id = isUpdating ? rValue.id : res.data.recordingRes.id;
          await uploadImage(image[0], id, "recordings");
          data.thumbnail = `${id}.png`;
          await http.POST(`${contentApi.update(rValue.module_id)}/${id}`, data);
        }
      });
    } catch (err) {
      toast.error(err.response.data.error || "Something went wrong");
    } finally {
      toast.success("Your upload has been completed.");
      setProgress(0);
      setrValue(null);
    }
  }

  return (
    <UploadContext.Provider
      value={{
        value,
        refresh,
        setValue,
        uploadFile,
        progress,
        setProgress,
        uploadQueue,
        log,
        temp,
        error,
        isEditing,
        setIsEditing,
        uploadRecording
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = () => useContext(UploadContext);
