import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Unit from "../pages/Unit/Unit";
import AddUnit from "../pages/Unit/AddUnit";

import { PATH } from "../constants/routes";
import { getAccessToken } from "../services/token";

export default function UnitRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Unit />} />
      <Route path={PATH.ADD_UNIT} element={<AddUnit />} />
    </Routes>
  );
}
