import React, { useState, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";

import config from "../../config";

import useFetch from "../../hooks/useFetch";

import "./Feedback.scss";
import Tables from "../../components/Tables/Tables";
import Search from "../../components/Search/Search";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

import http from "../../utils/http";
let courseApi = config.endpoints.api.course;

function Feedback() {
  let feedbackApi = config.endpoints.api.feedback;

  const selectedElementRef = useRef<null | HTMLElement>(null);
  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const [feedbacks, setFeedbacks] = useState([]);

  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(feedbackApi.list(watchCourse));
  }, [fetchNewData, watchCourse, feedbackApi]);

  useEffect(() => {
    if (fetchedData.data.length) {
      setFeedbacks(fetchedData.data);
    } else {
      setFeedbacks([]);
    }
  }, [fetchedData]);

  const columns = [
    {
      Header: "Id",
      accessor: "id"
    },

    {
      Header: "Module Id",
      accessor: "module_id"
    },
    {
      Header: "User Id",
      accessor: "userId"
    },
    {
      Header: "Feedback",
      accessor: row => {
        return (
          <div className="feedbackDetails">
            <p>{row.description}</p>
          </div>
        );
      }
    }
  ];

  const [searchedFeedback, setSearchedFeedback] = useState([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = feedbacks.filter(
        feedback =>
          feedback.description
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          feedback.module_id
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          feedback.userId
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      setSearchedFeedback(filteredData);
    };
  }, [feedbacks]);

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">User Feedbacks</h4>
        <div className="course_filter">
          <CustomSelect
            id="courseFilter"
            required={true}
            register={register}
            name="courseFilter"
            placeholder="Select Course"
            value={selectedCourse}
            handleChange={data => {
              setSelectedCourse(data);
              setValue("courseFilter", data.value);
            }}
            options={courseOptions}
            disabled={false}
          />
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
      </div>
      <div className="table_container feedback_table_container">
        <Tables
          data={searchValue ? searchedFeedback : feedbacks}
          feedbackTable
          hasError={error}
          columns={columns}
          isFetching={courseOptions?.length ? loading : false}
          selectedElementRef={selectedElementRef}
        />
      </div>
    </>
  );
}
export default Feedback;
