interface SYSTEM_ACCESS_ID_TYPE {
  DASHBOARD: string;
  ACCOUNT: string;
  USER: string;
  INSTRUCTOR: string;
  COURSE_GROUP: string;
  COURSE: string;
  SUBJECT: string;
  UNIT: string;
  CHAPTER: string;
  CONTENT: string;
  SUBSCRIPTION: string;
  ZOOMPLAN: string;
  REFERRAL: string;
  NOTIFICATION: string;
  FEEDBACK: string;
  PAYMENTLOG: string;
  QUIZ: string;
  LIVE: string;
  GROUP: string;
  TESTSERIES: string;
}

export const SYSTEM_ACCESS_ID: SYSTEM_ACCESS_ID_TYPE = {
  DASHBOARD: "dashboard",
  ACCOUNT: "account",
  USER: "user",
  INSTRUCTOR: "instructor",
  COURSE_GROUP: "course_group",
  COURSE: "course",
  SUBJECT: "subject",
  UNIT: "unit",
  CHAPTER: "chapter",
  CONTENT: "content",
  SUBSCRIPTION: "subscription",
  ZOOMPLAN: "zoomplan",
  REFERRAL: "referral",
  NOTIFICATION: "notification",
  FEEDBACK: "feedback",
  PAYMENTLOG: "paymentlogs",
  QUIZ: "quiz",
  LIVE: "live",
  GROUP: "group",
  TESTSERIES: "testseries"
};

export const permission = role => {
  if (role === "superadmin") {
    return [
      "dashboard",
      "account",
      "user",
      "course",
      "subject",
      "unit",
      "chapter",
      "content"
    ];
  } else if (role === "schoolmanager") {
    return ["dashboard", "user", "course", "subject", "unit", "chapter"];
  } else if (role === "contentmanager") {
    return ["dashboard", "content"];
  }
};
