import React, { useEffect, useState } from "react";

import "./LiveDetail.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import config from "../../../config";
import http from "../../../utils/http";
import toast from "../../../utils/toast";
import Loader from "../../../components/Loader/Loader";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import toReadableDate from "../../../utils/toReadableDate";
import moment, { duration } from "moment";

let liveApi = config.endpoints.api.live;

function LiveDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const meet_id = params.meet_id;
  const live_id = queryParams.get("live_id");
  const module_id = queryParams.get("module_id");
  const meet_type = queryParams.get("meet_type");

  useEffect(() => {
    if (!meet_id || !live_id || !module_id || !meet_type) {
      navigate("/group");
    }
  });

  const [occurrences, setOccurrences] = useState<any[]>();
  const [live, setLive] = useState<any>();
  const [activeDate, setActiveDate] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    http
      .GET(liveApi.detail(module_id, meet_id))
      .then(res => {
        if (res.data) {
          setLive(res.data);
          if (res.data.occurrences) {
            setOccurrences(res.data.occurrences);
          }
        }
      })
      .catch(error => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [meet_id, module_id]);

  const handleSchedule = (start, duration) => {
    let data = {
      start: moment(start).utc(),
      duration: duration
    };

    setActiveDate(data);
  };

  useEffect(() => {
    async function pushUpdate(data) {
      try {
        setIsUpdating(true);
        const response = await http.POST(
          `${liveApi.updateTime(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toast.success("Live updated successfully");
          navigate("/group");
        } else {
          toast.error(new Error("Error in updating plan"));
        }
        setIsUpdating(false);
      } catch (error) {
        toast.error(error);
        setIsUpdating(false);
      }
    }

    if (activeDate && activeDate) {
      const data = {
        module_id: module_id,
        id: live_id,
        start_time: activeDate.start,
        duration: activeDate.duration
      };
      pushUpdate(data);
    }
  }, [activeDate]);

  return (
    <>
      {Number(meet_type) === 8 ? (
        isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {live ? (
              <div className="liveDetail">
                <h1>Live Detail - {live.topic}</h1>

                <div className="list">
                  {occurrences ? (
                    <>
                      {occurrences.map((item, i) => {
                        let start_date = moment(item.start_time).toDate();
                        let start = toReadableDate(start_date);
                        return (
                          <div className="item" key={i}>
                            <div>
                              <p>
                                Start: <span>{start}</span>
                              </p>
                              <p>
                                Duration: <span>{item.duration} Mins</span>
                              </p>
                            </div>
                            <div className="last">
                              <button
                                disabled={isUpdating}
                                onClick={() => {
                                  handleSchedule(item.start_date, duration);
                                }}
                              >
                                {isUpdating ? (
                                  <AiOutlineLoading3Quarters />
                                ) : (
                                  "Re-schedule Meeting"
                                )}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <p>No Occurences found for this meeting.</p>
                  )}
                </div>
              </div>
            ) : (
              <h1 className="not-found">Live not found</h1>
            )}
          </>
        )
      ) : (
        <h1 className="not-found">
          This live is Scheduled Meeting, with no repeats.
        </h1>
      )}
    </>
  );
}

export default LiveDetail;
