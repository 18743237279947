import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    minHeight: "15px",
    height: "33px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "row",
    padding: "0px",
    border: state.isFocused ? "1px solid #bbb" : "1px solid #bbb",
    "&:hover": {
      border: "1px solid #555"
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    margin: "0px",
    padding: "0 8px"
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px"
  }),
  indicatorSeparator: state => ({
    display: "none"
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px"
  })
};

export default function CustomSelect(props) {
  let {
    name,
    label,
    options,
    value,
    handleChange,
    disabled,
    required,
    register,
    placeholder,
    ...rest
  } = props;

  return (
    <Select
      {...register(name, { required })}
      {...rest}
      options={options}
      value={value}
      onChange={(e, data) => handleChange(e, data)}
      isDisabled={disabled}
      styles={customStyles}
      placeholder={placeholder}
    />
  );
}
