import React, { useState } from "react";
import InstructorForm from "./components/instructorForm/InstructorForm";
import Button from "../../components/Button/Button";
import http from "../../utils/http";
import toast from "../../utils/toast";

import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./AddInstructor.scss";

import config from "../../config";
import uploadImage from "../../utils/uploadImage";

let instructorApi = config.endpoints.api.instructor;

function AddInstructor() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClickSubmit = data => {
    setIsSubmitting(true);

    let image = data.image_id;
    data.image_id = "";

    http
      .POST(instructorApi.create(data.module_id), data)
      .then(async res => {
        if (image?.length) {
          let id = res.data.teacherRes.id;
          await uploadImage(image[0], id, "nameteacher/profile");
          data.image_id = `images/nameteacher/profile/${id}.png`;
          await http.POST(
            `${instructorApi.update(data.module_id)}/${id}/`,
            data
          );
        }
        toast.success("Instructor Added Successfully!");
        setTimeout(() => {
          navigate("../");
        }, 1000);
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Instructors | Add Instructor</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            buttonName="< Back"
            color="success"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_unit_form">
        <InstructorForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isSubmitting}
        />
      </div>
    </>
  );
}

export default AddInstructor;
