import React from "react";
import "./Profile.scss";
import { getUser } from "../../utils/storage";

export default function Profile() {
  const userDetail: { [key: string | number]: any } | null = getUser();

  let joinedRoles = "";
  userDetail?.roles?.map((each, index) => {
    return (joinedRoles += `${each.name}${
      userDetail?.roles.length === index + 1 ? "" : ","
    } `);
  });

  return (
    <div
      className="mh-100 row  g-0 profile_page"
      style={{
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
        // border: "1px solid red"
      }}
    >
      <div
        className="col-sm-3 mh-100  text-center"
        style={{
          borderTopLeftRadius: ".7rem",
          borderBottomLeftRadius: ".7rem",
          background: "#2C529C"
        }}
      >
        <img
          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
          alt="Avatar"
          className="my-5"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%"
          }}
        />
        <h5 style={{ color: "white" }}>{`${userDetail?.name}`}</h5>
        <p style={{ color: "white" }}>{joinedRoles}</p>
        <i style={{ color: "white" }} className="far fa-edit"></i>

        <div className="d-flex justify-content-center profile_icons ">
          <a href="#!">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#!">
            {" "}
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#!">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className="col">
        <div className="p-5">
          <h6>Information</h6>
          <hr className="mt-0 mb-4" />
          <div className="row pt-1">
            <div className="col mb-5">
              <h6>Email</h6>
              <p className="text-muted">{userDetail?.email}</p>
            </div>
            <div className="col mb-5">
              <h6>Phone</h6>
              <p className="text-muted">{userDetail?.phone}</p>
            </div>
          </div>

          <h6>Access and Permission</h6>
          <hr className="mt-0 mb-4" />
          <div className="row pt-1">
            <div className="col mb-3">
              <h6>Name Online Staff</h6>
              <p className="text-muted">Can perform action specified in role</p>
            </div>
            <div className="col mb-3">
              <h6>Joined</h6>
              <p className="text-muted">December 7th, 2022</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
