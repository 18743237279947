import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import CourseForm from "./components/courseGroupForm/CourseGroupForm";
import toast from "../../utils/toast";
import { PATH } from "../../constants/routes";
import useFetch from "../../hooks/useFetch";
import Search from "../../components/Search/Search";
import "./CourseGroup.scss";
import config from "../../config";
import convertHtmlToText from "../../utils/convertHtmlToText";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import DragDropTable from "../../components/Tables/DragDropTable";
let courseGroupApi = config.endpoints.api.courseGroup;

interface ICourseGroup {
  id: number;
  name: string;
  description: string;
  thumbnail: null | string | HTMLInputElement;
  groupId: string;
}

function CourseGroup() {
  const selectedElementRef = useRef<null | HTMLElement>(null);
  const [disableDrag, setDisableDrag] = useState(true);
  const [courseGroup, setCourseGroup] = useState<ICourseGroup[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<ICourseGroup>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [isFormUpdating, setIsFormUpdating] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  useEffect(() => {
    fetchNewData(courseGroupApi.list);
  }, [fetchNewData]);

  useEffect(() => {
    if (fetchedData.data) {
      setCourseGroup(fetchedData.data);
    }
  }, [fetchedData]);

  const columns = [
    {
      Header: "Id",
      accessor: "id"
    },
    {
      Header: "Category",
      accessor: row => `${row.name}`,
      Cell: tableProps => {
        let original = tableProps.row.original;

        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              {original.thumbnail ? (
                <img
                  src={`${original.thumbnail}`}
                  alt={`thumbnail ${original.name}`}
                />
              ) : (
                <i className="bx bxs-layer"></i>
              )}
            </div>
            {`${original.name}`}
          </div>
        );
      }
    },
    {
      Header: "Category Id",
      accessor: row => row.groupId
    },
    {
      Header: "Description",
      accessor: row => {
        return (
          <div className="feedbackDetails">
            <p>{convertHtmlToText(row?.description) || "No description"}</p>
          </div>
        );
      }
    },
    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "10px"
          }}
        >
          Actions
        </div>
      ),
      accessor: "action",
      disableSortBy: true,
      Cell: tableProps => {
        const original = tableProps.row.original;
        return (
          <>
            <td
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "baseline",
                justifyItems: "flex-start"
              }}
            >
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowEditModal(true);
                  setSelectedCourse(original);
                }}
                className="edit_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faEdit}
              />
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowDeleteModal(true);
                  setSelectedCourse(original);
                }}
                className="trash_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faTrashCan}
              />
            </td>
          </>
        );
      }
    }
  ];

  const handleDelete = async id => {
    try {
      const response = await http.REMOVE(`${courseGroupApi.delete}/${id}/`);
      if (response.status === 200) {
        toast.success("Category deleted successfully");
        if (selectedElementRef?.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setCourseGroup(courses => {
              return courses.filter(course =>
                course.id !== id ? course : null
              );
            });
            fetchNewData(courseGroupApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting category"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsFormUpdating(true);

    data.groupId = data.groupId.toUpperCase();

    if (!data?.thumbnail?.length) {
      delete data.thumbnail;
    } else {
      if (typeof data.thumbnail === "string") {
        delete data.thumbnail;
      } else {
        data.thumbnail = data.thumbnail[0];
      }
    }

    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${courseGroupApi.update}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          toast.success("Category updated successfully");
          fetchNewData(courseGroupApi.list);
        } else {
          toast.error(new Error("Error in updating category"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsFormUpdating(false);
    }
    pushUpdate();
  };

  const [searchedCategory, setSearchedCategory] = useState<ICourseGroup[]>([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = courseGroup.filter(
        course =>
          course.name.toLowerCase().includes(searchText.toLowerCase()) ||
          course.groupId.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedCategory(filteredData);
    };
  }, [courseGroup]);

  const handlePositionUpdate = () => {
    http
      .POST(courseGroupApi.updatePosition, {
        data:
          courseGroup &&
          courseGroup.map(item => {
            return item.id;
          })
      })
      .then(() => {
        toast.success("Category Position Updated Successfully");
        fetchNewData(courseGroupApi.list);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Categories</h4>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="arrange_dragdrop">
          <div
            className="arrange_button"
            onClick={() => {
              setDisableDrag(pre => !pre);
              !disableDrag && handlePositionUpdate();
            }}
          >
            <div style={{ fontSize: "14px" }}>Arrange</div>
            {disableDrag ? (
              <i className="bx bxs-sort-alt" title="Reorder Contents"></i>
            ) : (
              <i className="bx bx-check tick" title="Save Changes"></i>
            )}
          </div>
          <div className="button_wrapper ">
            <Link to={PATH.ADD_COURSE_GROUP}>
              <Button buttonName="Add Category" color="success" type="button" />
            </Link>
          </div>
        </div>
      </div>
      <div className="table_container coursegroup_table_container">
        <DragDropTable
          disableDrag={disableDrag}
          data={searchValue ? searchedCategory : courseGroup}
          columns={columns}
          setData={setCourseGroup}
          hasCheckBox={true}
          selectedElementRef={selectedElementRef}
          onSelectRows={() => {}}
          isFetching={loading}
          formToEdit={<CourseForm />}
          handleDelete={handleDelete}
          isLoading={isFormUpdating}
          hasError={error}
          handleClickUpdate={data => handleClickUpdate(data)}
        />
      </div>
      <Modal
        size="lg"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName={"modal_container"}
        centered
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Update Category</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modalBody">
          {React.cloneElement(
            <CourseForm handleClickUpdate={() => handleClickUpdate} />,
            {
              data: selectedCourse,
              editform: 1,
              handleCancel: () => setShowEditModal(false),
              loading
            }
          )}
        </Modal.Body>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={selectedCourse?.id}
        name={selectedCourse?.name}
        handleDelete={handleDelete}
      />
    </>
  );
}
export default CourseGroup;
