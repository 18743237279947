import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

import Button from "../../components/Button/Button";
import SubscriptionForm from "./components/subscriptionForm/SubscriptionForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import toast from "../../utils/toast";
import { PATH } from "../../constants/routes";

import useFetch from "../../hooks/useFetch";

import Search from "../../components/Search/Search";

import "./Subscription.scss";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";

import config from "../../config";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import DragDropTable from "../../components/Tables/DragDropTable";
let courseApi = config.endpoints.api.course;
let subscriptionApi = config.endpoints.api.subscription;

function Subscription() {
  const selectedElementRef = useRef<null | HTMLElement>(null);

  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const [subscriptions, setSubscription] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState<any>();

  const [isFormUpdating, setIsFormUpdating] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchedSubscription, setSearchedSubscription] = useState([]);

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [disableDrag, setDisableDrag] = useState(true);

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(subscriptionApi.list(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData.data.length) {
      setSubscription(fetchedData.data);
    } else {
      setSubscription([]);
    }
  }, [fetchedData]);

  const columns = [
    {
      Header: "Subscription Title",
      accessor: row => `${row.title}`,
      Cell: tableProps => {
        let original = tableProps.row.original;
        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              <i className="bx bx-credit-card"></i>
            </div>
            {original.title}
          </div>
        );
      }
    },
    {
      Header: "Course",
      accessor: "module_id"
    },
    {
      Header: "Period",
      accessor: row => `${row.period} ${row.period > 1 ? "days" : "day"}`
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Discount",
      accessor: "discount"
    },
    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "10px"
          }}
        >
          Actions
        </div>
      ),
      accessor: "action",
      disableSortBy: true,
      Cell: tableProps => {
        const original = tableProps.row.original;
        return (
          <>
            <td
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "baseline",
                justifyItems: "flex-start"
              }}
            >
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowEditModal(true);
                  setSelectedSubscription(original);
                }}
                className="edit_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faEdit}
              />
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowDeleteModal(true);
                  setSelectedSubscription(original);
                }}
                className="trash_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faTrashCan}
              />
            </td>
          </>
        );
      }
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${subscriptionApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Plan deleted successfully");
        if (selectedElementRef?.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setSubscription(subscriptions => {
              return subscriptions.filter(course =>
                course.id !== id ? course : null
              );
            });
            fetchNewData(subscriptionApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the plan"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsFormUpdating(true);

    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${subscriptionApi.update(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          toast.success("Plan updated successfully");
          fetchNewData(subscriptionApi.list(data.module_id));
        } else {
          toast.error(new Error("Error in updating plan"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsFormUpdating(false);
    }
    pushUpdate();
  };

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = subscriptions?.filter(
        subscription =>
          subscription?.title
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          subscription?.module_id
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      setSearchedSubscription(filteredData);
    };
  }, [subscriptions]);

  const handlePositionUpdate = () => {
    http
      .POST(subscriptionApi.updatePosition(selectedCourse?.value), {
        data:
          subscriptions &&
          subscriptions.map(item => {
            return item.id;
          })
      })
      .then(() => {
        toast.success("Subscription Position Updated Successfully");
        fetchNewData(subscriptionApi.list);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Subscription Plans : {watchCourse} </h4>
        <div className="course_filter">
          <CustomSelect
            id="courseFilter"
            required={true}
            register={register}
            name="courseFilter"
            placeholder="Select Course"
            value={selectedCourse}
            handleChange={data => {
              setSelectedCourse(data);
              setValue("courseFilter", data.value);
            }}
            options={courseOptions}
            disabled={false}
          />
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="arrange_dragdrop">
          <div
            className="arrange_button"
            onClick={() => {
              setDisableDrag(pre => !pre);
              !disableDrag && handlePositionUpdate();
            }}
          >
            <div style={{ fontSize: "14px" }}>Arrange</div>
            {disableDrag ? (
              <i className="bx bxs-sort-alt" title="Reorder Contents"></i>
            ) : (
              <i className="bx bx-check tick" title="Save Changes"></i>
            )}
          </div>
          <div className="button_wrapper ">
            <Link to={PATH.ADD_SUBSCRIPTION}>
              <Button
                buttonName="Add Plan"
                color="success"
                type="button"
                clickHandler={() => {}}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="table_container subscription_table_container">
        <DragDropTable
          disableDrag={disableDrag}
          data={searchValue ? searchedSubscription : subscriptions}
          columns={columns}
          setData={setSubscription}
          hasCheckBox={true}
          selectedElementRef={selectedElementRef}
          onSelectRows={() => {}}
          isFetching={loading}
          formToEdit={<SubscriptionForm />}
          handleDelete={handleDelete}
          isLoading={isFormUpdating}
          hasError={error}
          handleClickUpdate={data => handleClickUpdate(data)}
        />
        {/* <Tables
          data={searchValue ? searchedSubscription : subscriptions}
          hasError={error}
          columns={columns}
          isFetching={courseOptions?.length ? loading : false}
          isLoading={isFormUpdating}
          formToEdit={<SubscriptionForm />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        /> */}
      </div>
      <Modal
        size="lg"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName={"modal_container"}
        centered
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Update Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modalBody">
          {React.cloneElement(
            <SubscriptionForm handleClickUpdate={() => handleClickUpdate} />,
            {
              data: selectedSubscription,
              editform: 1,
              handleCancel: () => setShowEditModal(false),
              loading
            }
          )}
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={selectedSubscription?.id}
        name={selectedSubscription?.title}
        handleDelete={handleDelete}
      />
    </>
  );
}
export default Subscription;
