import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import config from "../../../../config";
import toasts from "../../../../utils/toast";
import useFetch from "../../../../hooks/useFetch";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import "./GroupForm.scss";
import "react-toastify/dist/ReactToastify.css";

let courseApi = config.endpoints.api.course;
let subscriptionApi = config.endpoints.api.subscription;
let testSeriesApi = config.endpoints.api.testseries;

type courseType = {
  label: string;
  value: string;
};

type subsType = {
  label: string;
  value: number;
};

type PropsType = {
  data?: {
    id: number;
    title: string;
    module_id: string;
    amount: number;
    seat_limit: number;
    description: string;
    subscription_id: number | null;
    testSeriesId: number | null;
  };
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const GroupForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const {
    reset,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data?.id,
        title: data && data?.title,
        module_id: data && data?.module_id,
        amount: data && data?.amount,
        seat_limit: data && data?.seat_limit,
        description: data && data?.description,
        subscription_id: data && data?.subscription_id,
        testSeriesId: data && data?.testSeriesId,
      }
    }
  );

  const handleReset = () => {
    setSelectedCourse(undefined);
    setValue("amount", undefined);
    setValue("seat_limit", undefined);
    setValue("subscription_id", null);
    setValue("testSeriesId", null);
    reset({
      title: "",
      module_id: "",
      description: "",
    });
  };

  const handleClear = () => {
    handleReset();
  };

  const handleSelectChange = (data, e) => {
    setSelectedCourse(data);
    setValue("module_id", data.value);
  };

  const handleSelectSubsChange = (data, e) => {
    setSelectedSubs(data);
    setValue("subscription_id", data.value);
  };

  const handleSelectSeriesChange = (data, e) => {
    setSelectedSeries(data);
    setValue("testSeriesId", data.value);
  };

  const {
    error: courseError,
    fetchedData: fetchedCourse,
    fetchNewData: fetchNewCourse
  } = useFetch();

  const {
    error: subsError,
    fetchedData: fetchedSubs,
    fetchNewData: fetchNewSubs
  } = useFetch();

  const {
    error: seriesError,
    fetchedData: fetchedSeries,
    fetchNewData: fetchNewSeries
  } = useFetch();

  courseError && toasts.error("Something went wrong!");

  const watchCourse = watch("module_id");

  const [coursesOptions, setCourseOptions] = useState<courseType[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<any>(
    editform && data.module_id
  );

  const [subsOptions, setSubsOptions] = useState<subsType[]>([]);
  const [selectedSubs, setSelectedSubs] = useState<any>(
    editform && data.subscription_id
  );

  const [seriesOptions, setSeriesOptions] = useState<subsType[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<any>(
    editform && data.subscription_id
  );

  const convertToOptions = lists => {
    let options: { value: string; label: string }[] = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  const convertToSubsOptions = lists => {
    let options: { value: number; label: string }[] = lists.map(item => {
      return {
        value: item.id,
        label: item.title
      };
    });

    let modifiedOptions = [
      {
        value: null,
        label: "None"
      },
      ...options
    ]
    return modifiedOptions;
  };

  const convertToSeriesOptions = lists => {
    let options: { value: number; label: string }[] = lists.map(item => {
      return {
        value: item.id,
        label: item.title
      };
    });

    let modifiedOptions = [
      {
        value: null,
        label: "None"
      },
      ...options
    ]
    return modifiedOptions;
  };

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);
        setValue("module_id", options[0].value);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
          setValue("module_id", course?.value);
        }
      } else {
        setCourseOptions([]);
        setValue("module_id", undefined);
      }
    }
  }, [fetchedCourse, setValue, data, editform]);

  useEffect(() => {
    watchCourse && fetchNewSubs(subscriptionApi.list(watchCourse));
  }, [fetchNewSubs, watchCourse]);

  useEffect(() => {
    if (fetchedSubs.data) {
      if (fetchedSubs.data.length) {
        let options = convertToSubsOptions(fetchedSubs.data);
        setSubsOptions(options);
        setValue("subscription_id", options[0].value);

        if (editform) {
          let subs = options.find(item => {
            if (item.value === data?.subscription_id) return item;
            return null;
          });
          setSelectedSubs(subs);
          setValue("subscription_id", subs?.value);
        }
      } else {
        setSubsOptions([]);
        setValue("subscription_id", null);
      }
    }
  }, [fetchedSubs, setValue, data, editform]);

  useEffect(() => {
    watchCourse && fetchNewSeries(testSeriesApi.listSeries(watchCourse));
  }, [fetchNewSeries, watchCourse]);

  useEffect(() => {
    if (fetchedSeries.rows) {
      if (fetchedSeries.rows.length) {
        let options = convertToSeriesOptions(fetchedSeries.rows);
        setSeriesOptions(options);
        setValue("testSeriesId", options[0].value);

        if (editform) {
          let subs = options.find(item => {
            if (item.value === data?.subscription_id) return item;
            return null;
          });
          setSelectedSeries(subs);
          setValue("testSeriesId", subs?.value);
        }
      } else {
        setSeriesOptions([]);
        setValue("testSeriesId", null);
      }
    }
  }, [fetchedSeries, setValue, data, editform]);

  return (
    <form
      className="live-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Course <span>*</span>
              </label>
              <CustomSelect
                id="module_id"
                required={true}
                register={register}
                name="module_id"
                value={selectedCourse || ""}
                handleChange={(e, data) => {
                  setValue("module_id", data.value);
                  handleSelectChange(e, data);
                }}
                options={coursesOptions}
                disabled={false}
              />
              {errors?.module_id?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate">
              <label htmlFor="subscription_id">
                Subscription
              </label>
              <CustomSelect
                id="subscription_id"
                required={false}
                register={register}
                name="subscription_id"
                value={selectedSubs || ""}
                handleChange={(e, data) => {
                  setValue("subscription_id", data.value);
                  handleSelectSubsChange(e, data);
                }}
                options={subsOptions}
                disabled={false}
              />
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="testSeriesId">
                Test Series
              </label>
              <CustomSelect
                id="testSeriesId"
                required={false}
                register={register}
                name="testSeriesId"
                value={selectedSeries || ""}
                handleChange={(e, data) => {
                  setValue("testSeriesId", data.value);
                  handleSelectSeriesChange(e, data);
                }}
                options={seriesOptions}
                disabled={false}
              />
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate title">
              <InputField
                required
                type="text"
                label="Group Title"
                placeholder={"Enter the group title"}
                {...register("title", {
                  required: true
                })}
              />
              {errors?.title?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate period">
              <InputField
                required
                type="number"
                label="Amount"
                placeholder={"Enter the Amount"}
                {...register("amount", {
                  required: true
                })}
              />
              {errors?.amount?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>

          <div className="row-container">
            <div className="fieldAndValidate title">
              <InputField
                required
                type="number"
                label="Seat Limit"
                placeholder={"Enter the Seat Limit"}
                {...register("seat_limit", {
                  required: true
                })}
              />
              {errors?.seat_limit?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate title">
              <InputField
                required
                type="text"
                label="Group Description"
                placeholder={"Enter the group description"}
                {...register("description", {
                  required: false
                })}
              />
              {errors?.description?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading ? "Submitting" : editform ? "Update Group" : "Add Group"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default GroupForm;
