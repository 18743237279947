import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";

import { getAccessToken } from "../services/token";
import AddTestSeries from "../pages/TestSeries/AddTestSeries";
import TestSeries from "../pages/TestSeries/TestSeries";

export default function TestSeriesRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<TestSeries />} />
      <Route path={PATH.ADD_TESTSERIES} element={<AddTestSeries />} />
    </Routes>
  );
}
