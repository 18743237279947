import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";

import Course from "../pages/Course/Course";
import AddCourse from "../pages/Course/AddCourse";
import { getAccessToken } from "../services/token";

export default function CourseRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Course />} />
      <Route path={PATH.ADD_COURSE} element={<AddCourse />} />
    </Routes>
  );
}
