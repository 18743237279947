import React, { useEffect, useState } from "react";
import config from "../../../config";
import http from "../../../utils/http";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Spin } from "antd";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const value = tooltipItem.yLabel;
          return `${label}: ${value}`;
        }
      }
    }
  }
};

interface UsersByModuleCountProps {
  enrollUsersByModule: {
    userModuleId: string;
    count: number;
  }[];
  enrollPaidUsersByModule: {
    userModuleId: string;
    count: number;
  }[];
  enrollFreeUsersByModule: {
    userModuleId: string;
    count: number;
  }[];
}

const EnrollmentBarChart = () => {
  const [userByModule, setUserbyModule] =
    useState<UsersByModuleCountProps | null>(null);
  const apiUrlFirst = `${config.baseURI}/api/v1/count/usersByModuleCount`;

  useEffect(() => {
    http.GET(apiUrlFirst).then(response => {
      let users = response.data.data;
      setUserbyModule(users);
    });
  }, []);

  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(e.target.value);
  };

  if (!userByModule) {
    return <Spin tip="Loading" size="large" />;
  }

  let allUsers = userByModule?.enrollUsersByModule;
  let paidUsers = userByModule?.enrollPaidUsersByModule;
  let freeUsers = userByModule?.enrollFreeUsersByModule;

  //filter the array
  const filteredAllUsers = allUsers.filter(
    user => user.userModuleId === selectedCourse
  );

  const filteredPaidUsers = paidUsers.filter(
    user => user.userModuleId === selectedCourse
  );

  const filteredFreeUsers = freeUsers.filter(
    user => user.userModuleId === selectedCourse
  );

  const totalDefaultValue = allUsers?.[0]?.count;
  const paidDefaultValue = paidUsers?.[0]?.count;
  const freeDefaultValue = freeUsers?.[0]?.count;

  const defaultValue = [totalDefaultValue, paidDefaultValue, freeDefaultValue];

  const labels = ["Total", "Paid", "Free"];

  const data = {
    labels,
    datasets: [
      {
        label: "Enrolled users",
        data: [
          filteredAllUsers?.length
            ? filteredAllUsers?.map(item => item.count)[0]
            : defaultValue[0],
          filteredPaidUsers?.length
            ? filteredPaidUsers?.map(item => item.count)[0]
            : defaultValue[1],
          filteredFreeUsers?.length
            ? filteredFreeUsers?.map(item => item.count)[0]
            : defaultValue[2]
        ],
        backgroundColor: [
          "#fecaca",
          "rgba(53, 162, 235, 0.5)",
          "rgba(153, 102, 255, 0.2)"
        ],
        borderColor: [
          "#fecaca",
          "rgba(53, 162, 235, 0.5)",
          "rgba(153, 102, 255, 0.2)"
        ],
        borderWidth: 1
      }
    ]
  };

  return (
    <div>
      <div
        className="card_select"
        style={{ paddingBottom: "10px", paddingTop: "6px", width: "50%" }}
      >
        <label htmlFor="select-plan">Select a module</label>
        <select
          onChange={handleSelect}
          className="form-select form-select-md"
          aria-label=".form-select-md example"
          value={selectedCourse}
        >
          {allUsers.map(row => (
            <option key={row.userModuleId} value={row.userModuleId}>
              {row.userModuleId}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{ height: "270px", display: "flex", justifyContent: "center" }}
      >
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default EnrollmentBarChart;
