import React from "react";
import styles from "./Textarea.module.scss";

type PropsType = {
  rest?: any;
  rows: number;
  name: string;
  cols?: number;
  error?: Error;
  label: string;
  value?: string;
  border?: string;
  errorMsg?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder: string;
  setValue?: Function;
  validateInput?: Function;
};

type RefType = HTMLTextAreaElement | null;

const Textarea = React.forwardRef<RefType, PropsType>(
  (
    {
      label,
      name,
      setValue,
      validateInput,
      required = false,
      error,
      errorMsg,
      placeholder,
      value,
      rows,
      cols,
      border,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={styles.main}>
        <div className={styles.labelCon}>
          {label && <label htmlFor={name}>{label}</label>}
          {required && <span>*</span>}
        </div>
        <textarea
          data-border={border}
          className={styles.input}
          //   value={value}
          name={name}
          disabled={disabled}
          id={name}
          placeholder={placeholder}
          //   onBlur={e => setValue(e.target.value.trim())}
          //   onChange={e => {
          //     setValue(e.target.value)
          //     validateInput && validateInput(e.target.value)
          //   }}
          cols={cols}
          rows={rows}
          {...rest}
          ref={ref}
        />
      </div>
    );
  }
);
export default Textarea;
