import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import moment from "moment";
import config from "../../config";

import Button from "../../components/Button/Button";
import "./PaymentModal.scss";

import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import Tag from "../../components/Tag/Tag";
import httpMethods from "../../utils/http";
import toasts from "../../utils/toast";
// import testStatus from "../../utils/testStatus";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

interface ISubs {
  id: number;
  period: number;
  amount: number;
  discount: number;
}

interface ZPlans {
  id: number;
  title: string;
  amount: number;
  remaining_seats: number;
}

interface TPlans {
  id: number;
  title: string;
  amount: number;
  testSeriesId: number;
}

const serverURL = config.baseURI;
let subscriptionApi = config.endpoints.api.subscription;
let paymentApi = config.endpoints.api.payment;

let liveGroupApi = config.endpoints.api.livegroup;
let testSeriesApi = config.endpoints.api.testseries;

const PaymentModal = ({ selectedUser, show, handleClose, setRefresh }) => {
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const { fetchedData, fetchNewData } = useFetch();
  const {
    fetchedData: fetchedLiveGroupData,
    fetchNewData: fetchNewLiveGroupData
  } = useFetch();

  const { fetchedData: fetchedTestSeries, fetchNewData: fetchNewTestSeries } =
    useFetch();

  useEffect(() => {
    let userModule =
      selectedUser?.userModules && selectedUser?.userModules[0].userModuleId;

    userModule && fetchNewData(subscriptionApi.list(userModule));
    userModule && fetchNewLiveGroupData(liveGroupApi.list(userModule));
    userModule && fetchNewTestSeries(testSeriesApi.listData(userModule));
  }, [fetchNewData, selectedUser?.userModules, show]);

  useEffect(() => {
    setSubscriptionTypes(fetchedData.data);
    fetchedLiveGroupData.rows && setLiveGroupTypes(fetchedLiveGroupData.rows);

    fetchedTestSeries.data && setTestSeriesTypes(fetchedTestSeries.data);
  }, [fetchedData, fetchedLiveGroupData, show, fetchedTestSeries]);

  let userModule = selectedUser?.userModules && selectedUser?.userModules[0];

  const [subscriptionTypes, setSubscriptionTypes] = useState<ISubs[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<ISubs>();

  const [liveGroupTypes, setLiveGroupTypes] = useState<ZPlans[]>([]);
  const [selectedLiveGroup, setSelectedLiveGroup] = useState<ZPlans>();

  const [testSeriesTypes, setTestSeriesTypes] = useState<TPlans[]>([]);
  const [selectedTestSeries, setSelectedTestSeries] = useState<TPlans>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const confirmAdd = data => {
    setIsLoading(true);
    data.userId = selectedUser.id;
    httpMethods
      .POST(paymentApi.update(userModule.userModuleId), data)
      .then(res => {
        toasts.success("Subscription added successfully!");
        handleClear();
        handleClose();
        setRefresh(pre => !pre);
      })
      .catch(err => {
        toasts.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const confirmAddTest = data => {
    setIsLoading(true);
    data.userId = selectedUser.id;

    httpMethods
      .POST(paymentApi.test(userModule.userModuleId), data)
      .then(res => {
        toasts.success("Test Payment added successfully!");
        handleClear();
        handleClose();
        setRefresh(pre => !pre);
      })
      .catch(err => {
        toasts.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const confirmAddTestSeries = data => {
    setIsLoading(true);
    let newData = {
      amount: data.customAmount,
      testSeriesId: data.subscriptionId,
      userId: selectedUser.id,
      invoiceId: data.invoiceId
    };

    httpMethods
      .POST(paymentApi.testSeries(userModule.userModuleId), newData)
      .then(res => {
        toasts.success("Test Series payment added successfully!");
        handleClear();
        handleClose();
        setRefresh(pre => !pre);
      })
      .catch(err => {
        toasts.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const confirmAddZoom = data => {
    setIsLoading(true);
    let newData = {
      amount: data.customAmount,
      live_group_id: data.subscriptionId,
      userId: selectedUser.id,
      invoiceId: data.invoiceId
    };

    httpMethods
      .POST(paymentApi.zoomPlans(userModule.userModuleId), newData)
      .then(res => {
        toasts.success("Zoom Payment added successfully!");
        handleClear();
        handleClose();
        setRefresh(pre => !pre);
      })
      .catch(err => {
        toasts.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClear = () => {
    setSelectedSubscription(undefined);
    setSelectedLiveGroup(undefined);
    setSelectedTestSeries(undefined);
    reset({
      subscriptionId: "",
      invoiceId: "",
      customAmount: "",
      testId: "",
      zoomClass: "",
      testSeriesId: ""
    });
  };

  const panelList = [
    <SubscriptionPanel
      userModule={userModule}
      subscriptionTypes={subscriptionTypes}
      selectedSubscription={selectedSubscription}
      register={register}
      setSelectedSubscription={setSelectedSubscription}
      errors={errors}
    />,
    <TestPanel
      userModule={userModule}
      register={register}
      errors={errors}
      setValue={setValue}
    />,
    <TestSeriesPanel
      userModule={userModule}
      testSeriesTypes={testSeriesTypes}
      selectedTestSeries={selectedTestSeries}
      register={register}
      setSelectedTestSeries={setSelectedTestSeries}
      errors={errors}
    />,
    <ZoomPanel
      userModule={userModule}
      liveGroupTypes={liveGroupTypes}
      selectedLiveGroup={selectedLiveGroup}
      register={register}
      setSelectedLiveGroup={setSelectedLiveGroup}
      errors={errors}
    />
  ];

  const tabsList = ["Subscription", "Test", "Test Series", "Zoom"];

  const [activeTab, setActiveTab] = useState<{ index: number; tab: string }>({
    index: 0,
    tab: tabsList[0]
  });

  useEffect(() => {
    setActiveTab({
      index: 0,
      tab: tabsList[0]
    });
  }, [selectedUser, show, handleClose, setRefresh]);

  const handleTabSelect = (tab, index) => {
    document
      .getElementsByClassName("activeTab")[0]
      ?.classList.remove("activeTab");

    document.getElementById(`${tab}`)?.classList.add("activeTab");
    setActiveTab({ tab, index });
  };

  return (
    <>
      <Modal
        className="payment-modal-box"
        show={show}
        onHide={() => {
          handleClose();
          setActiveTab({ index: 0, tab: "Subscription" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Add Payment</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(data => {
              activeTab.index === 0
                ? confirmAdd(data)
                : activeTab.index === 1
                  ? confirmAddTest(data)
                  : activeTab.index === 2
                    ? confirmAddTestSeries(data)
                    : confirmAddZoom(data);
            })}
          >
            <div className="form_inputs">
              <div className="user_brief">
                <div className="user_img">
                  <img
                    src={`${serverURL}/${selectedUser.imageId}`}
                    alt="user"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = `fallback/user.png`;
                    }}
                  />
                </div>
                <div className="user_data">
                  <p className="name">
                    {selectedUser.name
                      ? selectedUser.name
                      : selectedUser.email?.split("@")[0]}{" "}
                  </p>
                  <p className="email">{selectedUser.email}</p>
                </div>
              </div>
              <div className="tabs">
                {tabsList.map((tab, index) => (
                  <div
                    id={`${tab}`}
                    key={index}
                    className={`tab ${!index && "activeTab"}`}
                    onClick={() => handleTabSelect(tab, index)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
              <div className="panel">{panelList[activeTab.index]}</div>
            </div>
            <div className="button_wrapper">
              <div className="btn_no">
                <Button
                  disabled={isLoading}
                  type="button"
                  color="danger"
                  buttonName={"Cancel"}
                  clickHandler={() => {
                    handleClear();
                    handleClose();
                  }}
                />
              </div>
              <div className="btn_yes">
                <Button
                  disabled={isLoading}
                  type="submit"
                  color="success"
                  buttonName={"Add Payment"}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PaymentModal;

const SubscriptionPanel = ({
  userModule,
  subscriptionTypes,
  selectedSubscription,
  register,
  setSelectedSubscription,
  errors
}) => {
  return (
    <>
      Subscription status:
      {userModule && userModule.expireDate ? (
        <div
          className="my-2 px-3 py-3 rounded"
          style={{
            backgroundColor: `${new Date(userModule?.expireDate) > new Date()
                ? "#dbfee5"
                : "#fedbdb"
              }`
          }}
        >
          <div className="d-flex justify-content-between">
            <span>
              Expiry Date:&nbsp;&nbsp;
              {moment(userModule?.expireDate).format("YYYY-MM-DD")}
            </span>
            <span>
              <strong>
                {new Date(userModule?.expireDate) > new Date()
                  ? "Active"
                  : "Expired"}
              </strong>
            </span>
          </div>
        </div>
      ) : (
        <div
          className="my-2 px-3 py-3 rounded"
          style={{
            backgroundColor: "#fedbdb"
          }}
        >
          User doesn't have any subscriptions
        </div>
      )}
      <p className="select_sub">Select a subscription to add:</p>
      {subscriptionTypes?.length > 0 ? (
        subscriptionTypes.map(type => {
          let { id, period, amount, discount } = type;
          let selected = selectedSubscription
            ? selectedSubscription.id === id
            : false;
          return (
            <div key={id} className="subscription">
              <div className="title">
                <input
                  {...register("subscriptionId", {
                    required: true
                  })}
                  type="radio"
                  id={`sub-${id}`}
                  onChange={e => {
                    setSelectedSubscription(type);
                  }}
                  value={id}
                  checked={selected}
                  name="subscriptionId"
                />
                &emsp;
                <label>
                  {period} {period > 1 ? "days" : "day"}
                  <div className="discount_tag">
                    <Tag text={`${discount}% Off`} color="green" />
                  </div>
                </label>
              </div>

              <div>
                <div>
                  {discount ? (
                    <span className="old_price">
                      Rs. {amount + amount * (discount / 100)}
                    </span>
                  ) : (
                    ""
                  )}
                  &emsp;
                  <span>{`Rs. ${amount}`}</span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no_subs">Subscription plans unavailable</div>
      )}
      <p className="total">Total (Incl. of all charges)</p>
      {errors?.subscriptionId?.type === "required" && (
        <p className="error">
          Subscription type is required. Please select one.
        </p>
      )}
      <div className="invoice">
        <p>Custom Amount:</p>
        <input
          {...register("customAmount", {})}
          type={"text"}
          placeholder={"Enter the package's custom amount if any"}
        />
      </div>
      <div className="invoice">
        <p>Invoice Id:</p>
        <input
          {...register("invoiceId", {
            required: true
          })}
          type={"text"}
          placeholder={"Enter the invoice id"}
        />
      </div>
      {errors?.invoiceId?.type === "required" && (
        <p className="error">
          Invoice id is required. Please enter invoice id.
        </p>
      )}
    </>
  );
};

const TestPanel = ({ userModule, register, errors, setValue }) => {
  // const [paid, setPaid] = useState(false);

  const [tests, setTests] = useState<any[]>([]);
  const [selectedTest, setSelectedTest] = useState<any>();

  // const [testPaymentData, setTestPaymentData] = useState<any>();

  const handleSelectChange = (e, data) => {
    setSelectedTest(e);
    setValue("testId", e.value);
  };

  useEffect(() => {
    httpMethods
      .GET(
        `${serverURL}/${userModule.userModuleId}/teacher/test/all?filter=active_scheduled`
      )
      .then(res => {
        if (res.data.tests) {
          let options = res.data.tests.map(item => {
            return {
              label: `${item.name + " " + " - " + " " + " RS " + " " + item.price
                }`,
              value: item.id
            };
          });

          options = [{ label: "Comprehensive Test 2024 - RS 750", value: "comprehensive2024" }, ...options];

          setTests(options);
        } else {
          let opt = [{ label: "Comprehensive Test 2024 - RS 750", value: "comprehensive2024" }];
          setTests(opt);
        }
      }).catch(err => {
        let opt = [{ label: "Comprehensive Test 2024 - RS 750", value: "comprehensive2024" }];
        setTests(opt);
      })
  }, [userModule]);

  // useEffect(() => {
  //   httpMethods
  //     .GET(
  //       `${serverURL}/${userModule.userModuleId}/test/hasTestSeriesAccess?userId=${userModule.user_id}`
  //     )
  //     .then(res => {
  //       if (res.data.paid) setPaid(true);
  //       if (res.data.testPayment) {
  //         let data = { ...res.data.testPayment };
  //         let currentDate = moment.utc();
  //         let startDate = moment.utc(res.data.testPayment.start);
  //         let endDate = moment.utc(res.data.testPayment.end);

  //         let { status } = testStatus(currentDate, startDate, endDate);

  //         data.status = status;
  //         setTestPaymentData(data);
  //       }
  //     });
  // }, [userModule]);

  return (
    <>
      {/* Test Series Payment status:
      {paid ? (
        <div
          className="my-2 px-3 py-3 rounded"
          style={
            testPaymentData
              ? testPaymentData.status === "Active"
                ? {
                    backgroundColor: "#dbfee5"
                  }
                : testPaymentData.status === "Scheduled"
                ? {
                    backgroundColor: "#ffb488"
                  }
                : {
                    backgroundColor: "#fedbdb"
                  }
              : {
                  backgroundColor: "#fedbdb"
                }
          }
        >
          <div className="d-flex justify-content-between">
            <span>
              {testPaymentData
                ? testPaymentData.name
                : "Payment found but no test id associated"}
            </span>
            <span>
              <strong>
                {testPaymentData ? testPaymentData.status : "Not Found"}
              </strong>
            </span>
          </div>
        </div>
      ) : (
        <div
          className="my-2 px-3 py-3 rounded"
          style={{
            backgroundColor: "#fedbdb"
          }}
        >
          User has not subscribed to any Test Series
        </div>
      )} */}
      <p className="select_sub" style={{ marginTop: "0" }}>
        Select a test:
      </p>
      <div className="my-2">
        <CustomSelect
          id="testId"
          required={true}
          name="testId"
          disabled={false}
          register={register}
          value={selectedTest}
          options={tests}
          handleChange={(e, data) => {
            handleSelectChange(e, data);
          }}
        />
      </div>
      <p className="total">Total (Incl. of all charges)</p>
      {errors?.testId?.type === "required" && (
        <p className="error">Test option is required. Please select one.</p>
      )}
      <div className="invoice">
        <p>Custom Amount:</p>
        <input
          {...register("customAmount", {})}
          type={"text"}
          placeholder={"Enter the package's custom amount if any"}
        />
      </div>
      <div className="invoice">
        <p>Invoice Id:</p>
        <input
          {...register("invoiceId", {
            required: true
          })}
          type={"text"}
          placeholder={"Enter the invoice id"}
        />
      </div>
      {errors?.invoiceId?.type === "required" && (
        <p className="error">
          Invoice id is required. Please enter invoice id.
        </p>
      )}
    </>
  );
};

const TestSeriesPanel = ({
  userModule,
  testSeriesTypes,
  selectedTestSeries,
  register,
  setSelectedTestSeries,
  errors
}) => {
  const [testSeriesPay, setTestSeriesPay] = useState<any>({});

  useEffect(() => {
    httpMethods
      .GET(
        `${serverURL}/${userModule.userModuleId}/teacher/testseries/hastestseries-access?userId=${userModule.user_id}`
      )
      .then(res => {
        if (res.data.testSeriesPayment) {
          let data = { ...res.data.testSeriesPayment };
          data.status = "Success";
          if (data.testsery) {
            data.testSeriesTitle = data.testsery.title;
          }
          setTestSeriesPay(data);
        }
      })
      .catch(err => toasts.error(err.message || "Something Went Wrong"));
  }, [userModule]);

  return (
    <>
      Test Series status:
      {userModule && testSeriesPay.id ? (
        <div
          className="my-2 px-3 py-3 rounded"
          style={{
            backgroundColor: `${testSeriesPay.status === "Success" ? "#dbfee5" : "#fedbdb"
              }`
          }}
        >
          <div className="d-flex justify-content-between">
            <span>
              Test Series Taken:&nbsp;&nbsp;
              {testSeriesPay.testSeriesTitle
                ? testSeriesPay.testSeriesTitle
                : "Title no found"}
            </span>
            <span>
              <strong>{testSeriesPay.status}</strong>
            </span>
          </div>
        </div>
      ) : (
        <div
          className="my-2 px-3 py-3 rounded"
          style={{
            backgroundColor: "#fedbdb"
          }}
        >
          User doesn't have any test series access.
        </div>
      )}
      <p className="select_sub">Select a zoom plan to add:</p>
      {testSeriesTypes?.length > 0 ? (
        testSeriesTypes.map(type => {
          let { id, title, amount, no_of_tests } = type;
          let selected = selectedTestSeries
            ? selectedTestSeries.id === id
            : false;
          return (
            <div key={id} className="subscription">
              <div className="title" style={{ width: "80%" }}>
                <input
                  {...register("subscriptionId", {
                    required: true
                  })}
                  type="radio"
                  id={`sub-${id}`}
                  onChange={e => {
                    setSelectedTestSeries(type);
                  }}
                  value={id}
                  checked={selected}
                  name="subscriptionId"
                />
                <p
                  style={{
                    textTransform: "capitalize",
                    margin: "3px 12px 0px"
                  }}
                >
                  {title}
                </p>
                <span
                  style={{ whiteSpace: "nowrap" }}
                >{`(Number of tests: ${no_of_tests})`}</span>
              </div>

              <div>
                <div>
                  <span>{`Rs. ${amount}`}</span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no_subs">Test Series unavailable</div>
      )}
      <p className="total">Total (Incl. of all charges)</p>
      {errors?.subscriptionId?.type === "required" && (
        <p className="error">Test Series is required. Please select one.</p>
      )}
      <div className="invoice">
        <p>Custom Amount:</p>
        <input
          {...register("customAmount", {})}
          type={"text"}
          placeholder={"Enter the package's custom amount if any"}
        />
      </div>
      <div className="invoice">
        <p>Invoice Id:</p>
        <input
          {...register("invoiceId", {
            required: true
          })}
          type={"text"}
          placeholder={"Enter the invoice id"}
        />
      </div>
      {errors?.invoiceId?.type === "required" && (
        <p className="error">
          Invoice id is required. Please enter invoice id.
        </p>
      )}
    </>
  );
};

const ZoomPanel = ({
  userModule,
  liveGroupTypes,
  selectedLiveGroup,
  register,
  setSelectedLiveGroup,
  errors
}) => {
  const [zoomPay, setZoomPay] = useState<any>({});

  useEffect(() => {
    httpMethods
      .GET(
        `${serverURL}/${userModule.userModuleId}/teacher/livegroup/haslivegroup-access?userId=${userModule.user_id}`
      )
      .then(res => {
        if (res.data.payment) {
          let data = { ...res.data.payment };
          if (res.data.liveGroup) {
            data.liveGroupTitle = res.data.liveGroup.title;
          }
          setZoomPay(data);
        }
      })
      .catch(err => toasts.error(err.message || "Something Went Wrong"));
  }, [userModule]);

  return (
    <>
      Zoom Plan status:
      {userModule && zoomPay.id ? (
        <div
          className="my-2 px-3 py-3 rounded"
          style={{
            backgroundColor: `${zoomPay.status === "Success" ? "#dbfee5" : "#fedbdb"
              }`
          }}
        >
          <div className="d-flex justify-content-between">
            <span>
              Live Group Taken:&nbsp;&nbsp;
              {zoomPay.liveGroupTitle
                ? zoomPay.liveGroupTitle
                : "Title no found"}
            </span>
            <span>
              <strong>{zoomPay.status}</strong>
            </span>
          </div>
        </div>
      ) : (
        <div
          className="my-2 px-3 py-3 rounded"
          style={{
            backgroundColor: "#fedbdb"
          }}
        >
          User doesn't have any zoom plan
        </div>
      )}
      <p className="select_sub">Select a zoom plan to add:</p>
      {liveGroupTypes?.length > 0 ? (
        liveGroupTypes.map(type => {
          let { id, title, amount, remaining_seats } = type;
          let selected = selectedLiveGroup
            ? selectedLiveGroup.id === id
            : false;
          return (
            <div key={id} className="subscription">
              <div className="title" style={{ width: "80%" }}>
                <input
                  {...register("subscriptionId", {
                    required: true
                  })}
                  type="radio"
                  id={`sub-${id}`}
                  onChange={e => {
                    setSelectedLiveGroup(type);
                  }}
                  value={id}
                  checked={selected}
                  name="subscriptionId"
                />
                <p
                  style={{
                    textTransform: "capitalize",
                    margin: "3px 12px 0px"
                  }}
                >
                  {title}
                </p>
                <span
                  style={{ whiteSpace: "nowrap" }}
                >{`(Remaining Seats: ${remaining_seats})`}</span>
              </div>

              <div>
                <div>
                  <span>{`Rs. ${amount}`}</span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no_subs">Live Groups unavailable</div>
      )}
      <p className="total">Total (Incl. of all charges)</p>
      {errors?.subscriptionId?.type === "required" && (
        <p className="error">Live group is required. Please select one.</p>
      )}
      <div className="invoice">
        <p>Custom Amount:</p>
        <input
          {...register("customAmount", {})}
          type={"text"}
          placeholder={"Enter the package's custom amount if any"}
        />
      </div>
      <div className="invoice">
        <p>Invoice Id:</p>
        <input
          {...register("invoiceId", {
            required: true
          })}
          type={"text"}
          placeholder={"Enter the invoice id"}
        />
      </div>
      {errors?.invoiceId?.type === "required" && (
        <p className="error">
          Invoice id is required. Please enter invoice id.
        </p>
      )}
    </>
  );
};
