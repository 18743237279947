import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
import ImageInput from "../../../../components/ImageInput/ImageInput";
import InputField from "../../../../components/InputField/InputField";

import "./InstructorForm.scss";
import "react-toastify/dist/ReactToastify.css";
import useFetch from "../../../../hooks/useFetch";

import config from "../../../../config";
import http from "../../../../utils/http";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

let subjectApi = config.endpoints.api.subject;

type optionType = {
  value: string | number;
  label: string;
};

type UnitType = {
  description: string;
  id: number;
  name: string;
};

type PropsType = {
  data?: UnitType | any;
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const InstructorForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const [image, setImage] = useState(
    editform
      ? data.image_id && `https://backend.avyaas.com/${data.image_id}`
      : ""
  );

  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        first_name: data && data.first_name,
        middle_name: data && data.middle_name,
        last_name: data && data.last_name,
        subject_id: data && data.subject_id,
        image_id: data && data.image_id,
        gender: data && data.gender,
        email: data && data.email,
        phone: data && data.phone,
        module_id: data && data.module_id
      }
    }
  );

  let courseApi = config.endpoints.api.course;

  const watchCourse = watch("module_id");

  const { fetchedData, fetchNewData } = useFetch();

  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<any[]>([]);

  const [selectedCourse, setSelectedCourse] = useState(
    editform && data.module_id
  );

  const [selectedSubject, setSelectedSubject] = useState(
    editform && data.subject_id
  );

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  const convertToSubjectOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.subject_id,
        label: item.subject_name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("course", options[0].value);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
        }
      } else {
        setCourseOptions([]);
        setValue("course", undefined);
      }
    }
    fetchCourses();
  }, [courseApi, data, editform, setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(subjectApi.list(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData.data.length) {
      let options = convertToSubjectOptions(fetchedData.data);
      setSubjectOptions(options);

      if (editform) {
        let subject = options.find(item => {
          if (item.value === data?.subject_id) return item;
          return null;
        });
        setSelectedSubject(subject);
      }
    } else {
      setSubjectOptions([]);
    }
  }, [fetchedData, editform, data?.subject_id]);

  const handleReset = () => {
    reset({
      first_name: "",
      middle_name: "",
      last_name: "",
      image_id: "",
      module_id: null,
      subject_id: null,
      gender: "",
      email: "",
      phone: ""
    });
  };

  const handleClear = () => {
    handleReset();
    setSelectedCourse(null);
    setSelectedSubject(null);
    setGender(null);
    setImage("");
  };

  const [genderOptions, setGenderOptions] = useState<optionType[]>([]);
  const [gender, setGender] = useState<optionType | null>();

  const handleGenderChange = (data, e) => {
    setGender(data);
    setValue("gender", data.value);
  };

  useEffect(() => {
    const genderValues = [
      {
        value: "M",
        label: "Male"
      },
      {
        value: "F",
        label: "Female"
      }
    ];
    setGenderOptions(genderValues);

    if (editform) {
      let gender = genderValues.find(item => {
        if (item.value === data.gender) return item;
        return null;
      });
      setGender(gender);
      setValue("gender", gender?.value);
    }
  }, [data, editform, setValue]);

  return (
    <form
      className="instructor-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="unit_image">
              <ImageInput
                image={image}
                editform={editform}
                setImage={setImage}
                {...register("image_id", {
                  required: false
                })}
              />
            </div>

            <div className="col-container">
              <div className="row-container">
                <div className="fieldAndValidate">
                  <label>
                    Course <span>*</span>
                  </label>
                  <CustomSelect
                    id="module_id"
                    required={true}
                    register={register}
                    name="module_id"
                    placeholder="Select Course"
                    value={selectedCourse || ""}
                    handleChange={data => {
                      setSelectedCourse(data);
                      setValue("module_id", data.value);
                      setSelectedSubject(undefined);
                      setValue("subject_id", undefined);
                    }}
                    options={courseOptions}
                    disabled={false}
                  />
                  {errors?.module_id?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate">
                  <label>
                    Subject <span>*</span>
                  </label>
                  <CustomSelect
                    id="subject_id"
                    required={true}
                    register={register}
                    name="subject_id"
                    placeholder="Select Subject"
                    value={selectedSubject || ""}
                    handleChange={data => {
                      setSelectedSubject(data);
                      setValue("subject_id", data.value);
                    }}
                    options={subjectOptions}
                    disabled={false}
                  />
                  {errors?.subject_id?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
              <div className="row-container name">
                <div className="fieldAndValidate">
                  <InputField
                    required
                    type="text"
                    label="First Name"
                    placeholder={"Enter first name"}
                    {...register("first_name", {
                      required: true
                    })}
                  />
                  {errors?.first_name?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate">
                  <InputField
                    type="text"
                    label="Middle Name"
                    placeholder={"Enter middle name"}
                    {...register("middle_name")}
                  />
                </div>
                <div className="fieldAndValidate">
                  <InputField
                    required
                    type="text"
                    label="Last Name"
                    placeholder={"Enter last name"}
                    {...register("last_name", {
                      required: true
                    })}
                  />
                  {errors?.last_name?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row-container name mt-3">
            <div className="fieldAndValidate gender">
              <label htmlFor="gender">
                Gender <span>*</span>
              </label>
              <CustomSelect
                id="gender"
                value={gender || ""}
                required={true}
                disabled={false}
                name="gender"
                register={register}
                options={genderOptions}
                handleChange={(e, data) => {
                  handleGenderChange(e, data);
                }}
              />
              {errors?.gender?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate">
              <InputField
                type="text"
                label="Email"
                placeholder={"Enter email"}
                {...register("email", {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
                })}
              />
              {errors?.email?.type === "pattern" && <p>Email format invalid</p>}
            </div>
            <div className="fieldAndValidate">
              <InputField
                type="number"
                label="Phone"
                placeholder={"Enter phone number"}
                {...register("phone", {
                  pattern: /^[+-/(/) 0-9]{0,20}$/
                })}
              />
              {errors?.phone?.type === "pattern" && <p>Invalid Phone Number</p>}
            </div>
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            clickHandler={() => {}}
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading
                ? "Submitting"
                : editform
                ? "Update Instructor"
                : "Add Instructor"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default InstructorForm;
