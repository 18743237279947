import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import "./LiveForm.scss";
import "react-toastify/dist/ReactToastify.css";
import {
  getDateTimeAfterYears,
  getDateTimeNow
} from "../../../../utils/getDateTimeNow";
import useFetch from "../../../../hooks/useFetch";
import config from "../../../../config";

const secretApis = config.endpoints.api.zoomSecrets;

type PropsType = {
  data?: {
    id: number;
    title: string;
    module_id: string;
    meet_type: number;
    meet_pass: string;
    start_time: string;
    recuur_end_time: string;
    duration: number;
    live_group_id: number;
    zoom_secret: number;
  };
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
  module_id?: string;
};

const LiveForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading,
  module_id
}: PropsType) => {
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data?.id,
        title: data && data?.title,
        module_id: data && data?.module_id,
        meet_type: data && data?.meet_type,
        meet_pass: data && data?.meet_pass,
        start_time: data && data?.start_time,
        recuur_end_time: data && data?.recuur_end_time,
        duration: data && data?.duration,
        live_group_id: data && data?.live_group_id,
        zoom_secret: data && data?.zoom_secret
      }
    }
  );

  const { fetchedData, fetchNewData } = useFetch();
  const [zoomSecretsOptions, setZoomSecretsOptions] = useState<any>();
  const [selectedZoomSecret, setSelectedZoomSecret] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.id,
        label: item.email
      };
    });
    return options;
  };

  useEffect(() => {
    fetchNewData(secretApis.list());
  }, [module_id]);

  useEffect(() => {
    if (fetchedData.rows && fetchedData.rows.length) {
      let options = convertToOptions(fetchedData.rows);
      setZoomSecretsOptions(options);

      if (editform) {
        let secret = options.find(item => {
          if (item.value === Number(data?.zoom_secret)) return item;
          return null;
        });
        setSelectedZoomSecret(secret);
      }
    } else {
      setZoomSecretsOptions([]);
    }
  }, [fetchedData]);

  const handleReset = () => {
    setValue("meet_type", undefined);
    setValue("meet_pass", undefined);
    setValue("duration", undefined);
    setValue("zoom_secret", undefined);
    reset({
      title: "",
      module_id: "",
      start_time: "",
      recuur_end_time: ""
    });
  };

  const handleClear = () => {
    handleReset();
  };

  const [selectedMeetType, setSelectedMeetType] = useState(
    editform && {
      value: data.meet_type,
      label:
        data.meet_type === 2
          ? "Scheduled Meeting"
          : data.meet_type === 8
          ? "Recurring Meeting"
          : ""
    }
  );

  const meetingTypeOptions = [
    {
      value: 2,
      label: "Scheduled Meeting"
    },
    {
      value: 8,
      label: "Recurring Meeting"
    }
  ];

  useEffect(() => {
    if (editform) {
      setValue(
        "start_time",
        moment(data.start_time).format("YYYY-MM-DD HH:mm")
      );
      setValue(
        "recuur_end_time",
        moment(data.recuur_end_time).format("YYYY-MM-DD HH:mm")
      );
    }
  }, [module_id, data, editform]);

  return (
    <form
      className="live-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Meeting Type <span>*</span>
              </label>
              <CustomSelect
                id="meet_type"
                required={true}
                register={register}
                name="meet_type"
                value={selectedMeetType || ""}
                handleChange={e => {
                  setValue("meet_type", e.value);
                  setSelectedMeetType(e);
                }}
                options={meetingTypeOptions}
                disabled={editform ? true : false}
              />
              {errors?.meet_type?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>

            <div className="fieldAndValidate">
              <label htmlFor="course">
                Zoom Email <span>*</span>
              </label>
              <CustomSelect
                id="zoom_secret"
                required={true}
                register={register}
                name="zoom_secret"
                value={selectedZoomSecret || ""}
                handleChange={e => {
                  setValue("zoom_secret", e.value);
                  setSelectedZoomSecret(e);
                }}
                options={zoomSecretsOptions}
                disabled={editform ? true : false}
              />
              {errors?.zoom_secret?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate title">
              <InputField
                required
                type="text"
                label="Live Title"
                placeholder={"Enter the live title"}
                {...register("title", {
                  required: true
                })}
              />
              {errors?.title?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate title">
              <InputField
                required
                type="number"
                label="Duration (In Minutes)"
                placeholder={"Enter the duration of the meeting"}
                {...register("duration", {
                  required: true
                })}
              />
              {errors?.duration?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate date">
              <InputField
                disabled={editform ? true : false}
                required
                label="Start Time"
                type="datetime-local"
                {...register("start_time", {
                  required: true
                })}
                max={getDateTimeAfterYears(1)}
                min={getDateTimeNow(0)}
              />
              {errors?.start_time?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            {selectedMeetType && selectedMeetType?.value === 8 ? (
              <div className="row-container">
                <div className="fieldAndValidate date">
                  <InputField
                    disabled={editform ? true : false}
                    required
                    label="End Time"
                    type="datetime-local"
                    {...register("recuur_end_time", {
                      required: true
                    })}
                    max={getDateTimeAfterYears(1)}
                    min={getDateTimeNow(0)}
                  />
                  {errors?.recuur_end_time?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading ? "Submitting" : editform ? "Update Live" : "Add Live"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default LiveForm;
