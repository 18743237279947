import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import useFetch from "../../../../hooks/useFetch";
import config from "../../../../config";

import Button from "../../../../components/Button/Button";
// import Textarea from "../../../../components/Textarea/Textarea";
import InputField from "../../../../components/InputField/InputField";
import ImageInput from "../../../../components/ImageInput/ImageInput";
import RichTextarea from "../../../../components/Textarea/RichTextarea";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import "./CourseForm.scss";
import "react-toastify/dist/ReactToastify.css";

type optionType = {
  value: string | number;
  label: string;
};

type PropsType = {
  data?: {
    id: number;
    name: string;
    module_id: string;
    courseGroupId: number;
    description: string;
    src: null | string;
    available: number;
  };
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const CourseForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const [image, setImage] = useState<string | null>(
    editform
      ? data.src &&
          `${process.env.REACT_APP_IMAGE_BASE_URL}/courses/${data.src}`
      : ""
  );

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data?.id,
        name: data && data?.name,
        module_id: data && data?.module_id,
        courseGroupId: data && data.courseGroupId,
        src: data && data?.src,
        available: data && data.available,
        description: data && data?.description
      }
    }
  );

  const handleReset = () => {
    reset({
      name: "",
      module_id: "",
      src: "",
      description: ""
    });
  };

  const handleClear = () => {
    setImage("");
    handleReset();
    setSelectedGroup(undefined);
  };

  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();

  const { fetchedData, fetchNewData } = useFetch();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    });
    return options;
  };

  useEffect(() => {
    let courseGroupApi = config.endpoints.api.courseGroup;
    fetchNewData(courseGroupApi.list);
  }, [fetchNewData]);

  useEffect(() => {
    if (fetchedData.data.length) {
      let options = convertToOptions(fetchedData.data);
      setGroupOptions(options);

      if (editform) {
        let group = options.find(item => {
          if (item.value === data?.courseGroupId) return item;
          return null;
        });
        setSelectedGroup(group);
      }
    } else {
      setGroupOptions([]);
    }
  }, [fetchedData, editform, data]);

  const handleSelectChange = (data, e) => {
    setSelectedGroup(data);
    setValue("courseGroupId", data.value);
  };

  const [availabilityOptions, setAvailabilityOptions] = useState<optionType[]>(
    []
  );
  const [available, setAvailable] = useState<optionType | null>();

  const handleAvailabilityChange = (data, e) => {
    setAvailable(data);
    setValue("available", data.value);
  };

  useEffect(() => {
    const availabilityValues = [
      {
        value: 0,
        label: "Unavailable"
      },
      {
        value: 1,
        label: "Available"
      }
    ];

    setValue("available", 0);
    setAvailabilityOptions(availabilityValues);

    if (editform) {
      let available = availabilityValues.find(item => {
        let availabilityValues = data.available ? 1 : 0;
        if (item.value === availabilityValues) return item;
        return null;
      });
      setAvailable(available);
      setValue("available", available?.value);
    }
  }, [setValue, data, editform]);

  return (
    <form
      className="course-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="course_image">
              <ImageInput
                image={image}
                editform={editform}
                setImage={setImage}
                {...register("src", {
                  required: false
                })}
              />
            </div>
            <div className="col-container">
              <div className="row-container">
                <div className="fieldAndValidate group">
                  <label id="groupLabel" htmlFor="course">
                    Category <span>*</span>
                  </label>
                  <CustomSelect
                    id="courseGroupId"
                    required={true}
                    register={register}
                    name="courseGroupId"
                    value={selectedGroup || ""}
                    handleChange={(e, data) => {
                      handleSelectChange(e, data);
                    }}
                    options={groupOptions}
                    disabled={false}
                  />
                  {errors?.courseGroupId?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate course-id">
                  <InputField
                    required
                    type="text"
                    label="Course Id"
                    placeholder={"Enter the module id"}
                    {...register("module_id", {
                      required: true
                    })}
                  />
                  {errors?.module_id?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
              <div className="row-container">
                <div className="fieldAndValidate course-name">
                  <InputField
                    required
                    type="text"
                    label="Course Name"
                    placeholder={"Enter the course name"}
                    {...register("name", {
                      required: true
                    })}
                  />
                  {errors?.name?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate available">
                  <label htmlFor="available">
                    Is Available <span>*</span>
                  </label>
                  <CustomSelect
                    id="available"
                    value={available}
                    required={true}
                    disabled={false}
                    name="available"
                    register={register}
                    options={availabilityOptions}
                    handleChange={(e, data) => {
                      handleAvailabilityChange(e, data);
                    }}
                  />
                  {errors?.available?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="fieldAndValidate">
            {/* <Textarea
              rows={5}
              label="Description"
              placeholder="Enter course description"
              {...register("description")}
            /> */}
            <RichTextarea
              required
              control={control}
              name="description"
              label="Description"
              editform={editform}
              placeholder={"Enter the course description"}
            />
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            clickHandler={() => {}}
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading
                ? "Submitting"
                : editform
                ? "Update Course"
                : "Add Course"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default CourseForm;
