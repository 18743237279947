import uploadImage from "./uploadImage";
import uploadAudio from "./uploadAudio";
import config from "../config";

let { quizImagesPath, quizAudiosPath } = {
  quizImagesPath: `${config.baseURI}/images/testQuestions`,
  quizAudiosPath: `${config.baseURI}/audio/testQuestions`
};

function addImageTag(image) {
  return `<br><img src='${quizImagesPath}/${image}.png' />`;
}

function addAudioTag(audio) {
  return `<br> <audio controls controlsList="nodownload noplaybackrate"> <source src='${quizAudiosPath}/${audio}.mp3' type="audio/mpeg"> Your browser does not support the audio element. </audio>`;
}

let conditions = (main, index, dir, testId, name) => {
  let mainImage = `test${testId}_QN${index + 1}_${name}`;
  let mainAudio = `test${testId}_QN${index + 1}_${name}`;
  if (main.image) {
    if (typeof main.image !== typeof "string")
      uploadImage(main.image, mainImage, dir);
    main.text += addImageTag(mainImage);
    mainImage = `${quizImagesPath}/${mainImage}.png`;
  } else {
    mainImage = null;
  }

  if (main.audio) {
    if (typeof main.audio !== typeof "string")
      uploadAudio(main.audio, mainAudio, dir);
    main.text += addAudioTag(mainAudio);
    mainAudio = `${quizAudiosPath}/${mainAudio}.mp3`;
  } else {
    mainAudio = null;
  }

  // let responseText = main.text;
  // if (main.image && main.image instanceof File) {
  //   let mainImage = `test${testId}_QN${index + 1}_${name}`;
  //   uploadImage(main.image, mainImage, dir);
  //   responseText += addImageTag(mainImage);
  // }
  // if (main.audio && main.audio instanceof File) {
  //   let mainAudio = `test${testId}_QN${index + 1}_${name}`;
  //   uploadAudio(main.audio, mainAudio, dir);
  //   responseText += addAudioTag(mainAudio);
  // }
  return { text: main.text, image: mainImage, audio: mainAudio };
};

const exports = {
  conditions
};

export default exports;
