const config = {
  basename: process.env.REACT_APP_BASE_NAME,
  baseURI: process.env.REACT_APP_API_BASE_URI,
  imageURL: process.env.REACT_APP_IMAGE_BASE_URL,
  fileUploadURL: process.env.REACT_APP_BASE_UPLOAD_URL,
  teacherURL: process.env.REACT_APP_TEACHER_BASE_URL,

  endpoints: {
    auth: {
      login: "api/v1/auth/login",
      verify: "api/v1/auth/verify",
      refresh: "auth/v1/token/refresh"
    },
    api: {
      staff: {
        list: "api/v1/staff/list",
        create: "api/v1/staff/create",
        update: "api/v1/staff/update",
        delete: "api/v1/staff/delete"
      },
      user: {
        list: (module, offset, limit, searchValue) =>
          `api/v1/${module}/teacher/student?offset=${offset}&limit=${limit}&search=${searchValue}`,
        create: module => `api/v1/${module}/teacher/student/create`,
        update: module => `api/v1/${module}/teacher/student/update`,
        delete: module => `api/v1/${module}/teacher/student/delete`,
        removesubs: module => `api/v1/${module}/teacher/student/remove-subs`
      },
      instructor: {
        list: module => `api/v1/${module}/teacher/teacher`,
        create: module => `api/v1/${module}/teacher/teacher/register`,
        update: module => `api/v1/${module}/teacher/teacher/update`,
        delete: module => `api/v1/${module}/teacher/teacher`
      },
      role: {
        list: "api/v1/role/list"
      },
      course: {
        list: "api/v1/course/list",
        create: "api/v1/course/create",
        update: "api/v1/course/update",
        delete: "api/v1/course/delete",
        updatePosition: "api/v1/course/update-order"
      },
      courseGroup: {
        list: "api/v1/course-group/list",
        create: "api/v1/course-group/create",
        update: "api/v1/course-group/update",
        delete: "api/v1/course-group/delete",
        updatePosition: "api/v1/course-group/update-order"
      },
      subject: {
        list: module => `api/v1/${module}/subject/list`,
        create: module => `api/v1/${module}/subject/create`,
        update: module => `api/v1/${module}/subject/update`,
        delete: module => `api/v1/${module}/subject/delete`,
        updatePosition: module => `api/v1/${module}/subject/update-order`
      },
      unit: {
        list: (module, subjectId) =>
          `api/v1/${module}/unit/list?subjectId=${subjectId || ""}`,
        create: module => `api/v1/${module}/unit/create`,
        update: module => `api/v1/${module}/unit/update`,
        delete: module => `api/v1/${module}/unit/delete`,
        updatePosition: module => `api/v1/${module}/unit/update-order`
      },
      chapter: {
        list: (module, subjectId, unitId) =>
          `api/v1/${module}/chapter/list?subjectId=${subjectId || ""}&unitId=${
            unitId || ""
          }`,
        create: module => {
          return `api/v1/${module}/chapter/create`;
        },
        update: module => `api/v1/${module}/chapter/update`,
        delete: module => `api/v1/${module}/chapter/delete`,
        updatePosition: module => `api/v1/${module}/chapter/update-order`
      },
      videoContent: {
        list: module => `api/v1/${module}/teacher/video`,
        create: module => `api/v1/${module}/teacher/video/addVideo`,
        update: module => `api/v1/${module}/teacher/video/update`,
        delete: module => `api/v1/${module}/teacher/video`,
        updatePosition: module => `api/v1/${module}/teacher/video/update-order`
      },
      recordingContent: {
        list: (module, live_id) =>
          `api/v1/${module}/recording/category?liveId=${live_id}`,
        create: module => `api/v1/${module}/teacher/recording/addRecording`,
        update: module => `api/v1/${module}/teacher/recording/update`,
        delete: module => `api/v1/${module}/teacher/recording`
      },
      pdfContent: {
        list: module => `api/v1/${module}/teacher/file`,
        create: module => `api/v1/${module}/teacher/file/addfile`,
        update: module => `api/v1/${module}/teacher/file/update`,
        delete: module => `api/v1/${module}/teacher/file/delete`
      },
      subscription: {
        list: module => `api/v1/${module}/subscription/list`,
        create: module => `api/v1/${module}/subscription/create`,
        update: module => `api/v1/${module}/subscription/update`,
        delete: module => `api/v1/${module}/subscription/delete`,
        updatePosition: module => `api/v1/${module}/subscription/update-order`
      },
      referral: {
        list: module => `api/v1/${module}/referral/list`,
        create: module => `api/v1/${module}/referral/create`,
        update: module => `api/v1/${module}/referral/update`,
        delete: module => `api/v1/${module}/referral/delete`
      },
      livegroup: {
        list: module => `api/v1/${module}/teacher/livegroup`,
        listData: module => `api/v1/${module}/teacher/livegroup/list`,
        create: module => `api/v1/${module}/teacher/livegroup/add`,
        update: module => `api/v1/${module}/teacher/livegroup/update`,
        delete: module => `api/v1/${module}/teacher/livegroup`
      },
      testseries: {
        list: module => `api/v1/${module}/teacher/testseries`,
        listSeries: module => `api/v1/${module}/teacher/testseries/series`,
        listData: module => `api/v1/${module}/teacher/testseries/list`,
        create: module => `api/v1/${module}/teacher/testseries/add`,
        update: module => `api/v1/${module}/teacher/testseries/update`,
        delete: module => `api/v1/${module}/teacher/testseries`
      },
      liveuser: {
        list: (module, id, searchValue, offset, limit) =>
          `api/v1/${module}/teacher/liveusers/list?liveGroup=${id}&search=${searchValue}&offset=${offset}&limit=${limit}`,
        delete: module => `api/v1/${module}/teacher/liveusers`
      },
      live: {
        list: (module, liveGroupId) =>
          `api/v1/${module}/teacher/live?liveGroupId=${liveGroupId}`,
        create: module => `api/v1/${module}/teacher/live/add`,
        update: module => `api/v1/${module}/teacher/live/update`,
        delete: module => `api/v1/${module}/teacher/live`,
        detail: (module, id) =>
          `api/v1/${module}/zoom/meetingdetail?meet_id=${id}`,
        updateTime: module => `api/v1/${module}/teacher/live/update/starttime`
      },
      notification: {
        list: `api/v1/teacher/pushNotification/list`,
        create: "api/v1/teacher/pushNotification/",
        delete: id => `api/v1/teacher/pushNotification/${id}`,
        upload: newName => `api/v1/teacher/pushNotification/upload/${newName}`
      },
      feedback: {
        list: module => `api/v1/${module}/feedback`
      },
      payment: {
        update: module => `api/v1/${module}/teacher/payment/update`,
        test: module => `api/v1/${module}/teacher/payment/test`,
        testSeries: module => `api/v1/${module}/teacher/payment/test-series`,
        zoomPlans: module => `api/v1/${module}/teacher/payment/zoom-plans`,
        logs: module => `api/v1/${module}/payment/subs-logs`,
        testSerieslogs: module => `api/v1/${module}/payment/test-series-logs`,
        zoomPlanlogs: module =>
          `api/v1/${module}/zoom-plan-payment/zoom-plan-logs`
      },
      zoomSecrets: {
        list: () => `live/zoom/zoom_secret`
      },
      giveAccessWithFile: {
        create: module => `api/v1/${module}/teacher/give-access-with-file`,
        checkUser: module => `api/v1/${module}/teacher/give-access-with-file/find-users`,
        createLive: module =>
          `api/v1/${module}/teacher/give-access-with-file/live`,
        createLiveWithEmail: module =>
          `api/v1/${module}/teacher/give-access-with-file/live/email`
      },
      video: {
        list: `/api/v2/video/all`
      },
      pdf: {
        list: `api/v2/file/all`
      },
      recording: {
        list: `/api/v2/recording/all`
      }
    }
  }
};

export default config;
