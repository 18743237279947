import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Button from "../../components/Button/Button";

import http from "../../utils/http";
import toast from "../../utils/toast";

import { useEffect } from "react";
import ContentForm from "./components/contentForm/ContentForm";
import uploadImage from "../../utils/uploadImage";

import "react-tabs/style/react-tabs.css";
import "./ContentTab.scss";

import config from "../../config";
let contentApi = config.endpoints.api.content;

function ContentTab() {
  const handleClickSubmit = data => {
    let image = data.thumbnail;
    data.thumbnail = "";

    http
      .POST(contentApi.create(data.module_id), data)
      .then(async res => {
        if (image?.length) {
          let id = res.data.videoRes.id;
          await uploadImage(image[0], id, "videos");
          data.thumbnail = `${id}.png`;
          await http.POST(`${contentApi.update(data.module_id)}/${id}/`, data);
        }
        toast.success("Content Added Successfully!");
        setTimeout(() => {
          navigate("../");
        }, 1000);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    !state && navigate("../");
  });

  return (
    <div className="content_tab_page">
      <div className="content_tab_header">
        <h4>{`Add Content to ${state && state.activeChapter.chapter_name}`}</h4>
        <div className="buttonWrapper">
          <div className="addCourseButton">
            <Button
              buttonName="< Back"
              color="success"
              clickHandler={() => navigate("../")}
            />
          </div>
        </div>
      </div>
      <Tabs className="content-tabs">
        <TabList>
          <Tab>Video</Tab>
          <Tab>PDF</Tab>
        </TabList>

        <TabPanel>
          <div className="panel">
            <ContentForm
              id={state && state.activeChapter.id}
              contentMetadata={state.activeChapter}
              contentType={"Video"}
              handleClickSubmit={handleClickSubmit}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel">
            <ContentForm
              id={state && state.activeChapter.id}
              contentMetadata={state.activeChapter}
              contentType={"Pdf"}
              handleClickSubmit={handleClickSubmit}
            />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default ContentTab;
