import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
// import Textarea from "../../../../components/Textarea/Textarea";
import ImageInput from "../../../../components/ImageInput/ImageInput";
import RichTextarea from "../../../../components/Textarea/RichTextarea";

import "./CourseGroupForm.scss";
import "react-toastify/dist/ReactToastify.css";

type PropsType = {
  data?: {
    id: number;
    name: string;
    groupId: string;
    description: string;
    thumbnail: null | string;
  };
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const CourseForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const [image, setImage] = useState<string | null>(
    editform ? (data ? data.thumbnail : "") : ""
  );

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data?.id,
        name: data && data?.name,
        groupId: data && data?.groupId,
        thumbnail: data && data?.thumbnail,
        description: data && data?.description
      }
    }
  );

  const handleReset = () => {
    reset({
      name: "",
      groupId: "",
      thumbnail: "",
      description: ""
    });
  };

  const handleClear = () => {
    setImage("");
    handleReset();
  };

  return (
    <form
      className="coursegroup-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="course_image">
          <ImageInput
            image={image}
            editform={editform}
            setImage={setImage}
            {...register("thumbnail", {
              required: false
            })}
          />
        </div>
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate group-id">
              <InputField
                required
                type="text"
                label="Category Id"
                placeholder={"Enter the category id"}
                {...register("groupId", {
                  required: true
                })}
              />
              {errors?.groupId?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate course-name">
              <InputField
                required
                type="text"
                label="Category Name"
                placeholder={"Enter the category name"}
                {...register("name", {
                  required: true
                })}
              />
              {errors?.name?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>

          <div className="fieldAndValidate">
            {/* <Textarea
              rows={5}
              label="Description"
              placeholder="Enter something about category"
              {...register("description")}
            /> */}
            <RichTextarea
              required
              control={control}
              name="description"
              label="Description"
              editform={editform}
              placeholder={"Enter the category description"}
            />
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            clickHandler={() => {}}
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading ? "Submitting" : editform ? "Update Group" : "Add Group"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default CourseForm;
