import React, { useEffect, useState } from "react";
import httpMethods from "../../../utils/http";
import config from "../../../config";
import { Table, Divider } from "antd";
import type { ColumnsType } from "antd/es/table";

interface DataType {
  key: React.Key;
  username: string;
  date: string;
  amount: number;
  moduleType: string;
}

interface Props {
  recentPaymentsAmount: {
    username: string;
    date: string;
    amount: number;
    gateway: string;
    moduleType: string;
  }[];
}

const columns: ColumnsType<DataType> = [
  {
    title: "Username",
    dataIndex: "username"
  },
  {
    title: "Module",
    dataIndex: "moduleType"
  },

  {
    title: "Gateway",
    dataIndex: "gateway"
  },
  {
    title: "Amount",
    dataIndex: "amount"
  }
];

const AntdTable = () => {
  const [dataVal, setDataVal] = useState<Props>({ recentPaymentsAmount: [] });

  const apiUrl = `${config.baseURI}/api/v1/count/paymentCount`;

  useEffect(() => {
    httpMethods.GET(apiUrl).then(response => {
      let resData = response.data.data;
      setDataVal(resData);
    });
  }, []);

  const data: DataType[] = dataVal.recentPaymentsAmount.map((item, index) => ({
    key: index,
    username: item?.username,
    date: item?.date,
    amount: item?.amount,
    gateway: item?.gateway,
    moduleType: item?.moduleType
  }));

  return (
    <>
      <Divider>Recent Payments</Divider>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
      />
    </>
  );
};

export default AntdTable;
