import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";
import toast from "../../utils/toast";

import Button from "../../components/Button/Button";
import Tables from "../../components/Tables/Tables";
import Search from "../../components/Search/Search";
import InstructorForm from "./components/instructorForm/InstructorForm";

import { PATH } from "../../constants/routes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";

import useFetch from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import uploadImage from "../../utils/uploadImage";

import "./Instructor.scss";

import config from "../../config";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
let courseApi = config.endpoints.api.course;
let instructorApi = config.endpoints.api.instructor;

type InstructorType = {
  id: number;
  phone: number;
  email: string;
  first_name: string;
  middle_name: string;
  last_name: string;
};

function Instructor() {
  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const [instructors, setInstructors] = useState<InstructorType[]>([]);
  const selectedElementRef = useRef<null | HTMLDivElement>(null);

  const [reload, setReload] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  const {
    loading: courseLoading,
    error: courseError,
    fetchedData: fetchedCourse,
    fetchNewData: fetchNewCourse
  } = useFetch();

  const [courseOptions, setCourseOptions] = useState<any[]>([]);

  const [selectedCourse, setSelectedCourse] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
  }, [fetchedCourse, setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(instructorApi.list(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData.data) {
      setInstructors(fetchedData.data);
    }
  }, [reload, fetchedData]);

  const columns = [
    {
      Header: "Instructor Name",
      Cell: tableProps => {
        let original = tableProps.row.original;

        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              {original.image_id ? (
                <img
                  src={`${config.teacherURL}/${original.image_id}`}
                  alt={`${original.first_name}`}
                />
              ) : (
                <FontAwesomeIcon icon={faChalkboardTeacher} />
              )}
            </div>
            {`${original.first_name} ${original.middle_name} ${original.last_name}`}
          </div>
        );
      }
    },
    {
      Header: "Phone",
      accessor: row => row.phone
    },
    {
      Header: "Email",
      accessor: row => row.email
    },
    {
      Header: "Subject",
      accessor: row => row.subject_name
    },
    {
      Header: "Course",
      accessor: row => row.module_id?.toUpperCase()
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${instructorApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Instructor deleted successfully");
        if (selectedElementRef.current) {
          let selectedElement = selectedElementRef?.current;

          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setInstructors(instructors => {
              return instructors.filter(instructor =>
                instructor.id !== id ? instructor : null
              );
            });
            fetchNewData(instructorApi.list(module_id));
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the unit"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsLoading(true);

    let image = data.image_id;

    if (typeof data.image_id !== "string") {
      data.image_id = `images/nameteacher/profile/${data.id}.png`;
    }

    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${instructorApi.update(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          if (typeof image !== "string" && image?.length) {
            await uploadImage(image[0], data.id, "nameteacher/profile");
          }
          toggleModal();
          fetchNewData(instructorApi.list(data.module_id));
          setReload(pre => !pre);
          toast.success("Instructor updated successfully");
        } else {
          toast.error(new Error("Error in updating instructor"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsLoading(false);
    }
    pushUpdate();
  };

  const [searchedInstructor, setSearchedInstructor] = useState<
    InstructorType[]
  >([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);

      let filteredData = instructors.filter(instructor => {
        let name =
          instructor.first_name +
          " " +
          instructor.middle_name +
          " " +
          instructor.last_name;

        return (
          instructor.email?.toLowerCase().includes(searchText.toLowerCase()) ||
          instructor.phone
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          name?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setSearchedInstructor(filteredData);
    };
  }, [instructors]);

  return (
    <div className="instructor_page">
      <div className="page_header">
        <h4 className="page_title">Instructors: {watchCourse}</h4>
        <div className="content_filter">
          <div className="course_filter">
            <CustomSelect
              id="courseFilter"
              required={true}
              register={register}
              name="courseFilter"
              placeholder="Select Course"
              value={selectedCourse}
              handleChange={data => {
                setSelectedCourse(data);
                setValue("courseFilter", data.value);
              }}
              options={courseOptions}
              disabled={false}
            />
          </div>
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link to={PATH.ADD_INSTRUCTOR}>
            <Button
              clickHandler={() => {}}
              type="button"
              buttonName="Add Instructor"
              color="success"
            />
          </Link>
        </div>
      </div>
      <div className="table_container instructor_table_container">
        <Tables
          data={searchValue ? searchedInstructor : instructors}
          hasError={error || courseError}
          columns={columns}
          isFetching={courseLoading || courseOptions?.length ? loading : false}
          isLoading={isLoading}
          formToEdit={<InstructorForm />}
          handleDelete={handleDelete}
          selectedElementRef={selectedElementRef}
          handleClickUpdate={() => handleClickUpdate}
        />
      </div>
    </div>
  );
}
export default Instructor;
