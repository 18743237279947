import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";

import Quiz from "../pages/Quiz/Quiz";
import QuizPreview from "../pages/Quiz/QuizPreview";
import AddQuizQuestion from "../pages/Quiz/components/AddQuizQuestion";
import { getAccessToken } from "../services/token";

export default function CourseRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Quiz />} />
      <Route path={PATH.ADD_QUIZ} element={<AddQuizQuestion />} />
      <Route path={PATH.PREVIEW_QUIZ} element={<QuizPreview />} />
    </Routes>
  );
}
