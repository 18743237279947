import React from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { AiOutlineClose } from "react-icons/ai";

import pdf from "../../../../assets/pdf.pdf";

import "./VideoPreviewModal.scss";

const PDFPreviewModal = ({ url, handleClose }) => {
  // const renderToolbar = Toolbar => {
  //   <Toolbar>
  //     {slots => {
  //       const {
  //         CurrentPageInput,
  //         GoToNextPage,
  //         GoToPreviousPage,
  //         NumberOfPages,
  //         // ShowSearchPopover,
  //         Zoom,
  //         ZoomIn,
  //         ZoomOut
  //       } = slots;
  //       return (
  //         <div
  //           style={{
  //             alignItems: "center",
  //             display: "flex",
  //             width: "100%"
  //           }}
  //         >
  //           {/* <div style={{ padding: "0px 2px" }}>
  //             <ShowSearchPopover />
  //           </div> */}
  //           <div style={{ padding: "0px 2px" }}>
  //             <ZoomOut />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <Zoom />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <ZoomIn />
  //           </div>
  //           <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
  //             <GoToPreviousPage />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <CurrentPageInput /> / <NumberOfPages />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <GoToNextPage />
  //           </div>
  //           <div>Hello</div>
  //         </div>
  //       );
  //     }}
  //   </Toolbar>;
  // };
  // const defaultLayoutPluginInstance = defaultLayoutPlugin({
  //   sidebarTabs: defaultTabs => [],
  //   toolbar: renderToolbar
  // });
  const zoomPluginInstance = zoomPlugin();

  return (
    <div
      className="pdf-content"
      style={{
        height: "100vh",
        width: "100%",
        padding: "2rem 0"
      }}
    >
      <div
        className="d-flex flex-row justify-content-end mb-2"
        style={{
          width: "30%"
        }}
        onClick={handleClose}
      >
        <AiOutlineClose size={21} color={"white"} />
      </div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={pdf}
          initialPage={0}
          defaultScale={1}
          plugins={[zoomPluginInstance]}
          theme="dark"
        />
      </Worker>
    </div>
  );
};

export default PDFPreviewModal;
