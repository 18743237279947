import React, { useEffect, useState } from "react";
import { Spin, Empty, Segmented } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

import config from "../../../config";
import httpMethods from "../../../utils/http";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const
    },
    title: {
      display: true,
      text: "Test Payments"
    }
  },
  interaction: { intersect: true },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      min: 0,
      ticks: {
        stepSize: 1000
      },
      grid: {
        display: false
      }
    }
  }
};

interface AmountProps {
  last12EachMonthsPaymentAmount: {
    date: string;
    total_amount: number;
  }[];
  lastMonthWeeklyPayments: {
    start_date: string;
    end_date: string;
    total_amount: number;
  }[];
  last7DaysPaymentAmount: {
    date: string;
    total_amount: number;
  }[];

  last12EachMonthsPaymentAmountEsewa: {
    date: string;
    total_amount: number;
  }[];
  lastMonthWeeklyPaymentsEsewa: {
    start_date: string;
    end_date: string;
    total_amount: number;
  }[];
  last7DaysPaymentAmountEsewa: {
    date: string;
    total_amount: number;
  }[];

  last12EachMonthsPaymentAmountKhalti: {
    date: string;
    total_amount: number;
  }[];
  lastMonthWeeklyPaymentsKhalti: {
    start_date: string;
    end_date: string;
    total_amount: number;
  }[];
  last7DaysPaymentAmountKhalti: {
    date: string;
    total_amount: number;
  }[];
}

const PaymentLineChart = () => {
  const [amount, setAmount] = useState<AmountProps>({
    last12EachMonthsPaymentAmount: [],
    lastMonthWeeklyPayments: [],
    last7DaysPaymentAmount: [],

    last12EachMonthsPaymentAmountEsewa: [],
    lastMonthWeeklyPaymentsEsewa: [],
    last7DaysPaymentAmountEsewa: [],

    last12EachMonthsPaymentAmountKhalti: [],
    lastMonthWeeklyPaymentsKhalti: [],
    last7DaysPaymentAmountKhalti: []
  });
  const apiUrl = `${config.baseURI}/api/v1/count/testPaymentCount`;

  useEffect(() => {
    httpMethods.GET(apiUrl).then(response => {
      let payAmt = response.data.data;
      setAmount(payAmt);
    });
  }, [apiUrl]);

  const [selectedDuration, setSelectedDuration] = useState<string>("");
  const handleSelectDuration = (value: any) => {
    setSelectedDuration(value);
  };

  if (!amount) {
    return <Spin tip="Loading" size="large" />;
  }

  let dailyLabels = amount?.last7DaysPaymentAmount?.map(item => {
    const parts = item.date.split("-");
    const dayMonth = `${parts[1]}/${parts[2]}`;
    return dayMonth;
  });

  let weeklyLabels = amount?.lastMonthWeeklyPayments?.map((item, i) => {
    const parts = item?.start_date?.split("-");
    const end_parts = item?.end_date?.split("-");
    let week = `${parts[1]}/${parts[2]} - ${end_parts[1]}/${end_parts[2]}`;
    return week;
  });

  let monthlyLabels = amount?.last12EachMonthsPaymentAmount?.map(
    item => item.date
  );

  let dailyPaymentAmount =
    amount?.last7DaysPaymentAmount?.length > 0
      ? amount?.last7DaysPaymentAmount?.map(item => item.total_amount)
      : "No daily payments available";

  let weeklyPaymentAmount =
    amount?.lastMonthWeeklyPayments?.length > 0
      ? amount?.lastMonthWeeklyPayments?.map(item => item.total_amount)
      : "No weekly payments available";
  let monthlyPaymentAmount =
    amount?.last12EachMonthsPaymentAmount?.length > 0
      ? amount?.last12EachMonthsPaymentAmount?.map(item => item.total_amount)
      : "No monthly payments available";

  let dailyPaymentAmountEsewa =
    amount?.last7DaysPaymentAmountEsewa?.length > 0
      ? amount?.last7DaysPaymentAmountEsewa?.map(item => item.total_amount)
      : "No weekly payments available";

  let weeklyPaymentAmountEsewa =
    amount?.lastMonthWeeklyPaymentsEsewa?.length > 0
      ? amount?.lastMonthWeeklyPaymentsEsewa?.map(item => item.total_amount)
      : "No weekly payments available";
  let monthlyPaymentAmountEsewa =
    amount?.last12EachMonthsPaymentAmountEsewa?.length > 0
      ? amount?.last12EachMonthsPaymentAmountEsewa?.map(
          item => item.total_amount
        )
      : "No monthly payments available";

  let dailyPaymentAmountKhalti =
    amount?.last7DaysPaymentAmountKhalti?.length > 0
      ? amount?.last7DaysPaymentAmountKhalti?.map(item => item.total_amount)
      : "No daily payments available";

  let weeklyPaymentAmountKhalti =
    amount?.lastMonthWeeklyPaymentsKhalti?.length > 0
      ? amount?.lastMonthWeeklyPaymentsKhalti?.map(item => item.total_amount)
      : "No weekly payments available";
  let monthlyPaymentAmountKhalti =
    amount?.last12EachMonthsPaymentAmountKhalti?.length > 0
      ? amount?.last12EachMonthsPaymentAmountKhalti?.map(
          item => item.total_amount
        )
      : "No monthly payments available";

  let labels: any, data: any;

  switch (selectedDuration) {
    case "Daily":
      labels = dailyLabels;

      data = {
        labels,
        datasets: [
          {
            label: "Total Amount",
            data: dailyPaymentAmount,
            borderColor: "#017aff",
            backgroundColor: "#017aff"
          },

          {
            label: "Esewa",
            data: dailyPaymentAmountEsewa,
            borderColor: "#60ba44",
            backgroundColor: "#60ba44"
          },

          {
            label: "Khalti",
            data: dailyPaymentAmountKhalti,
            borderColor: "#5c2c90",
            backgroundColor: "#5c2c90"
          }
        ]
      };
      break;

    case "Weekly":
      labels = weeklyLabels;

      data = {
        labels,
        datasets: [
          {
            label: "Total Amount",
            data: weeklyPaymentAmount,
            borderColor: "#017aff",
            backgroundColor: "#017aff",
            yAxisID: "y"
          },

          {
            label: "Esewa",
            data: weeklyPaymentAmountEsewa,
            borderColor: "#60ba44",
            backgroundColor: "#60ba44",
            yAxisID: "y"
          },

          {
            label: "Khalti",
            data: weeklyPaymentAmountKhalti,
            borderColor: "#5c2c90",
            backgroundColor: "#5c2c90",
            yAxisID: "y"
          }
        ]
      };
      break;

    case "Monthly":
      labels = monthlyLabels;
      data = {
        labels,
        datasets: [
          {
            label: "Total Amount",
            data: monthlyPaymentAmount,
            borderColor: "#017aff",
            backgroundColor: "#017aff"
          },

          {
            label: "Esewa",
            data: monthlyPaymentAmountEsewa,
            borderColor: "#60ba44",
            backgroundColor: "#60ba44"
          },

          {
            label: "Khalti",
            data: monthlyPaymentAmountKhalti,
            borderColor: "#5c2c90",
            backgroundColor: "#5c2c90"
          }
        ]
      };
      break;

    default:
      labels = amount?.last7DaysPaymentAmount?.map(item => {
        const parts = item.date.split("-");
        const dayMonth = `${parts[1]} ${parts[2]}`;
        return dayMonth;
      });

      data = {
        labels,
        datasets: [
          {
            label: "Total Amount",
            data: dailyPaymentAmount,
            borderColor: "#017aff",
            backgroundColor: "#017aff",
            yAxisID: "y"
          },

          {
            label: "Esewa",
            data: dailyPaymentAmountEsewa,
            borderColor: "#60ba44",
            backgroundColor: "#60ba44",
            yAxisID: "y"
          },

          {
            label: "Khalti",
            data: dailyPaymentAmountKhalti,
            borderColor: "#5c2c90",
            backgroundColor: "#5c2c90",
            yAxisID: "y"
          }
        ]
      };
      break;
  }

  return (
    <div style={{ height: "92%" }}>
      <Segmented
        options={["Daily", "Weekly", "Monthly"]}
        onChange={handleSelectDuration}
      />
      {labels?.length > 0 ? (
        <Line style={{ width: "100%" }} options={options} data={data} />
      ) : (
        <Empty description="No Records" />
      )}
    </div>
  );
};

export default PaymentLineChart;
