import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import config from "../../../config";
import http from "../../../utils/http";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  cutout: 70,
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const
    },
    title: {
      display: true,
      text: "Users Overview"
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const value = tooltipItem.yLabel;
          return `${label}: ${value}`;
        }
      }
    }
  }
};

interface ProgressProps {
  freeUsers: number;
  paidUsers: number;
  activeUsers: number;
  expiredUsers: number;
}

const DonutChart = () => {
  const [usersCount, setUsersCount] = useState<ProgressProps | null>(null);

  const apiUrl = `${config.baseURI}/api/v1/count/dataCount`;

  useEffect(() => {
    http.GET(apiUrl).then(response => {
      let users = response.data.data;
      setUsersCount(users);
    });
  }, []);

  if (!usersCount) {
    return <Spin tip="Loading" size="large" />;
  }

  const data = {
    labels: ["Free", "Paid", "Active", "Expired"],
    datasets: [
      {
        data: [
          usersCount?.freeUsers,
          usersCount?.paidUsers,
          usersCount?.activeUsers,
          usersCount?.expiredUsers
        ],
        backgroundColor: ["#fde047", `#1e3a8a`, "#22c55e", "#ef4444"],
        borderColor: ["#fde047", "#1e3a8a", "#22c55e", "#ef4444"],
        borderWidth: 1
      }
    ]
  };

  return (
    <div style={{ height: "270px", display: "flex", justifyContent: "center" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DonutChart;
