interface PATH_TYPE {
  DASHBOARD: string;
  LOGIN: string;
  STAFF: string;
  ADD_STAFF: string;
  USER: string;
  ADD_USER: string;
  INSTRUCTOR: string;
  ADD_INSTRUCTOR: string;
  COURSE_GROUP: string;
  COURSE: string;
  ADD_COURSE: string;
  ADD_COURSE_GROUP: string;
  SUBJECT: string;
  ADD_SUBJECT: string;
  CHAPTER: string;
  ADD_CHAPTER: string;
  UNIT: string;
  ADD_UNIT: string;
  PROFILE: string;
  SETTING: string;
  CONTENT: string;
  ADD_CONTENT: string;
  SUBSCRIPTION: string;
  ZOOMPLAN: string;
  ADD_ZOOMPLAN: string;
  ADD_SUBSCRIPTION: string;
  REFERRAL: string;
  ADD_REFERRAL: string;
  NOTIFICATION: string;
  ADD_NOTIFICATION: string;
  FEEDBACK: string;
  PAYMENTLOG: string;
  QUIZ: string;
  ADD_QUIZ: string;
  LIVE: string;
  ADD_LIVE: string;
  PREVIEW_QUIZ: string;
  LIVE_DETAIL: string;
  GROUP: string;
  ADD_GROUP: string;
  RECORDING: string;
  ADD_RECORDING: string;
  PREMIUM_WITH_DATA: string;
  GROUPUSERS: string;
  TESTSERIES: string;
  ADD_TESTSERIES: string;
}

export const PATH: PATH_TYPE = {
  DASHBOARD: "/",
  LOGIN: "/login",
  STAFF: "staffs",
  ADD_STAFF: "addStaff",
  USER: "users",
  ADD_USER: "addschool",
  INSTRUCTOR: "instructors",
  ADD_INSTRUCTOR: "addinstructor",
  COURSE_GROUP: "coursegroups",
  COURSE: "courses",
  ADD_COURSE: "addcourse",
  ADD_COURSE_GROUP: "addcoursegroup",
  SUBJECT: "subjects",
  ADD_SUBJECT: "addsubject",
  CHAPTER: "chapters",
  ADD_CHAPTER: "addchapter",
  UNIT: "units",
  ADD_UNIT: "addunit",
  PROFILE: "profile",
  SETTING: "setting",
  CONTENT: "contents",
  ADD_CONTENT: "addcontent",
  SUBSCRIPTION: "subscription",
  ZOOMPLAN: "zoomplan",
  ADD_SUBSCRIPTION: "addsubscription",
  ADD_ZOOMPLAN: "addzoomplan",
  REFERRAL: "referrals",
  ADD_REFERRAL: "addreferral",
  NOTIFICATION: "notifications",
  ADD_NOTIFICATION: "addnotifications",
  FEEDBACK: "feedbacks",
  PAYMENTLOG: "paymentlogs",
  QUIZ: "quizzes",
  ADD_QUIZ: "addquiz",
  PREVIEW_QUIZ: "preview",
  LIVE: "live",
  ADD_LIVE: "addlive",
  LIVE_DETAIL: "livedetail/:meet_id",
  GROUP: "group",
  GROUPUSERS: "live-users",
  ADD_GROUP: "addgroup",
  RECORDING: "recording",
  ADD_RECORDING: "recording/addrecording",
  PREMIUM_WITH_DATA: "premium-with-data",
  TESTSERIES: "testseries",
  ADD_TESTSERIES: "add-testseries"
};
