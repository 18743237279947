import React, { useState, useRef } from "react";
import { AudioOutlined } from "@ant-design/icons";

import { useEffect } from "react";
import "./TextImageInput.scss";

const TextImageInput = ({
  id,
  name,
  rows,
  cols,
  option,
  imagePreview,
  audioPreview,
  setValue,
  register,
  placeholder,
  setImageRemove,
  setAudioRemove
}) => {
  const fileInputRef = useRef(null);
  const audioInputRef = useRef(null);
  const [errCount, setErrCount] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [audioPreviewUrl, setAudioPreviewUrl] = useState("");

  useEffect(() => {
    // if (imagePreview && imagePreview != null && imagePreview != undefined) {
    //   imagePreview = parseURL(imagePreview);
    // }
    // if (audioPreview && audioPreview != null && audioPreview != undefined) {
    //   audioPreview = parseURL(audioPreview);
    // }
    const regex = /src=['"](.*?)['"]/;
    const extractImagePath =imagePreview && regex.exec(imagePreview);
    const extractAudioPath=audioPreview && regex.exec(audioPreview)
    if(extractImagePath){
      setImagePreviewUrl(extractImagePath?.[1]);
    }
    else{
      setImagePreviewUrl(imagePreview)
    }

    if(extractAudioPath){
      setAudioPreviewUrl(extractAudioPath?.[1]);
    }
    else{
      setAudioPreviewUrl(audioPreview)
    }
    
  }, [imagePreview, audioPreview]);

  // useEffect(() => {
  //   setAudioPreviewUrl(audioPreview);
  // }, [audioPreview]);

  const imageSelect = () => {
    let imageElement = document.getElementById(`quiz${id}_${name}_image`);
    if (imageElement) imageElement.click();
  };

  const audioSelect = () => {
    let audioElement = document.getElementById(`quiz${id}_${name}_audio`);
    if (audioElement) {
      audioElement.click();
    }
  };

  const handleImageChange = event => {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];

    if (file) {
      reader.onloadend = () => {
        fileInputRef.current = file;
        setValue(`${name}.image`, file);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // function parseURL(previewURL) {
  //   let actualURL = previewURL.split("src=")[1];
  //   if (actualURL?.includes("'")) {
  //     actualURL = actualURL.replaceAll("'", "");
  //   }
  //   if (actualURL?.includes(">")) {
  //     actualURL = actualURL.replace(">", "");
  //   }
  //   if (actualURL?.includes(" /")) {
  //     actualURL = actualURL.replace(" /", "");
  //   }
  //   return actualURL;
  // }

  function handleRemoveImage() {
    setImageRemove(true);
    setImagePreviewUrl(null);
    setValue(`${name}.image`, "");
    fileInputRef.current.value = null;
  }

  const addDefaultSrc = e => {
    e.target.src = imagePreviewUrl;
  };

  const handleAudioChange = async event => {
    event.preventDefault();
    const reader = new FileReader();
    const audio = event.target.files[0];

    if (audio) {
      const result = await new Promise(resolve => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(audio);
      });
      audioInputRef.current = audio;
      setValue(`${name}.audio`, audio);
      setAudioPreviewUrl(result);
    }
  };

  function handleRemoveAudio() {
    setAudioRemove(true);
    setAudioPreviewUrl(null);
    setValue(`${name}.audio`, "");
    audioInputRef.current.value = null;
  }

  const addDefaultAudioSrc = e => {
    e.target.src = audioPreviewUrl;
  };

  return (
    <div className="text_image_input">
      <div className="text_area">
        {option && (
          <div className="option_label">
            <p>{`${option} :`}</p>
          </div>
        )}
        <textarea
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          {...register(`${name}.text`)}
        />

        <div className="upload_image text-muted">
          <input
            hidden
            type="file"
            accept="image/*"
            id={`quiz${id}_${name}_image`}
            {...register(`${name}.image`)}
            onChange={handleImageChange}
            ref={fileInputRef}
          />
          <i className="bx bx-image-add" onClick={imageSelect}></i>
        </div>
        <div className="upload_audio text-muted">
          <input
            hidden
            type="file"
            accept="audio/*"
            id={`quiz${id}_${name}_audio`}
            {...register(`${name}.audio`)}
            onChange={handleAudioChange}
            ref={audioInputRef}
          />
          <AudioOutlined className="audio_input" onClick={audioSelect} />
        </div>
      </div>

      {imagePreviewUrl && (
        <div className="imagePreview">
          <div className="image">
            <img
              src={imagePreviewUrl ? imagePreviewUrl : ""}
              alt="Preview"
              onError={e => {
                if (errCount < 5) {
                  setTimeout(() => {
                    addDefaultSrc(e);
                  }, 200);
                  setErrCount(pre => pre + 1);
                }
              }}
            />
            <div className="remove">
              <i className="bx bx-x text-muted" onClick={handleRemoveImage}></i>
            </div>
          </div>
        </div>
      )}

      {audioPreviewUrl && (
        <div className="audioPreview">
          <div className="audio">
            <audio controls>
              <source
                src={audioPreviewUrl ? audioPreviewUrl : ""}
                onError={e => {
                  if (errCount < 5) {
                    setTimeout(() => {
                      addDefaultAudioSrc(e);
                    }, 200);
                    setErrCount(pre => pre + 1);
                  }
                }}
              />
            </audio>
            <div className="remove">
              <i className="bx bx-x text-muted" onClick={handleRemoveAudio}></i>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TextImageInput;
