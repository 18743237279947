import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRightFromBracket,
  faAudioDescription,
  faUpload,
  faVideo
} from "@fortawesome/free-solid-svg-icons";

import { clear, get } from "../../utils/storage";
import { Link } from "react-router-dom";
import { PATH } from "../../constants/routes";
import useHandleClickOutside from "../../hooks/useHandleClickOutside";
import {
  faUser
  // faGrinHearts,
  // faNewspaper,
} from "@fortawesome/free-regular-svg-icons";

import { useUploadContext } from "../../context/UploadContextProvider";

import "./Navbar.scss";
import config from "../../config";
import styled from "styled-components";

type UserType = {
  name: string;
  imageId: string | null;
};
const serverURL = config.baseURI;

const NavContainer = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${props => props.progress}%;
    height: 2px;
    background-color: #143f91;
    transition: width 0.5s ease-in-out;
  }
`;

const Progress = styled.div`
  width: ${props => props.progress}%;
`;

export default function Navbar() {
  const { progress, log, temp, uploadQueue, isEditing } = useUploadContext();
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  // const NavContainer = styled.div`
  //   position: relative;

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     width: ${progress}%;
  //     height: 2px;
  //     background-color: #143f91;
  //     transition: width 0.5s ease-in-out;
  //   }
  // `;

  // const Progress = styled.div`
  //   width: ${progress}%;
  // `;

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [user, setUser] = useState<UserType>();

  const wrapperRef = useRef(null);
  useHandleClickOutside(wrapperRef, {
    toggle: () => {
      setShowMenu(false);
      setUploadModal(false);
    }
  });

  useEffect(() => {
    //getting user from localstorage
    let response = get("user");
    if (response) {
      let userDetail = JSON.parse(response);
      setUser(userDetail);
    }
  }, []);

  const toggleMenu = () => {
    setShowMenu(showMenu => !showMenu);
  };

  const handleLogout = () => {
    //clearing localstorage
    clear();
    window.location.href = "/login";
    // navigate("/login");
    //TODO: navigate not working used window for now fix it
  };

  const [time, timeSet] = useState(new Date().toLocaleTimeString());
  const DateTime = () => {
    useEffect(() => {
      let id = setInterval(() => {
        timeSet(new Date().toLocaleTimeString());
      }, 1000);
      return () => {
        clearInterval(id);
      };
    });

    return (
      <div className="timeContainer">
        <i
          style={{ marginBottom: ".1em", fontSize: "1.2em" }}
          className="fal fa-calendar-alt"
        ></i>
        &nbsp;&nbsp;&nbsp;
        <span>{new Date().toDateString()}</span>
      </div>
    );
  };

  return (
    <NavContainer progress={progress} className="navbar_container">
      <DateTime />
      <div className="timeContainer">
        <i
          style={{ fontSize: "1.2em", fontWeight: "300" }}
          className="far fa-clock"
        ></i>
        &nbsp;&nbsp;&nbsp;
        <span className="time">
          {time.slice(1, 2) === ":" ? 0 : ""}
          {time.slice(0, 8)}
        </span>
        <span className="time">{time.slice(-2)}</span>
      </div>
      <div className="row-container">
        {/* upload indicator */}
        <div className="upload_container">
          <div
            className="upload_indicator"
            onClick={() => {
              setUploadModal(uploadModal => !uploadModal);
            }}
          >
            {progress > 0 ? (
              <p className="progress_number">{progress} %</p>
            ) : (
              <FontAwesomeIcon
                icon={faUpload}
                id="upload_icon"
                className="uploadCon"
              />
            )}
          </div>
          {uploadModal && (
            <div className="upload_modal">
              {temp && (
                <div className="upload_item">
                  <div className="upload_icon">
                    <FontAwesomeIcon icon={faVideo} className="icon" />
                  </div>
                  <div className="upload_content">
                    {isEditing ? (
                      <h5 className="upload_title">{temp.title} - Updating</h5>
                    ) : (
                      <h5 className="upload_title">{temp.title}</h5>
                    )}
                    <p>{temp.module_id.toUpperCase()}</p>
                    <div className="progress_bar">
                      <Progress
                        progress={progress}
                        className="progress"
                      ></Progress>
                    </div>
                    <div className="upload_status">
                      <p style={{ margin: 0 }}>{progress} %</p>
                    </div>
                  </div>
                </div>
              )}
              {uploadQueue.length > 0 &&
                uploadQueue.map((item, index) => (
                  <div className="upload_item" key={index}>
                    <div className="upload_icon">
                      <FontAwesomeIcon icon={faVideo} className="icon" />
                    </div>
                    <div className="upload_content">
                      <h5 className="upload_title">{item.title}</h5>
                      <p>{item.module_id.toUpperCase()}</p>
                      <div className="waiting">
                        <p>Waiting . In Queue</p>
                      </div>
                    </div>
                  </div>
                ))}
              {log.length > 0 &&
                log.map((item, index) => (
                  <div className="upload_item completed" key={index}>
                    <div className="upload_icon">
                      <FontAwesomeIcon
                        icon={faAudioDescription}
                        className="icon"
                      />
                    </div>
                    <div className="upload_content">
                      <h5 className="upload_title">{item.title}</h5>
                      <p>{item.module_id.toUpperCase()}</p>
                      <div className="waiting">
                        <p>Completed</p>
                      </div>
                    </div>
                  </div>
                ))}
              {temp === null &&
                uploadQueue.length === 0 &&
                log.length === 0 && (
                  <div className="empty_message">
                    <p>
                      😟<span>You have nothing in upload queue right now.</span>
                    </p>
                  </div>
                )}
            </div>
          )}
        </div>
        {/* greetings */}
        <div className="greeting_container">
          <h6>Hello, {user?.name || "User"}!</h6>
        </div>
        <div ref={wrapperRef} className="menu_container" onClick={toggleMenu}>
          <div className="navbar_pic_container">
            {user?.imageId ? (
              <img
                src={`${serverURL}/${user.imageId}`}
                alt="profile"
                onError={() => {
                  user.imageId = null;
                }}
              />
            ) : user?.name ? (
              user?.name[0].toUpperCase()
            ) : (
              "U"
            )}
          </div>
          {showMenu ? (
            <FontAwesomeIcon icon={faAngleUp} id="arrow_pic" />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} id="arrow_pic" />
          )}
          {showMenu && (
            <ul className="dropdown_menu">
              <Link to={PATH.PROFILE}>
                <FontAwesomeIcon icon={faUser} className="dropIcon" />
                <li>Profile</li>
              </Link>
              {/* <Link to={PATH.PROFILE}>
                <FontAwesomeIcon icon={faNewspaper} />
                <li>Control</li>
              </Link>
              <Link to={PATH.PROFILE}>
                <FontAwesomeIcon icon={faGrinHearts} />
                <li>Setting</li>
              </Link> */}
              <li className="dropdown_divider">
                <hr />
              </li>
              <Link to={"#"} onClick={handleLogout}>
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  className="dropIcon"
                />
                <li>Logout</li>
              </Link>
            </ul>
          )}
        </div>
      </div>
    </NavContainer>
  );
}
