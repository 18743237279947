import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import config from "../config";
import { getAccessToken } from "../services/token";

/* A constant variable that is used to store the base url of the api. */
const BaseURL: string = config.baseURI;

const http: AxiosInstance = axios.create({
  baseURL: BaseURL,
  responseType: "json"
});

type GetHeadersFunction = () => AxiosRequestHeaders;
const getHeaders: GetHeadersFunction = () => {
  let headerOptions = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`
  };
  return headerOptions;
};

function GET(url: string, params?: any) {
  return http.get(url, {
    headers: getHeaders(),
    params: params
  });
}

async function POST(url: string, data: any) {
  return http.post(url, data, {
    headers: getHeaders(),
    params: {}
  });
}

function POST_FILE(url: string, data: any) {
  return http.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getAccessToken()}`
    },
    params: {}
  });
}

function PUT(url: string, data: any) {
  return http.put(url, data, { headers: getHeaders(), params: {} });
}

function PATCH(url: string, data: any) {
  return http.patch(url, data, { headers: getHeaders(), params: {} });
}

function PUT_FILE(url: string, data: any) {
  return http.patch(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getAccessToken()}`
    },
    params: {}
  });
}

/**
 * It returns a promise that will resolve to the response of a DELETE request to the given url.
 * @param {string} url - The URL of the API endpoint you want to call.
 * @returns A function that takes a url and returns a promise.
 */
function REMOVE(url: string) {
  return http.delete(url, { headers: getHeaders() });
}

const httpMethods = {
  GET,
  POST,
  PUT,
  PATCH,
  REMOVE,
  PUT_FILE,
  POST_FILE
};

export default httpMethods;
