import React, { useState, useEffect, useMemo, useRef } from "react";

import http from "../../utils/http";

import Tables from "../../components/Tables/Tables";

import toast from "../../utils/toast";

import useFetch from "../../hooks/useFetch";

import Search from "../../components/Search/Search";
import Button from "../../components/Button/Button";

import "./Group.scss";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import toReadableDate from "../../utils/toReadableDate";

import { useNavigate } from "react-router-dom";

import config from "../../config";

let courseApi = config.endpoints.api.course;
let livegroupApi = config.endpoints.api.livegroup;
let liveUserApi = config.endpoints.api.liveuser;

function LiveUsers() {
  const selectedElementRef = useRef<null | HTMLElement>(null);

  const navigate = useNavigate();

  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const [livesGroup, setLivesGroup] = useState<any>([]);
  const [selectedLiveGroup, setSelectedLiveGroup] = useState<any>();

  const [searchValue, setSearchValue] = useState<string>("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(9);
  const [pageCount, setPageCount] = useState<number | undefined>(0);

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const { fetchedData: fetchedLiveUsers, fetchNewData: fetchNewLiveUsers } =
    useFetch();
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState();

  const [liveUsers, setLiveUsers] = useState<any[]>([]);

  const convertToOptions = (lists, isCourse) => {
    if (isCourse) {
      let options = lists.map(item => {
        return {
          value: item.module_id,
          label: item.module_id.toUpperCase()
        };
      });

      return options;
    } else {
      let options = lists.map(item => {
        return {
          value: item.id,
          label: item.title
        };
      });
      return options;
    }
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data, true);
        setCourseOptions(options);
        setSelectedCourse(options[0]);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(livegroupApi.list(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData && fetchedData.rows) {
      let options = convertToOptions(fetchedData.rows, false);

      setLivesGroup(options);
    } else {
      setLivesGroup([]);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (watchCourse) {
      if (selectedLiveGroup) {
        fetchNewLiveUsers(
          liveUserApi.list(
            watchCourse,
            selectedLiveGroup.value,
            searchValue,
            offset,
            limit
          )
        );
      } else {
        fetchNewLiveUsers(
          liveUserApi.list(watchCourse, "", searchValue, offset, limit)
        );
      }
    }
  }, [
    fetchNewLiveUsers,
    watchCourse,
    selectedLiveGroup,
    searchValue,
    offset,
    limit
  ]);

  useEffect(() => {
    if (
      fetchedLiveUsers &&
      fetchedLiveUsers.rows &&
      fetchedLiveUsers.total_pages
    ) {
      setLiveUsers(fetchedLiveUsers.rows);
      setPageCount(fetchedLiveUsers.total_pages);
    } else {
      setLiveUsers([]);
    }
  }, [fetchedLiveUsers]);

  const columns = [
    {
      Header: "Email",
      accessor: "user.email"
    },
    {
      Header: "Phone",
      accessor: "user.phone"
    },
    {
      Header: "Live Group",
      accessor: "livegroup.title"
    },
    {
      Header: "Amount",
      accessor: "livegroup.amount"
    },
    {
      Header: "Created",
      accessor: row => toReadableDate(row.created_at)
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${liveUserApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Live User deleted successfully.");
        if (selectedElementRef?.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setLiveUsers(users => {
              return users.filter(user => (user.id !== id ? user : null));
            });
            if (selectedLiveGroup) {
              fetchNewData(
                liveUserApi.list(
                  module_id,
                  selectedLiveGroup.value,
                  searchValue,
                  offset,
                  limit
                )
              );
            } else {
              fetchNewData(
                liveUserApi.list(module_id, "", searchValue, offset, limit)
              );
            }
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the Live User."));
      }
    } catch (error) {
      if (error.hasLive) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      // let filteredData = liveUsers.filter(
      //   liveUser =>
      //     (liveUser.user.email &&
      //       liveUser.user.email
      //         .toLowerCase()
      //         .includes(searchText?.toLowerCase())) ||
      //     (liveUser.user.phone && liveUser.user.phone.includes(searchText))
      // );
      // filteredData && setSearchedUser(filteredData);
    };
  }, [livesGroup]);

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Group : {watchCourse} </h4>
        <div className="course_filter">
          <CustomSelect
            id="courseFilter"
            required={true}
            register={register}
            name="courseFilter"
            placeholder="Select Course"
            value={selectedCourse}
            handleChange={data => {
              setSelectedCourse(data);
              setValue("courseFilter", data.value);
            }}
            options={courseOptions}
            disabled={false}
          />
        </div>
        <div className="course_filter" style={{ marginLeft: "10px" }}>
          <CustomSelect
            id="groupFilter"
            required={true}
            register={register}
            name="groupFilter"
            placeholder="Group"
            value={selectedLiveGroup}
            handleChange={data => {
              setSelectedLiveGroup(data);
              setValue("groupFilter", data.value);
            }}
            options={livesGroup}
            disabled={false}
          />
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>
      <div className="table_container livegroup_table_container liveusers">
        <Tables
          data={liveUsers}
          total_pages={pageCount}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          hasError={error}
          columns={columns}
          isFetching={liveUsers?.length ? loading : false}
          handleDelete={handleDelete}
          selectedElementRef={selectedElementRef}
        />
      </div>
    </>
  );
}
export default LiveUsers;
