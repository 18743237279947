import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";
import Live from "../pages/Live/Live";
import AddLive from "../pages/Live/AddLive";
import LiveDetail from "../pages/Live/LiveDetail/LiveDetail";
import AddRecording from "../pages/Live/components/Recording/AddRecording";
import Recording from "../pages/Live/components/Recording/Recording";

import { getAccessToken } from "../services/token";

export default function LiveRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Live />} />
      <Route path={PATH.ADD_LIVE} element={<AddLive />} />
      <Route path={PATH.LIVE_DETAIL} element={<LiveDetail />} />
      <Route path={PATH.RECORDING} element={<Recording />} />
      <Route path={PATH.ADD_RECORDING} element={<AddRecording />} />
    </Routes>
  );
}
