import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../config";
import http from "../../utils/http";
import toast from "../../utils/toast";
import { USER } from "../../constants/storage";
import { setTokens } from "../../services/token";
import { setUser } from "../../utils/storage";

import "./AdminLogin.scss";
import avyaasLogo from "../../assets/images/name_online.png";

export default function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState(false);

  let navigate = useNavigate();

  let handleChange = e => {
    if (e.target.id === "username") {
      setUsername(e.target.value);
    } else if (e.target.id === "password") {
      setPassword(e.target.value);
    }
  };

  let handleSubmit = e => {
    e.preventDefault();

    async function loginAdmin() {
      try {
        const response = await http.POST(config.endpoints.auth.login, {
          phone: username,
          auth: "lettheadminenter!"
        });
        if (response.status === 200) {
          if (
            response.data.otpreceivedby === process.env.REACT_APP_AUTH ||
            response.data.otpreceivedby === process.env.REACT_APP_AUTH_TEACHER
          ) {
            setVerifying(true);
            setVerifyMessage(`Enter below the ${response.data.message}`);
            toast.success(response.data.message);
          } else {
            toast.error("User associated with the phone unauthorized");
          }
        } else {
          toast.error(new Error("response from admin not success"));
        }
      } catch (error) {
        toast.error(error);
      }
    }

    async function verifyLogin() {
      try {
        const response = await http.POST(config.endpoints.auth.verify, {
          user: username,
          token: password
        });
        if (response.status === 200) {
          setVerifying(false);
          toast.success(response.data.message);

          let accessToken = response.data.token;
          // let refreshToken = response.data.data.refresh;
          setTokens({ accessToken });

          setUser(USER, response.data.user);

          // Array.prototype.unique = function () {
          //   var a = this.concat();
          //   for (var i = 0; i < a.length; ++i) {
          //     for (var j = i + 1; j < a.length; ++j) {
          //       if (a[i] === a[j]) a.splice(j--, 1);
          //     }
          //   }
          //   return a;
          // };

          // let permissions = [];
          // response.data.data.user.roles.map(each => {
          //   permissions = permissions.concat(permission(each.name)).unique();
          //   return 1;
          // });

          // set("userAccess", permissions);
          // toast.success("Success login");
          navigate("/", { replace: true });
        } else {
          toast.error(new Error("response from admin not success"));
        }
      } catch (error) {
        toast.error(error);
      }
    }
    verifying ? verifyLogin() : loginAdmin();
  };
  return (
    <div className="login_page">
      <div className="login_container ">
        <div className="image_container ">
          <img src={avyaasLogo} alt="name-online" />
        </div>
        <div className="detail_container ">
          <div className="title_container "></div>
          <h3 className="greet">Welcome to Name Online!!</h3>
          <p>Please sign in to continue to your account.</p>
          <hr style={{ width: "100%" }} />
          <form onSubmit={handleSubmit}>
            <div className="login_detail_container">
              <div className="username_container ">
                <label htmlFor="username">Phone No. :</label>
                <input
                  value={username}
                  onChange={handleChange}
                  type="text"
                  id="username"
                  placeholder="Please enter your phone number"
                />
              </div>
              {verifying && (
                <>
                  <p>{verifyMessage}</p>
                  <div className="password_container ">
                    <label htmlFor="password">Password (OTP) :</label>
                    <input
                      value={password}
                      onChange={handleChange}
                      type="text"
                      id="password"
                    />
                  </div>
                </>
              )}
            </div>
            {/* <div className="keep_login_container">
              <input type="checkbox" id="keep_login_checkbox" />
              <label htmlFor="keep_login_checkbox">Keep me logged in</label>
            </div> */}
            <br />
            <hr />
            <div className="button_container">
              {/* <div className="recover_password_button">Recover Password</div> */}
              <button
                className=" button button_secondary button_submit"
                type="submit"
              >
                {verifying ? "Verify Login" : "Login"}
              </button>
            </div>
          </form>
        </div>
        {/* <div className="terms_container">
        2022 All right Reserved . Ayata Inc. Cookie Preferences, secondary and
        Terms.
      </div> */}
      </div>
    </div>
  );
}
