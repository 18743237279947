import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import http from "../../utils/http";
import toast from "../../utils/toast";

import Button from "../../components/Button/Button";
import CourseGroupForm from "./components/courseGroupForm/CourseGroupForm";

import "./AddCourseGroup.scss";

import config from "../../config";
let courseGroupApi = config.endpoints.api.courseGroup;

const AddCourseGroup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = data => {
    setIsLoading(true);

    data.groupId = data.groupId.toUpperCase();

    if (!data?.thumbnail?.length) {
      delete data.thumbnail;
    } else {
      data.thumbnail = data?.thumbnail[0];
    }

    http
      .POST(courseGroupApi.create, data)
      .then(res => {
        toast.success("Category added successfully!");
        navigate("../");
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Categories | Add Category </h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_course_form">
        <CourseGroupForm
          editform={false}
          handleClickUpdate={() => {}}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AddCourseGroup;
