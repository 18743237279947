import React from "react";

import "./VideoPreviewModal.scss";

const VideoPreviewModal = ({ url }) => {
  return (
    <div className="video-content">
      {url.includes("https://www.youtube") ? (
        <iframe
          width="960"
          height="540"
          src={`https://www.youtube.com/embed/${
            url.split("v=")[1].split("&")[0]
          }`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      ) : (
        <video
          src={`https://nameavyaas.obs.ap-southeast-3.myhuaweicloud.com/uploads/${url}`}
          controls
          // autoPlay
          className="video-content__video"
        />
      )}
    </div>
  );
};

export default VideoPreviewModal;
