import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import http from "../../utils/http";
import toast from "../../utils/toast";
import useFetch from "../../hooks/useFetch";
import { PATH } from "../../constants/routes";

import Button from "../../components/Button/Button";
import ContentForm from "./components/contentForm/ContentForm";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import ChapterForm from "../Chapter/components/chapterForm/ChapterForm";
import MasterContentCard from "./components/masterContentCard/MasterContentCard";
import MasterDetailsCard from "./components/masterDetailsCard/MasterDetailsCard";

import "./Content.scss";

import config from "../../config";
import { useUploadContext } from "../../context/UploadContextProvider";
import VideoPreviewModal from "./components/contentForm/VideoPreviewModal";
import PDFPreviewModal from "./components/contentForm/PDFPreviewModal";

let courseApi = config.endpoints.api.course;
let subjectApi = config.endpoints.api.subject;
let unitApi = config.endpoints.api.unit;
let chapterApi = config.endpoints.api.chapter;
let contentApi = config.endpoints.api.videoContent;
let pdfContentApi = config.endpoints.api.pdfContent;

function Content() {
  const { watch, register, setValue } = useForm({});
  const { setIsEditing, uploadFile, refresh } = useUploadContext();
  const watchCourse = watch("courseFilter");
  const watchSubject = watch("subjectFilter");
  const watchUnit = watch("unitFilter");

  const [isLoading, setIsLoading] = useState(false);
  const [contents, setContents] = useState([]);
  const [activeChapter, setActiveChapter] = useState("");
  const [chapters, setChapters] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { fetchedData, fetchNewData } = useFetch();

  const [unitOptions, setUnitOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);

  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedSubject, setSelectedSubject] = useState();

  const [isPreview, setIsPreview] = useState(false);
  const [pdfView, setPdfView] = useState(false);

  const convertToSubjectOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.subject_id,
        label: item.subject_name
      };
    });
    return options;
  };

  const convertToUnitOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.unit_id,
        label: item.unit_name
      };
    });
    return options;
  };

  const convertToCourseOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToCourseOptions(courses?.data?.data);
        setCourseOptions(options);
        setValue("courseFilter", options?.[0]?.value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  useEffect(() => {
    async function fetchSubjects() {
      let subjects = await http.GET(subjectApi.list(watchCourse));

      if (subjects?.data?.data?.length) {
        let options = convertToSubjectOptions(subjects.data.data);
        setSubjectOptions(options);
        setValue("subjectFilter", options[0].subject_id);
      } else {
        setUnitOptions([]);
        setSubjectOptions([]);
      }
    }
    watchCourse && fetchSubjects();
  }, [watchCourse, setValue]);

  useEffect(() => {
    async function fetchUnits() {
      let units = await http.GET(unitApi.list(watchCourse, watchSubject));

      if (units?.data?.data?.length) {
        let options = convertToUnitOptions(units.data.data);
        setUnitOptions(options);
      } else {
        setUnitOptions([]);
        setSelectedUnit(undefined);
        setValue("unitFilter", undefined);
      }
    }
    watchSubject && fetchUnits();
  }, [watchCourse, watchSubject, setValue]);

  useEffect(() => {
    watchCourse &&
      fetchNewData(chapterApi.list(watchCourse, watchSubject, watchUnit));
  }, [fetchNewData, watchCourse, watchSubject, watchUnit, refresh]);

  useEffect(() => {
    if (fetchedData?.data?.length) {
      setChapters(fetchedData.data);
      setActiveChapter(fetchedData?.data[0]);
    } else {
      setChapters([]);
      setContents([]);
    }

    fetchedData?.data[0] &&
      getData(watchCourse, fetchedData?.data[0]?.chapters_id);
  }, [fetchedData, watchCourse]);

  async function getData(moduleId, chapterId) {
    try {
      const detailResponse = await http.GET(
        `${contentApi.list(moduleId)}/${chapterId}`
      );
      const pdfContentResponse = await http.GET(
        `${pdfContentApi.list(moduleId)}/${chapterId}`
      );

      if (
        pdfContentResponse?.data?.file?.length ||
        detailResponse?.data?.video?.length
      ) {
        const newVideoData = detailResponse.data.video.map(item => ({
          ...item,
          content_type: "Video"
        }));
        const newpdfData = pdfContentResponse.data.file.map(item => ({
          ...item,
          content_type: "Pdf"
        }));

        setContents([...newVideoData, ...newpdfData]);
      } else setContents([]);
    } catch (err) {
      toast.error(err);
    }
  }

  const handleSelect = (chapter_id, activeTitle, activeChapter) => {
    setContents([]);
    setActiveChapter(activeChapter);

    getData(activeChapter.module_id, activeChapter.chapters_id);
    setIsLoading(false);
  };

  const [showEditMasterModal, setShowEditMasterModal] = useState(false);
  const toggleEditMasterModal = () =>
    setShowEditMasterModal(!showEditMasterModal);

  const [showEditContentModal, setShowEditContentModal] = useState(false);
  const toggleEditContentModal = () =>
    setShowEditContentModal(!showEditContentModal);

  const [deleteId, setDeleteId] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [deleteTitle, setDeleteTitle] = useState("");
  const [contentType, setContentType] = useState("");

  const [deleteModuleId, setDeleteModuleId] = useState("");
  const [deleteContentId, setDeleteContentId] = useState("");
  const [updateContentData, setUpdateContentData] = useState({});
  const [deleteContentTitle, setDeleteContentTitle] = useState("");
  const [deleteContentModule, setDeleteContentModule] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);

  const toggleModal = () => {
    setShowDeleteModal(pre => !pre);
  };

  const toggleDeleteContentModal = () => {
    setShowDeleteContentModal(pre => !pre);
  };

  const setDeleteData = (id, title, module_id) => {
    id && setDeleteId(id);
    title && setDeleteTitle(title);
    module_id && setDeleteModuleId(module_id);
  };

  const setDeleteContentData = (id, title, module_id, contentType) => {
    id && setDeleteContentId(id);
    title && setDeleteContentTitle(title);
    module_id && setDeleteContentModule(module_id);
    contentType && setContentType(contentType);
  };

  const handleDeleteContent = async (id, module_id, contentType) => {
    try {
      const url = contentType === "Video" ? contentApi : pdfContentApi;
      const response = await http.REMOVE(`${url.delete(module_id)}/${id}`);
      if (response.status === 200) {
        toast.success("Content deleted successfully");
        setContents(prevContents => {
          return prevContents.filter(content => {
            return content.id !== id ? content : null;
          });
        });
      } else {
        toast.error(new Error("Error in deleting the content"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${chapterApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Chapter deleted successfully");
        setChapters(prevChapters => {
          return prevChapters.filter(chapter =>
            chapter.id !== id ? chapter : null
          );
        });
      } else {
        toast.error(new Error("Error in deleting the chapter"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const handleVisibility = id => {
  //   try {
  //     http.POST(`${masterApi.toggleVisibility}/${id}/`);
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  const handleClickUpdate = toggleModal => data => {
    setIsSubmitting(true);
    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${chapterApi.update(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          fetchNewData(chapterApi.list(data.module_id));
          toast.success("Chapter updated successfully");
        } else {
          toast.error(new Error("Error in updating chapter"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsSubmitting(false);
    }
    pushUpdate();
  };

  const handleClickUpdateContent = data => {
    async function pushUpdate() {
      try {
        setIsEditing(true);
        await uploadFile(data, updateContentData.content_type, true);
        await fetchNewData(
          chapterApi.list(
            data.module_id,
            watchSubject || null,
            watchUnit || null
          )
        );
        toggleEditContentModal();
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  const handlePositionUpdate = async orderedChapters => {
    await http
      .POST(chapterApi.updatePosition(selectedCourse?.value), {
        data: orderedChapters?.map(item => {
          return item?.id;
        })
      })
      .then(() => {
        toast.success("Content Position Updated Successfully");
        fetchNewData(chapterApi.list);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const onDragEnd = async result => {
    if (!result.destination) {
      return;
    }
    const orderedChapters = await reorder(
      chapters,
      result.source.index,
      result.destination.index
    );

    setChapters(orderedChapters);
    handlePositionUpdate(orderedChapters);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 8px 0`,
    width: "100%",
    height: "100px",
    background: isDragging ? "lightblue" : "transparent",
    border: isDragging ? "2px solid #a1c5e8" : "",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <div className="content_page">
      <div className="content_header">
        <div className="content_add">
          <h4>Chapters: {watchCourse}</h4>
          <div className="buttonWrapper">
            <div className="addCourseButton">
              <Link to={PATH.ADD_CHAPTER}>
                <Button
                  type="button"
                  clickHandler={() => {}}
                  buttonName="Add Chapter"
                  color="success"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="content_filter">
          <div className="course_filter">
            <CustomSelect
              id="courseFilter"
              required={true}
              register={register}
              name="courseFilter"
              placeholder="Select Course"
              value={selectedCourse || ""}
              handleChange={data => {
                setSelectedUnit(undefined);
                setValue("unitFilter", undefined);
                setSelectedSubject(undefined);
                setValue("subjectFilter", undefined);
                setSelectedCourse(data);
                setValue("courseFilter", data.value);
              }}
              options={courseOptions}
              disabled={false}
            />
          </div>

          <div className="subject_filter">
            <CustomSelect
              id="subjectFilter"
              required={true}
              register={register}
              name="subjectFilter"
              placeholder="Select Subject"
              value={selectedSubject || ""}
              handleChange={data => {
                setSelectedUnit(undefined);
                setValue("unitFilter", undefined);
                setSelectedSubject(data);
                setValue("subjectFilter", data.value);
              }}
              options={subjectOptions}
              disabled={false}
            />
          </div>

          <div className="unit_filter">
            <CustomSelect
              id="unitFilter"
              required={true}
              register={register}
              name="unitFilter"
              placeholder="Select Unit"
              value={selectedUnit || ""}
              handleChange={data => {
                setSelectedUnit(data);
                setValue("unitFilter", data.value);
              }}
              options={unitOptions}
              disabled={false}
            />
          </div>
        </div>
      </div>

      <div className="contents">
        <div className="content_list">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  // className={`draggableList ${!disableDrag && "ordering"}`}
                  className={`draggableList`}
                >
                  {chapters &&
                    chapters?.map((each, i) => (
                      <Draggable
                        key={String(each.id)}
                        draggableId={String(each.id)}
                        index={i}
                        // isDragDisabled={disableDrag}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <MasterContentCard
                              data={each}
                              pos={i}
                              key={each.chapters_id}
                              toggleModal={toggleModal}
                              setDeleteData={setDeleteData}
                              setUpdateData={setUpdateData}
                              activeChapterId={activeChapter.chapters_id}
                              handleSelect={(
                                chapters_id,
                                activeTitle,
                                activeChapter
                              ) =>
                                handleSelect(
                                  chapters_id,
                                  activeTitle,
                                  activeChapter
                                )
                              }
                              toggleEditMasterModal={toggleEditMasterModal}
                              // handleVisibility={handleVisibility}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div className="content_detail">
          <MasterDetailsCard
            contents={contents}
            isLoading={isLoading}
            activeChapter={activeChapter}
            setUpdateContentData={setUpdateContentData}
            setDeleteContentData={setDeleteContentData}
            handleDelete={(id, module_id) => handleDeleteContent(id, module_id)}
            toggleEditContentModal={toggleEditContentModal}
            toggleDeleteContentModal={toggleDeleteContentModal}
          />
        </div>
      </div>

      {/* Modal to delete master content */}
      <DeleteModal
        id={deleteId}
        name={deleteTitle}
        module_id={deleteModuleId}
        show={showDeleteModal}
        handleClose={toggleModal}
        handleDelete={handleDelete}
      />

      {/* Modal to delete content */}
      <DeleteModal
        id={deleteContentId}
        name={deleteContentTitle}
        module_id={deleteContentModule}
        show={showDeleteContentModal}
        contentType={contentType}
        handleClose={toggleDeleteContentModal}
        handleDelete={handleDeleteContent}
      />

      {/* Modal to edit master content */}
      <Modal
        centered
        onHide={toggleEditMasterModal}
        show={showEditMasterModal}
        dialogClassName={"modal_container"}
      >
        <Modal.Header className="modal_title" closeButton>
          <Modal.Title>Edit Master Content</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal_body">
          <ChapterForm
            editform
            data={updateData}
            handleCancel={toggleEditMasterModal}
            handleClickUpdate={() => handleClickUpdate}
            isSubmitting={isSubmitting}
          />
        </Modal.Body>
      </Modal>

      {isPreview && (
        <Modal centered onHide={setIsPreview} show={isPreview}>
          <Modal.Body className="video-preview-body">
            <VideoPreviewModal url={updateContentData.link} />
          </Modal.Body>
        </Modal>
      )}
      {pdfView && (
        <Modal centered onHide={setPdfView} show={pdfView}>
          <Modal.Body>
            <PDFPreviewModal
              handleClose={() => {
                setPdfView(false);
              }}
            />
          </Modal.Body>
        </Modal>
      )}

      {/* Modal to edit content */}
      <Modal
        centered
        onHide={toggleEditContentModal}
        show={showEditContentModal}
        dialogClassName={"modal_container"}
      >
        <Modal.Header className="modal_title" closeButton>
          <Modal.Title>Edit Content</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal_body">
          <div
            style={{
              height: "80vh",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            <ContentForm
              editform
              data={updateContentData}
              // contentType={updateContentData.contentType}
              contentType={updateContentData.content_type || "video"}
              handleCancel={toggleEditContentModal}
              handleClickUpdate={handleClickUpdateContent}
              isSubmitting={isSubmitting}
              isPreview={isPreview || pdfView}
              setIsPreview={setIsPreview}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Content;
