import React from "react";
import { useController } from "react-hook-form";
import { DefaultEditor } from "react-simple-wysiwyg";
import "./RichTextarea.scss";

const RichTextArea = ({
  control,
  name,
  label,
  required,
  placeholder,
  editform
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ""
  });

  return (
    <div className={`richEditor ${!!editform && "edit"}`}>
      <div className="label">
        {label && <label htmlFor={name}>{label}</label>}
        {required && <span>*</span>}
      </div>

      <DefaultEditor
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        // placeholder={placeholder}
      />
      {error && <span>{error.message}</span>}
    </div>
  );
};

export default RichTextArea;
