import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useForm } from "react-hook-form";

import http from "../../utils/http";

import Button from "../../components/Button/Button";
import Tables from "../../components/Tables/Tables";
import NotificationForm from "./components/notificationForm/NotificationForm";

import toast from "../../utils/toast";
import { PATH } from "../../constants/routes";
import toReadableDate from "../../utils/toReadableDate";

import useFetch from "../../hooks/useFetch";

import "./Notification.scss";
import addNotification from "./sendNotification";
import Search from "../../components/Search/Search";

import config from "../../config";
let notificationApi = config.endpoints.api.notification;

function Notification() {
  const selectedElementRef = useRef<null | HTMLElement>(null);
  const navigate = useNavigate();

  const [notifications, setNotification] = useState([]);
  const [reload, setReload] = useState(false);

  const [isFormUpdating, setIsFormUpdating] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  useEffect(() => {
    fetchNewData(notificationApi.list);
  }, [fetchNewData, reload]);

  useEffect(() => {
    if (fetchedData.data.length) {
      setNotification(fetchedData.data);
    } else {
      setNotification([]);
    }
  }, [fetchedData]);

  const columns = [
    {
      Header: "Type",
      Cell: tableProps => {
        let original = tableProps.row.original;
        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              {original.notifType === "Notification" ? (
                <i className="bx bxs-bell"></i>
              ) : (
                <i className="bx bxs-megaphone"></i>
              )}
            </div>
            {original.notifType}
          </div>
        );
      }
    },
    {
      Header: "Title & Details",
      accessor: row => {
        return (
          <div className="notifDetails">
            <h6>{row.title}</h6>
            <p>{row.description}</p>
          </div>
        );
      }
    },
    {
      Header: "Recipient",
      accessor: "recipient"
    },
    {
      Header: "Date Posted",
      accessor: row => toReadableDate(row.notifDate)
    }
  ];

  const handleDelete = async id => {
    try {
      const response = await http.REMOVE(notificationApi.delete(id));
      if (response.status === 200) {
        toast.success("Notification deleted successfully");
        if (selectedElementRef?.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setNotification(Notifications => {
              return Notifications.filter(notification =>
                notification.id !== id ? notification : null
              );
            });
            fetchNewData(notificationApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the plan"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    addNotification(data, navigate, setIsFormUpdating, toggleModal, setReload);
  };

  const [searchedNotification, setSearchedNotification] = useState([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);

      let filteredData = notifications.filter(notification => {
        let date = toReadableDate(notification.notifDate);

        return (
          notification.notifType
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          notification.title.toLowerCase().includes(searchText.toLowerCase()) ||
          notification.description
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          notification.recipient
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          date.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setSearchedNotification(filteredData);
    };
  }, [notifications]);

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Notification & Announcements</h4>

        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link to={PATH.ADD_NOTIFICATION}>
            <Button
              buttonName="Add Notification"
              color="success"
              type="button"
              clickHandler={() => {}}
            />
          </Link>
        </div>
      </div>
      <div className="table_container notification_table_container">
        <Tables
          data={searchValue ? searchedNotification : notifications}
          notificationForm
          hasError={error}
          columns={columns}
          isFetching={loading}
          isLoading={isFormUpdating}
          formToEdit={<NotificationForm />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        />
      </div>
    </>
  );
}
export default Notification;
