import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";
import Subscription from "../pages/Subscription/Subscription";
import AddSubscription from "../pages/Subscription/AddSubscription";

import { getAccessToken } from "../services/token";

export default function SubscriptionRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Subscription />} />
      <Route path={PATH.ADD_SUBSCRIPTION} element={<AddSubscription />} />
    </Routes>
  );
}
