import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import http from "../../utils/http";
import toast from "../../utils/toast";
import uploadImage from "../../utils/uploadImage";

import Button from "../../components/Button/Button";
import CourseForm from "./components/courseForm/CourseForm";

import "./AddCourse.scss";

import config from "../../config";
let courseApi = config.endpoints.api.course;

const AddCourse = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = data => {
    setIsLoading(true);
    let image = data.src;
    data.src = "";
    // data.module_id = data.module_id.toUpperCase();

    http
      .POST(courseApi.create, data)
      .then(async res => {
        if (image?.length) {
          let id = res.data.courseRes.id;
          await uploadImage(image[0], id, "courses");
          data.src = `${id}.png`;
          await http.POST(`${courseApi.update}/${id}/`, data);
        }
        toast.success("Course Added Successfully!");
        navigate("../");
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Courses | Add Course</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_course_form">
        <CourseForm
          editform={false}
          handleClickUpdate={() => {}}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AddCourse;
