import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";

import { getAccessToken } from "../services/token";
import Group from "../pages/Group/Group";
import AddGroup from "../pages/Group/AddGroup";
import LiveUsers from "../pages/Group/LiveUsers";

export default function GroupRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Group />} />
      <Route path={PATH.ADD_GROUP} element={<AddGroup />} />
      <Route path={PATH.GROUPUSERS} element={<LiveUsers />} />
    </Routes>
  );
}
