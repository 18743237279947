import http from "./http";
import config from "../config";

const uploadImage = async (file, name, dir) => {
  const data = new FormData();

  data.append("file", file);
  data.append("name", name);
  data.append("dir", dir);

  http
    .POST_FILE(`${config.baseURI}/uploadImage`, data)
    .then(async res => {
      return Promise.resolve("success", res);
    })
    .catch(err => {
      return Promise.reject("error", err);
    });
};

export default uploadImage;
