function getDateTimeNow(years = 0, months = 0, days = 0) {
  var now = new Date();
  // var utcString = now.toISOString().substring(0, 19);
  var year = now.getFullYear() + years;
  var month = now.getMonth() + 1 + months;
  var day = now.getDate() + days;
  var hour = now.getHours();
  var minute = now.getMinutes();
  var localDatetime =
    year +
    "-" +
    (month < 10 ? "0" + month.toString() : month) +
    "-" +
    (day < 10 ? "0" + day.toString() : day) +
    "T" +
    (hour < 10 ? "0" + hour.toString() : hour) +
    ":" +
    (minute < 10 ? "0" + minute.toString() : minute);
  // +
  // utcString.substring(16, 19);

  return localDatetime;
}

function getDateTimeAfterYears(years) {
  return getDateTimeNow(years, 0, 0);
}

export { getDateTimeNow, getDateTimeAfterYears };
