import React from "react";
import { Icon } from "@iconify/react";
import { Col, Row, Statistic } from "antd";

type Props = {
  feedbackCount: number;
  subscriptionCount: number;
};

const AntdStatistics = ({ feedbackCount, subscriptionCount }: Props) => (
  <div>
    <Row gutter={16}>
      <Col span={12}>
        <Statistic
          title="Feedback"
          value={feedbackCount}
          prefix={
            <Icon
              icon="codicon:feedback"
              color="green"
              width="32"
              height="32"
            />
          }
        />
      </Col>
      <Col span={12} style={{ paddingLeft: "70px" }}>
        <Statistic
          title="Subscription"
          value={subscriptionCount}
          prefix={
            <Icon
              icon="eos-icons:subscriptions-created"
              color="blue"
              width="32"
              height="32"
            />
          }
        />
      </Col>
    </Row>
  </div>
);

export default AntdStatistics;
