import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";
import toast from "../../utils/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faEdit,
  faBookOpen
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import Search from "../../components/Search/Search";
import UnitForm from "./components/unitForm/UnitForm";

import { PATH } from "../../constants/routes";

import useFetch from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import uploadImage from "../../utils/uploadImage";

import "./Unit.scss";

import config from "../../config";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import DragDropTable from "../../components/Tables/DragDropTable";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
let unitApi = config.endpoints.api.unit;
let courseApi = config.endpoints.api.course;

let subjectApi = config.endpoints.api.subject;

type UnitType = {
  id: number;
  unit_name: string;
  description: string;
  subject_name: string;
  module_id: string;
};

function Unit() {
  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");
  const watchSubject = watch("subjectFilter");
  const [disableDrag, setDisableDrag] = useState(true);

  const [units, setUnits] = useState<UnitType[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<UnitType>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const selectedElementRef = useRef<null | HTMLDivElement>(null);

  const [reload, setReload] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<any[]>([]);

  const [selectedCourse, setSelectedCourse] = useState<any>();
  const [selectedSubject, setSelectedSubject] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  const convertToSubjectOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.subject_id,
        label: item.subject_name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchSubjects() {
      let subjects = await http.GET(subjectApi.list(watchCourse));

      if (subjects?.data?.data?.length) {
        let options = convertToSubjectOptions(subjects.data.data);
        setSubjectOptions(options);
        setValue("subjectFilter", options[0].subject_id);
      } else {
        setSubjectOptions([]);
        setSelectedSubject(undefined);
        setValue("subjectFilter", undefined);
      }
    }
    watchCourse && fetchSubjects();
  }, [watchCourse, setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(unitApi.list(watchCourse, watchSubject));
  }, [fetchNewData, watchCourse, watchSubject]);

  useEffect(() => {
    if (fetchedData.data) {
      setUnits(fetchedData.data);
    }
  }, [reload, fetchedData]);

  const columns = [
    {
      Header: "Id",
      accessor: row => row.unit_id
    },
    {
      Header: "Unit Name",
      accessor: row => `${row.unit_name}`,
      Cell: tableProps => {
        let original = tableProps.row.original;

        return (
          <div className="profile_container">
            <div className="profile_pic_container ">
              {original.logo_id ? (
                <img
                  src={`${config.imageURL}/unit/${original.logo_id}`}
                  alt={`${original.unit_name}`}
                />
              ) : (
                <FontAwesomeIcon icon={faBookOpen} />
              )}
            </div>
            {`${original.unit_name}`}
          </div>
        );
      }
    },
    {
      Header: "Subject",
      accessor: row => row.subject_name
    },
    {
      Header: "Course",
      accessor: row => row.module_id?.toUpperCase()
    },
    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "10px"
          }}
        >
          Actions
        </div>
      ),
      accessor: "action",
      disableSortBy: true,
      Cell: tableProps => {
        const original = tableProps.row.original;
        return (
          <>
            <td
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "baseline",
                justifyItems: "flex-start"
              }}
            >
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowEditModal(true);
                  setSelectedUnit(original);
                }}
                className="edit_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faEdit}
              />
              <FontAwesomeIcon
                onClick={e => {
                  e.preventDefault();
                  setShowDeleteModal(true);
                  setSelectedUnit(original);
                }}
                className="trash_btn"
                style={{
                  marginTop: "0.2rem",
                  cursor: `${disableDrag ? "pointer" : "none"}`
                }}
                icon={faTrashCan}
              />
            </td>
          </>
        );
      }
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(`${unitApi.delete(module_id)}/${id}/`);
      if (response.status === 200) {
        toast.success("Unit deleted successfully");
        if (selectedElementRef.current) {
          let selectedElement = selectedElementRef?.current;

          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setUnits(units => {
              return units.filter(unit => (unit.id !== id ? unit : null));
            });
            fetchNewData(unitApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the unit"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsLoading(true);

    let image = data.logo_id;

    if (typeof data.logo_id !== "string") {
      data.logo_id = `${data.id}.png`;
    }

    async function pushUpdate() {
      try {
        const response = await http.POST(
          `${unitApi.update(data.module_id)}/${data.id}/`,
          data
        );
        if (response.status === 200) {
          if (typeof image !== "string" && image?.length) {
            await uploadImage(image[0], data.id, "unit");
          }
          toggleModal();
          fetchNewData(unitApi.list(data.module_id, watchSubject));
          setReload(pre => !pre);
          toast.success("Unit updated successfully");
        } else {
          toast.error(new Error("Error in updating unit"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsLoading(false);
    }
    pushUpdate();
  };

  const [searchedUnit, setSearchedUnit] = useState<UnitType[]>([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = units.filter(
        unit =>
          unit.unit_name.toLowerCase().includes(searchText.toLowerCase()) ||
          unit.subject_name.toLowerCase().includes(searchText.toLowerCase()) ||
          unit.module_id.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedUnit(filteredData);
    };
  }, [units]);

  const handlePositionUpdate = () => {
    http
      .POST(unitApi.updatePosition(selectedCourse?.value), {
        data:
          units &&
          units.map(item => {
            return item.id;
          })
      })
      .then(() => {
        toast.success("Unit Position Updated Successfully");
        fetchNewData(unitApi.list);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Units: {watchCourse}</h4>
        <div className="content_filter">
          <div className="course_filter">
            <CustomSelect
              id="courseFilter"
              required={true}
              register={register}
              name="courseFilter"
              placeholder="Select Course"
              value={selectedCourse}
              handleChange={data => {
                setSelectedCourse(data);
                setValue("courseFilter", data.value);
                setSelectedSubject(undefined);
              }}
              options={courseOptions}
              disabled={false}
            />
          </div>
          <div className="subject_filter">
            <CustomSelect
              id="subjectFilter"
              required={true}
              register={register}
              name="subjectFilter"
              placeholder="Select Subject"
              value={selectedSubject || ""}
              handleChange={data => {
                setSelectedSubject(data);
                setValue("subjectFilter", data.value);
              }}
              options={subjectOptions}
              disabled={false}
            />
          </div>
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="arrange_dragdrop">
          <div
            className="arrange_button"
            onClick={() => {
              setDisableDrag(pre => !pre);
              !disableDrag && handlePositionUpdate();
            }}
          >
            <div style={{ fontSize: "14px" }}>Arrange</div>
            {disableDrag ? (
              <i className="bx bxs-sort-alt" title="Reorder Contents"></i>
            ) : (
              <i className="bx bx-check tick" title="Save Changes"></i>
            )}
          </div>
          <div className="button_wrapper ">
            <Link to={PATH.ADD_UNIT}>
              <Button type="button" buttonName="Add Unit" color="success" />
            </Link>
          </div>
        </div>
      </div>
      <div className="table_container unit_table_container">
        <DragDropTable
          disableDrag={disableDrag}
          data={searchValue ? searchedUnit : units}
          columns={columns}
          setData={setUnits}
          hasCheckBox={true}
          selectedElementRef={selectedElementRef}
          onSelectRows={() => {}}
          isFetching={loading}
          formToEdit={<UnitForm />}
          handleDelete={handleDelete}
          isLoading={isLoading}
          hasError={error}
          handleClickUpdate={data => handleClickUpdate(data)}
        />
      </div>
      <Modal
        size="lg"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName={"modal_container"}
        centered
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>Update Unit</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modalBody">
          {React.cloneElement(
            <UnitForm handleClickUpdate={() => handleClickUpdate} />,
            {
              data: selectedUnit,
              editform: 1,
              handleCancel: () => setShowEditModal(false),
              loading
            }
          )}
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={selectedUnit?.id}
        name={selectedUnit?.unit_name}
        handleDelete={handleDelete}
      />
    </>
  );
}
export default Unit;
