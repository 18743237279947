import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import http from "../../utils/http";
import toast from "../../utils/toast";

import Button from "../../components/Button/Button";
import LiveForm from "./components/liveForm/LiveForm";

import "./AddLive.scss";

import config from "../../config";
import moment from "moment";
let liveApi = config.endpoints.api.live;

const AddLive = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const module_id = queryParams.get("module_id");
  const liveGroupId = queryParams.get("live_group_id");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!(module_id && liveGroupId)) {
      navigate("/livegroup");
    }
  }, []);

  const handleClickSubmit = data => {
    setIsLoading(true);
    data.live_group_id = liveGroupId;

    data.z_start_time = data.start_time;
    data.z_recuur_end_time = data.recuur_end_time
      ? data.recuur_end_time
      : moment.utc();

    data.start_time = moment(data.start_time).utc();
    data.recuur_end_time = data.recuur_end_time
      ? moment(data.recuur_end_time).utc()
      : moment.utc();

    http
      .POST(liveApi.create(module_id), data)
      .then(res => {
        toast.success("Live Added Successfully!");
        navigate(`/live?module_id=${module_id}&live_group_id=${liveGroupId}`);
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Live | Add Live</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() =>
              navigate(
                `/live?module_id=${module_id}&live_group_id=${liveGroupId}`
              )
            }
          />
        </div>
      </div>

      <div className="add_live_form">
        <LiveForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isLoading}
          module_id={module_id}
        />
      </div>
    </>
  );
};

export default AddLive;
