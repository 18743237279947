import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";

import config from "../../config";
import http from "../../utils/http";
import toast from "../../utils/toast";
import useFetch from "../../hooks/useFetch";
import { PATH } from "../../constants/routes";

import Search from "../../components/Search/Search";
import Button from "../../components/Button/Button";
import Tables from "../../components/Tables/Tables";
import ReferralForm from "./components/referralForm/ReferralForm";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

import "./Referral.scss";

let courseApi = config.endpoints.api.course;
let referralApi = config.endpoints.api.referral;

function Referral() {
  const selectedElementRef = useRef<null | HTMLElement>(null);

  const { watch, register, setValue } = useForm({});
  const watchCourse = watch("courseFilter");

  const [referrals, setReferral] = useState([]);

  const [isFormUpdating, setIsFormUpdating] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
    fetchCourses();
  }, [setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(referralApi.list(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData.data.length) {
      setReferral(fetchedData.data);
    } else {
      setReferral([]);
    }
  }, [fetchedData]);

  const columns = [
    {
      Header: "Referral Code",
      accessor: "code"
    },
    {
      Header: "Course",
      accessor: "course"
    },
    {
      Header: "Discount %",
      accessor: "discountPercent"
    },
    {
      Header: "Limit",
      accessor: row => (row.hasLimit ? row.limit : "No")
    },
    {
      Header: "Use Count",
      accessor: "useCount"
    }
  ];

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${referralApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Code deleted successfully");
        if (selectedElementRef?.current) {
          let selectedElement = selectedElementRef?.current;
          selectedElement.style.animationName = "fade-out";
          setTimeout(() => {
            setReferral(referrals => {
              return referrals.filter(course =>
                course.id !== id ? course : null
              );
            });
            fetchNewData(referralApi.list);
            selectedElement.style.animationName = "none";
          }, 1000);
        }
      } else {
        toast.error(new Error("Error in deleting the code"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickUpdate = toggleModal => data => {
    setIsFormUpdating(true);

    const id = data.id;
    const hasLimit = Boolean(data.hasLimit);
    data.hasLimit = hasLimit;
    data.code = data.code.toUpperCase();
    delete data.id;
    if (!hasLimit) {
      data.limit = null;
    }

    async function pushUpdate() {
      try {
        const response = await http.PUT(
          `${referralApi.update(data.course)}/${id}/`,
          data
        );
        if (response.status === 200) {
          toggleModal();
          toast.success("Code updated successfully");
          fetchNewData(referralApi.list(data.course));
        } else {
          toast.error(new Error("Error in updating code"));
        }
      } catch (error) {
        toast.error(error);
      }
      setIsFormUpdating(false);
    }
    pushUpdate();
  };

  const [searchedReferral, setSearchedReferral] = useState([]);

  let handleSearch = useMemo(() => {
    return searchText => {
      setSearchValue(searchText);
      let filteredData = referrals.filter(
        referral =>
          referral.code.toLowerCase().includes(searchText.toLowerCase()) ||
          referral.course.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedReferral(filteredData);
    };
  }, [referrals]);

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Referral Codes : {watchCourse} </h4>
        <div className="course_filter">
          <CustomSelect
            id="courseFilter"
            required={true}
            register={register}
            name="courseFilter"
            placeholder="Select Course"
            value={selectedCourse}
            handleChange={data => {
              setSelectedCourse(data);
              setValue("courseFilter", data.value);
            }}
            options={courseOptions}
            disabled={false}
          />
        </div>
        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link to={PATH.ADD_REFERRAL}>
            <Button
              buttonName="Add Code"
              color="success"
              type="button"
              clickHandler={() => {}}
            />
          </Link>
        </div>
      </div>
      <div className="table_container referral_table_container">
        <Tables
          data={searchValue ? searchedReferral : referrals}
          hasError={error}
          columns={columns}
          isFetching={courseOptions?.length ? loading : false}
          isLoading={isFormUpdating}
          formToEdit={<ReferralForm />}
          handleDelete={handleDelete}
          handleClickUpdate={() => handleClickUpdate}
          selectedElementRef={selectedElementRef}
        />
      </div>
    </>
  );
}
export default Referral;
