import React, { useState } from "react";
import "./PremiumWithData.scss";

import PremiumWithData from "./PremiumWithData";
import LiveAccessWithData from "./LiveAccessWithData";
import CheckNumberOfUser from "./CheckNumberOfUser";

function GiveAccess() {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <>
      <div className="give-access-wrapper">
        <div className="tabs">
          <p
            className={selectedTab === 0 ? "active" : ""}
            onClick={() => {
              setSelectedTab(0);
            }}
          >
            Subscription
          </p>
          <p
            className={selectedTab === 1 ? "active" : ""}
            onClick={() => {
              setSelectedTab(1);
            }}
          >
            Live Group
          </p>
          <p
            className={selectedTab === 2 ? "active" : ""}
            onClick={() => {
              setSelectedTab(2);
            }}
          >
            Check Users
          </p>
        </div>
      </div>
      {selectedTab === 0 ? <PremiumWithData /> : selectedTab === 1 ? <LiveAccessWithData /> : <CheckNumberOfUser/>}
    </>
  );
}

export default GiveAccess;
