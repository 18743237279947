import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import "./PremiumWithData.scss";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

import config from "../../config";
import useFetch from "../../hooks/useFetch";
import Button from "../../components/Button/Button";

import http from "../../utils/http";
import toast from "../../utils/toast";

import readXlsxFile from "read-excel-file";
import { Modal } from "react-bootstrap";
import LiveAccessWithMail from "./LiveAccessWithMail";

const liveGroupApi = config.endpoints.api.livegroup;
const courseApi = config.endpoints.api.course;
const giveAccessWithFileApi = config.endpoints.api.giveAccessWithFile;

function LiveAccessWithData() {
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const giveAccessWithOptions = [
    {
      value: 1,
      label: "Phone"
    },
    {
      value: 2,
      label: "Email"
    }
  ];
  const [selectedAccessOption, setSelectedAccessOption] = useState<any>({
    value: 1,
    label: "Phone"
  });

  const [liveGroupTypes, setLiveGroupTypes] = useState<any[]>([]);
  const [selectedLiveGroup, setSelectedLiveGroup] = useState<any>();

  const { loading, error, fetchedData, fetchNewData } = useFetch();
  const {
    loading: courseLoading,
    error: courseError,
    fetchedData: fetchedCourse,
    fetchNewData: fetchNewCourse
  } = useFetch();

  const watchCourse = watch("courseFilter");

  const [courseOptions, setCourseOptions] = useState<any[]>([]);

  const [selectedCourse, setSelectedCourse] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [fileError, setFileError] = useState<boolean>(false);

  const [openModel, setOpenModel] = useState<boolean>(false);

  const [userPhoneNumbers, setUserPhoneNumbers] = useState<any>();

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.name
      };
    });
    return options;
  };

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);
        setValue("courseFilter", options[0].value);
        setSelectedCourse(options[0]);
      } else {
        setCourseOptions([]);
        setValue("courseFilter", undefined);
      }
    }
  }, [fetchedCourse, setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(liveGroupApi.listData(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData.data.length) {
      let options = fetchedData.data.map(item => {
        return {
          value: item.id,
          label: `${item.title} - Seats: ${item.remaining_seats}`
        };
      });
      setLiveGroupTypes(options);
    } else {
      setLiveGroupTypes([]);
    }
  }, [fetchedData]);

  const handleFileChange = e => {
    setValue("file", e.target.files[0]);
    if (e.target.files[0]) {
      clearErrors("file");
      readXlsxFile(e.target.files[0]).then(rows => {
        let data =
          rows &&
          rows.map(r => {
            return r[0];
          });
        if (data[0] !== "Phone" && data.length > 1) {
          setFileError(true);
        } else {
          data.shift();
          setUserPhoneNumbers(data);
          setFileError(false);
          setOpenModel(true);
        }
      });
    }
  };

  const handleSubmitFile = data => {
    if (!data.file) {
      setError("file", { type: "required" });
    } else if (!fileError) {
      setIsLoading(true);
      clearErrors("file");

      let new_data = {
        moduleId: data.courseFilter,
        phoneNumbers: userPhoneNumbers,
        live_group_id: data.liveGroup
      };

      http
        .POST(giveAccessWithFileApi.createLive(watchCourse), new_data)
        .then(res => {
          toast.success(res.data.message);
        })
        .catch(error => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Give Live Access to users.</h4>
        <CustomSelect
          required={false}
          register={register}
          placeholder="Select Option"
          name="accessOption"
          id="accessOption"
          value={selectedAccessOption}
          handleChange={data => {
            setSelectedAccessOption(data);
          }}
          options={giveAccessWithOptions}
          disabled={false}
        />
        <div className="content_filter">
          <div className="course_filter">
            <CustomSelect
              id="courseFilter"
              required={true}
              register={register}
              name="courseFilter"
              placeholder="Select Course"
              value={selectedCourse}
              handleChange={data => {
                setSelectedCourse(data);
                setValue("courseFilter", data.value);
              }}
              options={courseOptions}
              disabled={false}
            />
          </div>
        </div>
      </div>
      {selectedAccessOption.value === 1 ? (
        <>
          <form
            className="premiumWithData-container"
            onSubmit={handleSubmit(handleSubmitFile)}
          >
            <div className="row-container">
              <div className="col-container">
                <div className="row-container">
                  <div className="fieldAndValidate">
                    <label htmlFor="course">
                      Live Group <span>*</span>
                    </label>
                    <CustomSelect
                      id="liveGroup"
                      required={true}
                      register={register}
                      name="liveGroup"
                      value={selectedLiveGroup || ""}
                      handleChange={e => {
                        setValue("liveGroup", e.value);
                        setSelectedLiveGroup(e);
                      }}
                      options={liveGroupTypes}
                      disabled={false}
                    />
                    {errors?.liveGroup?.type === "required" && (
                      <p>This field is required</p>
                    )}
                  </div>
                </div>

                <div className="row-container">
                  <div className="fieldAndValidate">
                    <label htmlFor="file">
                      File <span>*</span>
                    </label>
                    <input
                      // accept=".xsl, .xslx"
                      type="file"
                      id="file"
                      name="file"
                      onChange={handleFileChange}
                    />
                    {errors?.file?.type === "required" && (
                      <p>File is required</p>
                    )}

                    {fileError && (
                      <p>
                        File must only contain Phone numbers with top cell named
                        as "Phone".
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={`row-container loader ${isLoading ? "load" : ""}`}>
              <span></span>
            </div>

            <div className="row-container">
              <div className="button-wrapper">
                {userPhoneNumbers ? (
                  <Button
                    type="button"
                    color="success"
                    disabled={false}
                    buttonName="Check"
                    clickHandler={() => {
                      setOpenModel(!openModel);
                    }}
                  />
                ) : (
                  <></>
                )}
                <Button
                  type="submit"
                  color="success"
                  disabled={isLoading || fileError ? true : false}
                  buttonName={
                    isLoading
                      ? "Submitting"
                      : fileError
                      ? "File Error"
                      : "Submit"
                  }
                />
              </div>
            </div>

            <div className="loading">
              <span></span>
            </div>
          </form>

          <Modal
            size="xl"
            show={openModel}
            onHide={() => {
              setOpenModel(!openModel);
            }}
            dialogClassName={"modal_container"}
            centered
          >
            <Modal.Header className="modalTitle" closeButton>
              <Modal.Title>Review User's Numbers</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalBody">
              <div className="review-container">
                {!userPhoneNumbers ? (
                  <p>No numbers found.</p>
                ) : (
                  <ul>
                    {userPhoneNumbers.map((phone, i) => {
                      return (
                        <li key={i}>
                          {i + 1}
                          {". "} {phone || "NULL"}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <LiveAccessWithMail />
      )}
    </>
  );
}

export default LiveAccessWithData;
