import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { getDateTimeNow } from "../../../../utils/getDateTimeNow";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import "./NotificationForm.scss";
import "react-toastify/dist/ReactToastify.css";
import Textarea from "../../../../components/Textarea/Textarea";

type courseType = {
  label: string;
  value: string;
};

type PropsType = {
  data?: {
    link: string;
    title: string;
    notifType: string;
    notifDate: string;
    notifFile: string;
    recipient: string;
    description: string;
  };
  isLoading?: boolean;
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
};

const NotificationForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        notifFile: null,
        link: data && data?.link,
        title: data && data?.title,
        notifDate: getDateTimeNow(),
        recipient: data && data?.recipient,
        notifType: data && data?.notifType,
        description: data && data?.description
      }
    }
  );

  let dateNow = getDateTimeNow();

  const handleReset = () => {
    setSelectedNotifType(undefined);
    setSelectedRecipient(undefined);
    reset({
      link: "",
      title: "",
      description: "",
      notifFile: null
    });
  };

  const handleClear = () => {
    handleReset();
  };

  const handleSelectChange = (data, e) => {
    setSelectedNotifType(data);
    setValue("notifType", data.value);
  };

  const handleRecipientChange = (data, e) => {
    setSelectedRecipient(data);
    setValue("recipient", data.value);
  };

  const [notifTypeOptions, setNotifTypeOptions] = useState<courseType[]>([]);
  const [selectedNotifType, setSelectedNotifType] =
    useState<courseType | null>();

  const [recipientOptions, setRecipientOptions] = useState<courseType[]>([]);
  const [selectedRecipient, setSelectedRecipient] =
    useState<courseType | null>();

  const convertToOptions = lists => {
    let options: { value: string; label: string; isDisabled: boolean }[] =
      lists.map(item => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: item.isDisabled
        };
      });
    return options;
  };

  useEffect(() => {
    let notifTypes = [
      { id: "select", name: "Select Type", isDisabled: true },
      { id: "Announcement", name: "Announcement" },
      { id: "Notification", name: "Push Notification" }
    ];

    let recipients = [
      { id: "All", name: "All Users" },
      { id: "Premium", name: "Premium Users" },
      { id: "New", name: "New Users" },
      { id: "MBBS", name: "MBBS Users" },
      { id: "PG", name: "PG Users" },
      { id: "NURSING", name: "Nursing Users" }
    ];

    let options = convertToOptions(notifTypes);
    setNotifTypeOptions(options);

    let recipientOptions = convertToOptions(recipients);
    setRecipientOptions(recipientOptions);

    if (editform) {
      let oldType = options.find(item => {
        if (item.value === data?.notifType) return item;
        return null;
      });
      setSelectedNotifType(oldType);

      let oldRecipient = recipientOptions.find(item => {
        if (item.value === data?.recipient) return item;
        return null;
      });
      setSelectedRecipient(oldRecipient);
    }
    setValue("notifType", data?.notifType);
  }, [setValue, editform, data]);

  return (
    <form
      className="notification-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Notification Type <span>*</span>
              </label>
              <CustomSelect
                id="notifType"
                required={true}
                register={register}
                name="notifType"
                placeholder="Select Notification Type"
                value={selectedNotifType || ""}
                handleChange={(e, data) => {
                  setValue("notifType", data.value);
                  handleSelectChange(e, data);
                }}
                options={notifTypeOptions}
              />
              {errors?.notifType?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Select Recipients <span>*</span>
              </label>
              <CustomSelect
                id="recipient"
                required={true}
                register={register}
                name="recipient"
                placeholder="Select Recipients"
                value={selectedRecipient || ""}
                handleChange={(e, data) => {
                  setValue("recipient", data.value);
                  handleRecipientChange(e, data);
                }}
                options={recipientOptions}
              />
              {errors?.recipient?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate date">
              <InputField
                value={dateNow}
                readOnly={true}
                type="datetime-local"
                label="Notification Date"
                {...register("notifDate", {
                  required: true
                })}
              />
              {errors?.notifDate?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate title">
              <InputField
                required
                type="text"
                label="Notification Title"
                placeholder={"Enter the notification title"}
                {...register("title", {
                  required: true
                })}
              />
              {errors?.title?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="col-container">
              <div className="fieldAndValidate period">
                <label>Notification Image</label>
                <div>
                  <input
                    className="custom-file-input border-0 radius-10"
                    id="notifFile"
                    name="notifFile"
                    type="file"
                    onChange={() => {}}
                    {...register("notifFile", {})}
                  />
                </div>
              </div>
              <div className="fieldAndValidate links">
                <InputField
                  type="text"
                  label="Additional Links"
                  placeholder={"Enter the links to notice here"}
                  {...register("link")}
                />
              </div>
            </div>
            <div className="fieldAndValidate">
              <Textarea
                rows={6}
                label="Notification Details"
                placeholder="Enter notification details"
                {...register("description")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading
                ? "Submitting"
                : editform
                ? "Resend Notification"
                : "Send Notification"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default NotificationForm;
