import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "../../../../components/Search/Search";
import { PATH } from "../../../../constants/routes";
import Button from "../../../../components/Button/Button";
import useFetch from "../../../../hooks/useFetch";
import config from "../../../../config";
import http from "../../../../utils/http";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Recording.scss";

import ErrorPages from "../../../../components/ErrorPages/ErrorPages";
import { Modal } from "react-bootstrap";
import VideoPreviewModal from "../../../Content/components/contentForm/VideoPreviewModal";
import RecordingForm from "./recordingForm/RecordingForm";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { useUploadContext } from "../../../../context/UploadContextProvider";
import { toast } from "react-toastify";

const recordingApi = config.endpoints.api.recordingContent;

function Recording() {
  const navigate = useNavigate();
  const location = useLocation();

  const { setIsEditing, uploadRecording, refresh } = useUploadContext();

  const { loading, error, fetchedData, fetchNewData } = useFetch();

  const queryParams = new URLSearchParams(location.search);
  const live_id = queryParams.get("live_id");
  const module_id = queryParams.get("module_id");
  const live_title = queryParams.get("live_title");

  useEffect(() => {
    if (!live_id || !module_id || !live_title) {
      navigate("/group");
    } else {
      setMainData({
        live_id: live_id,
        module_id: module_id,
        live_title: live_title
      });
      fetchNewData(recordingApi.list(module_id, live_id));
    }
  }, []);

  const [mainData, setMainData] = useState({
    live_id: live_id || "",
    module_id: module_id || "",
    live_title: live_title || ""
  });

  const [recordings, setRecordings] = useState([]);
  const [deleteContentTitle, setDeleteContentTitle] = useState("");
  const [deleteContentId, setDeleteContentId] = useState("");
  const [updateContentData, setUpdateContentData] = useState({});

  const [isPreview, setIsPreview] = useState(false);
  const [showEditRecordingModal, setShowEditRecordingModal] = useState(false);
  const toggleEditRecordingModal = () => {
    setShowEditRecordingModal(!showEditRecordingModal);
  };
  const [showDeleteRecordingModal, setShowDeleteRecordingModal] =
    useState(false);
  const toggleDeleteRecordingModal = () => {
    setShowDeleteRecordingModal(!showDeleteRecordingModal);
  };

  useEffect(() => {
    if (fetchedData) {
      setRecordings(fetchedData.data);
    } else {
      setRecordings([]);
    }
  }, [fetchedData, refresh]);

  const handleDelete = async (id, module_id) => {
    try {
      const response = await http.REMOVE(
        `${recordingApi.delete(module_id)}/${id}/`
      );
      if (response.status === 200) {
        toast.success("Recording deleted successfully");
        setRecordings(recordings => {
          return recordings.filter(recording => recording.id !== id);
        });
      } else {
        toast.error(new Error("Error in deleting the chapter"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleClickUpdateContent = data => {
    async function pushUpdate() {
      try {
        setIsEditing(true);
        await uploadRecording(data, true);
        await fetchNewData(recordingApi.list(module_id, live_id));
        toggleEditRecordingModal();
      } catch (error) {
        toast.error(error);
      }
    }
    pushUpdate();
  };

  const handleSearch = () => {};
  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Live : {live_title} </h4>

        <div className="search_wrapper ">
          <Search handleSearch={handleSearch} />
        </div>
        <div className="button_wrapper ">
          <Link
            to={`/live/${PATH.ADD_RECORDING}?module_id=${module_id}&live_id=${live_id}&live_title=${live_title}`}
          >
            <Button
              buttonName="Add Recording"
              color="success"
              type="button"
              clickHandler={() => {}}
            />
          </Link>
        </div>
      </div>

      <div className="recording_container">
        <div className="list">
          {recordings?.map((recording, i) => {
            return (
              <RecordingCard
                setDeleteContentTitle={setDeleteContentTitle}
                setDeleteContentId={setDeleteContentId}
                setUpdateContentData={setUpdateContentData}
                toggleDeleteRecordingModal={toggleDeleteRecordingModal}
                toggleEditRecordingModal={toggleEditRecordingModal}
                recording={recording}
                key={i}
              />
            );
          })}
        </div>

        <ErrorPages
          isFetching={loading}
          data={recordings && recordings.length ? true : false}
          error={error ? true : false}
        />
      </div>

      {isPreview && (
        <Modal
          centered
          onHide={() => {
            setIsPreview(false);
          }}
          show={isPreview}
        >
          <Modal.Body className="video-preview-body">
            <VideoPreviewModal url={updateContentData.link} />
          </Modal.Body>
        </Modal>
      )}

      <DeleteModal
        id={deleteContentId}
        name={deleteContentTitle}
        module_id={module_id}
        show={showDeleteRecordingModal}
        handleClose={toggleDeleteRecordingModal}
        handleDelete={handleDelete}
      />

      <Modal
        centered
        onHide={toggleEditRecordingModal}
        show={showEditRecordingModal}
        dialogClassName={"modal_container"}
      >
        <Modal.Header className="modal_title" closeButton>
          <Modal.Title>Edit Recording</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal_body">
          <div
            style={{
              height: "80vh",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            <RecordingForm
              editform
              mainData={mainData}
              data={updateContentData}
              handleCancel={toggleEditRecordingModal}
              handleClickUpdate={handleClickUpdateContent}
              isPreview={isPreview}
              setIsPreview={setIsPreview}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Recording;

const RecordingCard = ({
  recording,
  setDeleteContentTitle,
  setDeleteContentId,
  setUpdateContentData,
  toggleDeleteRecordingModal,
  toggleEditRecordingModal
}) => {
  const handleDeleteModel = () => {
    setDeleteContentId(recording.id);
    setDeleteContentTitle(recording.title);
    toggleDeleteRecordingModal();
  };
  const handleUpdate = () => {
    setUpdateContentData(recording);
    toggleEditRecordingModal();
  };
  return (
    <div className="item">
      <div className="item-content">
        <h1>{recording.title}</h1>
        <p>{recording.description}</p>
      </div>
      <div className="action_buttons">
        <i title="Edit Content" onClick={handleUpdate}>
          <FontAwesomeIcon className="edit" icon={faEdit} />
        </i>
        <i title="Delete Content" onClick={handleDeleteModel}>
          <FontAwesomeIcon className="trash" icon={faTrashCan} />
        </i>
      </div>
    </div>
  );
};
