/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./VideoLinksModal.scss";

import useFetch from "../../../../../hooks/useFetch";
import Loader from "../../../../../components/Loader/Loader";

import notFound from "../../../../../assets/gifs/not_found.gif";

import config from "../../../../../config";
const videoApi = config.endpoints.api.video;
const pdfApi = config.endpoints.api.pdf;

const VideoLinksModal = ({ setValue, setVideoLinksModal, contentType }) => {
  const [searchText, setSearchText] = useState("");
  const { fetchedData, fetchNewData, loading } = useFetch();
  const api = contentType == "Video" ? videoApi : pdfApi;

  //   const [contents, setContents] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    let currentOffset = (pageNumber - 1) * 10;
    fetchNewData(
      `${api.list}?offset=${currentOffset}&limit=10&title=${searchText}`
    );
  }, [fetchNewData, pageNumber, searchText]);

  return (
    <div className="links-modal">
      <input
        type="text"
        value={searchText}
        className="links-modal__input"
        placeholder="Search"
        onChange={e => {
          setSearchText(e.target.value);
        }}
      />

      <h4>Available {contentType}s</h4>
      {loading ? (
        <div className="links-modal__loading">
          <Loader size={40} />
        </div>
      ) : fetchedData.data.length < 1 ? (
        <div className="links-modal__no-data">
          <img
            src={notFound}
            alt="no-data"
            style={{
              width: "120px",
              height: "auto"
            }}
          />
          <h4>No {contentType}s Found</h4>
          <p>Upload {contentType}s instead.</p>
        </div>
      ) : (
        <>
          <div className="links-modal__video_lists">
            {fetchedData.data.map((each, index) => (
              <div
                className="links-modal__video_lists__video_container"
                key={index}
              >
                {contentType == "Video" && (
                  <video
                    src={`https://nameavyaas.obs.ap-southeast-3.myhuaweicloud.com/${each.link}`}
                    muted
                    controls
                    className="video_lists__video_container__video"
                  />
                )}
                <div
                  className="links-modal__video_lists__video_container__description"
                  onClick={() => {
                    setValue("link", each.link);
                    setVideoLinksModal(false);
                  }}
                >
                  <p>{each.title}</p>
                  <p className="link">{each.link}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="links-modal__table_pagination">
        <button
          disabled={pageNumber === 1}
          onClick={() => {
            setPageNumber(1);
          }}
        >
          <b>{"<<"}</b>
        </button>
        <button
          disabled={pageNumber === 1}
          onClick={() => {
            if (pageNumber > 1) setPageNumber(pageNumber - 1);
          }}
        >
          <b>{"<"}</b>
        </button>
        <span>
          &nbsp;Page&ensp;
          <input
            type="number"
            value={pageNumber}
            onChange={e => {}}
            style={{ width: "50px" }}
          />
          &nbsp; of {(fetchedData.totalVideos / 10).toFixed(0)} &nbsp;
        </span>
        <button
          disabled={pageNumber == (fetchedData.totalVideos / 10).toFixed(0)}
          onClick={() => {
            if (pageNumber < (fetchedData.totalVideos / 10).toFixed(0))
              setPageNumber(pageNumber + 1);
          }}
        >
          <b>{">"}</b>
        </button>
        <button
          disabled={pageNumber == (fetchedData.totalVideos / 10).toFixed(0)}
          onClick={() => {
            setPageNumber((fetchedData.totalVideos / 10).toFixed());
          }}
          // disabled={!canNextPage}
        >
          <b>{">> "}</b>
        </button>
      </div>
    </div>
  );
};

export default VideoLinksModal;
