import React from "react";
import styles from "./ImageInput.module.scss";
import Button from "../Button/Button";

type RefType = HTMLInputElement | null;
type PropsType = {
  image: any;
  setImage: Function;
  editform: boolean;
  staffForm?: React.FC | any;
  edit?: boolean;
  onChange: Function;
  rest?: any;
  onError?: Function;
};

const ImageInput = React.forwardRef<RefType, PropsType>(
  (
    {
      // validateImage,
      image,
      setImage,
      editform,
      staffForm,
      edit = false,
      onChange,
      onError,
      ...rest
    },
    ref
  ) => {
    const handleFile = e => {
      if (e.target.files && e.target.files[0]) {
        setImage(URL.createObjectURL(e.target.files[0]));
      }
    };

    const fileSelect = e => {
      let imageElement: HTMLElement | null =
        document.getElementById("staff_img");
      if (imageElement) imageElement.click();
    };

    return (
      <div className={styles.inputContainer}>
        <div className={styles.imageContainer}>
          {image ? (
            <img
              src={image}
              alt="thumbnail"
              onError={e => onError && onError(e)}
            />
          ) : staffForm ? (
            <i className="fas fa-user-alt"></i>
          ) : (
            <i className="far fa-image"></i>
          )}
        </div>
        <div className={styles.inputBtnWrapper}>
          <input
            id="staff_img"
            hidden
            type="file"
            ref={ref}
            accept="image/jpeg, image/jpg, image/png"
            {...rest}
            onChange={e => {
              onChange(e);
              handleFile(e);
            }}
          />
          <Button
            type="button"
            color="success"
            buttonName={
              !editform
                ? staffForm
                  ? "Select Photo"
                  : "Select Image"
                : staffForm
                ? "Update Photo"
                : "Update Image"
            }
            clickHandler={fileSelect}
          />
        </div>
      </div>
    );
  }
);

export default ImageInput;
