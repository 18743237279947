import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import config from "../../../../config";
import http from "../../../../utils/http";
import useFetch from "../../../../hooks/useFetch";

import Button from "../../../../components/Button/Button";
// import Textarea from "../../../../components/Textarea/Textarea";
import InputField from "../../../../components/InputField/InputField";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import "./ChapterForm.scss";
import "react-toastify/dist/ReactToastify.css";

let courseApi = config.endpoints.api.course;

const ChapterForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}) => {
  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        unit_id: data && data.unit_id,
        module_id: data && data.module_id,
        subject_id: data && data.subject_id,
        // description: data && data.description,
        chapters_id: data && data.chapters_id,
        chapter_name: data && data.chapter_name
      }
    }
  );

  let unitApi = config.endpoints.api.unit;
  let subjectApi = config.endpoints.api.subject;

  const watchCourse = watch("module_id");
  const watchSubject = watch("subject_id");

  const [unitOptions, setUnitOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);

  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedSubject, setSelectedSubject] = useState();

  const { fetchedData: fetchedCourse, fetchNewData: fetchNewCourse } =
    useFetch();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);
        setValue("module_id", options[0].value);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
          setValue("module_id", course?.value);
        }
      } else {
        setCourseOptions([]);
        setValue("module_id", undefined);
      }
    }
  }, [fetchedCourse, setValue, data, editform]);

  const convertToSubjectOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.subject_id,
        label: item.subject_name
      };
    });
    return options;
  };

  const convertToUnitOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.unit_id,
        label: item.unit_name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchSubjects() {
      let subjects = await http.GET(subjectApi.list(watchCourse));

      if (subjects?.data?.data?.length) {
        let options = convertToSubjectOptions(subjects.data.data);
        setSubjectOptions(options);

        if (editform) {
          let subject = options.find(item => {
            if (item.value === data?.subject_id) return item;
            return null;
          });
          setSelectedSubject(subject);
        }
      } else {
        setUnitOptions([]);
        setSubjectOptions([]);
      }
    }
    watchCourse && fetchSubjects();
  }, [watchCourse, setValue, editform, data?.subject_id, subjectApi]);

  useEffect(() => {
    async function fetchUnits() {
      let units = await http.GET(unitApi.list(watchCourse, watchSubject));

      if (units?.data?.data?.length) {
        let options = convertToUnitOptions(units.data.data);
        setUnitOptions(options);

        if (editform) {
          let unit = options.find(item => {
            if (item.value === data?.unit_id) return item;
            return null;
          });
          setSelectedUnit(unit);
        }
      } else {
        setUnitOptions([]);
        setSelectedUnit(undefined);
        setValue("unit_id", undefined);
      }
    }
    watchSubject && fetchUnits();
  }, [watchCourse, watchSubject, setValue, editform, data?.unit_id, unitApi]);

  const handleReset = () => {
    setSelectedCourse(undefined);
    setSelectedSubject(undefined);
    setSelectedUnit(undefined);
    setUnitOptions([]);
    setSubjectOptions([]);

    reset({
      chapter_name: ""
      // description: ""
    });
  };

  return (
    <form
      className="chapter-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <label>
                Course <span>*</span>
              </label>
              <CustomSelect
                id="module_id"
                required={true}
                register={register}
                name="module_id"
                placeholder="Select Course"
                value={selectedCourse || ""}
                handleChange={data => {
                  setSelectedUnit(undefined);
                  setValue("unit_id", undefined);
                  setSelectedSubject(undefined);
                  setValue("subject_id", undefined);
                  setSelectedCourse(data);
                  setValue("module_id", data.value);
                }}
                options={courseOptions}
                disabled={false}
              />
              {errors?.module_id?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate">
              <label>
                Subject <span>*</span>
              </label>
              <CustomSelect
                id="subject_id"
                required={true}
                register={register}
                name="subject_id"
                placeholder="Select Subject"
                value={selectedSubject || ""}
                handleChange={data => {
                  setSelectedUnit(undefined);
                  setValue("unit_id", undefined);
                  setSelectedSubject(data);
                  setValue("subject_id", data.value);
                }}
                options={subjectOptions}
                disabled={false}
              />
              {errors?.subject_id?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate">
              <label>
                Unit <span>*</span>
              </label>
              <CustomSelect
                id="unit_id"
                required={true}
                register={register}
                name="unit_id"
                placeholder="Select Unit"
                value={selectedUnit || ""}
                handleChange={data => {
                  setSelectedUnit(data);
                  setValue("unit_id", data.value);
                }}
                options={unitOptions}
                disabled={false}
              />
              {errors?.unit_id?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate">
              <InputField
                required
                type="text"
                label="Chapter Name"
                placeholder={"Enter the chapter name"}
                {...register("chapter_name", {
                  required: true
                })}
              />
              {errors?.chapter_name?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>

          {/* <div className="fieldAndValidate">
            <Textarea
              label="Description"
              rows={5}
              placeholder="Enter course description"
              {...register("description", {})}
            />
          </div> */}
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            clickHandler={() => {}}
            disabled={isLoading ? true : false}
            buttonName={
              isLoading
                ? "Submitting"
                : editform
                ? "Update Chapter"
                : "Add Chapter"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleReset()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default ChapterForm;
