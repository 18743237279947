import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLogin from "../pages/AdminLogin/AdminLogin";

export default function LoginRoutes() {
  return (
    <Routes>
      <Route index element={<AdminLogin />} />
    </Routes>
  );
}
