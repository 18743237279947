import React from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import * as csvtojson from "csvtojson";
import { PATH } from "../../../constants/routes";
import testStatus from "../../../utils/testStatus";
import http from "../../../utils/http";
import config from "../../../config";

import Tag from "../../../components/Tag/Tag";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";

function QuizDetail({ activeTest, selectedCourse }) {
  const { id, end, start, name, isQuestionSet } = activeTest;
  let { url } = {
    moduleId: selectedCourse,
    url: `${config.baseURI}/api/v1/${selectedCourse}`
  };

  let endDate = moment.utc(end);
  let currentDate = moment.utc();
  let startDate = moment.utc(start);
  let { status, color } = testStatus(currentDate, startDate, endDate, isQuestionSet);

  const navigate = useNavigate();

  const navigateToAdd = () => {
    navigate(PATH.ADD_QUIZ, {
      state: {
        testId: id,
        selectedCourse
      }
    });
  };

  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = () => {
      const csv = reader.result;
      csvtojson()
        .fromString(csv)
        .then(async json => {
          json.forEach(question => {
            question.testId = id;
          });
          try {
            const res = await http.POST(`${url}/teacher/test/bulkUpload`, {
              jsonQuestions: JSON.stringify(json)
            });
            if (res) {
              navigateToAdd();
            }
          } catch (err) {
            toast.error("Someething went wrong");
          }
        })
        .catch(err => toast.error(err));
    };

    reader.onerror = error => {
      toast.error(error);
    };
  };

  const fileSelect = e => {
    let fileElement = document.getElementById("import_csv");
    if (fileElement) fileElement.click();
  };

  return (
    <div className="result_header_contents">
      <div className="test_name">
        <h5 className="mb-0 d-block text-dark">{name}</h5>

        <p className="text-muted date d-block my-0  ">
          {moment(start).local().format("MMMM DD YYYY - HH:mm")}&ensp;↔&ensp;
          {moment(end).local().format("MMMM DD YYYY - HH:mm")}
        </p>
      </div>

      {status === "Expired" ? (
        <div className="status_tag">
          <Tag text={status || "Unknown"} color={color} size={"small"} />
        </div>
      ) : (
        <div className="add_import_questions">
          <label>Manage Quiz Questions</label>
          <div className="buttons">
            <div className="add">
              <Button
                buttonName="Edit"
                iconName="bx bxs-edit"
                clickHandler={navigateToAdd}
              />
            </div>
            <div className="import">
              <input
                type="file"
                id="import_csv"
                onChange={handleFileUpload}
                hidden
              />
              <Button
                buttonName="Import"
                iconName="bx bxs-file-import"
                clickHandler={fileSelect}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizDetail;
