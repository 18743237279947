import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import RecordingForm from "./recordingForm/RecordingForm";

import "./AddRecording.scss";
import { useUploadContext } from "../../../../context/UploadContextProvider";

function AddRecording() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isSubmitting } = useUploadContext();

  const queryParams = new URLSearchParams(location.search);
  const live_id = queryParams.get("live_id");
  const module_id = queryParams.get("module_id");
  const live_title = queryParams.get("live_title");

  useEffect(() => {
    if (!live_id || !module_id || !live_title) {
      navigate("/group");
    } else {
      setMainData({
        live_id: live_id,
        module_id: module_id,
        live_title: live_title
      });
    }
  }, []);

  const [mainData, setMainData] = useState({
    live_id: live_id || "",
    module_id: module_id || "",
    live_title: live_title || ""
  });

  return (
    <div className="add_recording">
      <div className="content_tab_header">
        <h4>{`Add Recording to ${live_title}`}</h4>
        <div className="buttonWrapper">
          <div className="addCourseButton">
            <Button
              type="button"
              buttonName="< Back"
              color="success"
              clickHandler={() =>
                navigate(
                  `/live/recording?module_id=${module_id}&live_id=${live_id}&live_title=${live_title}`
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="record_form">
        <RecordingForm mainData={mainData} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
}

export default AddRecording;
