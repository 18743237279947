import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
// import Textarea from "../../../../components/Textarea/Textarea";
import ImageInput from "../../../../components/ImageInput/ImageInput";
import RichTextarea from "../../../../components/Textarea/RichTextarea";

import { FaPlay } from "react-icons/fa";

import "./ContentForm.scss";
import "react-toastify/dist/ReactToastify.css";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

import { useUploadContext } from "../../../../context/UploadContextProvider";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import VideoLinksModal from "./videoLinks/VideoLinksModal";

const ContentForm = ({
  data,
  editform,
  contentType,
  contentMetadata,
  handleCancel,
  handleClickUpdate,
  isSubmitting,
  isPreview,
  setIsPreview
}) => {
  if (!editform) {
    var { module_id, chapters_id, subject_id, unit_id } = contentMetadata;
  }

  const [image, setImage] = useState(
    editform
      ? data.thumbnail &&
          `${process.env.REACT_APP_IMAGE_BASE_URL}/videos/${data.thumbnail}`
      : ""
  );

  const handleFileChange = e => {
    setTempFile(e.target.files[0]);
    setValue("file", e.target.files[0]);
    // calculate duration only works for mp4, so not using it for now
    // const video = document.createElement("video");
    // video.addEventListener("loadedmetadata", () => {
    //   setValue("length", video.duration);
    // });
    // video.src = URL.createObjectURL(e.target.files[0]);
  };

  const {
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform
      ? {
          defaultValues: {
            id: data && data.id,
            title: data && data.title,
            thumbnail: data && data.thumbnail,
            description: data && data.description,
            level: data && data.level,
            file: data && data.file,
            length: data && data.length,
            link:
              data &&
              data.link.replace(
                "https://backend.avyaas.com/api/video/course-video/",
                ""
              ),
            module_id: data && data.module_id,
            chapterId: data && data.chapterId,
            subjectId: data && data.subjectId,
            unitId: data && data.unitId,
            visibility: "public"
          }
        }
      : {
          defaultValues: {
            module_id,
            chapterId: chapters_id,
            subjectId: subject_id,
            unitId: unit_id,
            visibility: "public",
            level: "Intermediate"
          }
        }
  );

  const [selectedTab, setSelectedTab] = useState("url");

  const getSelectedTab = tab => {
    if (selectedTab === tab) {
      return "active";
    }
  };

  const handleClear = () => {
    setImage("");
    reset({
      file: null,
      title: "",
      thumbnail: "",
      description: "",
      module_id,
      length:0,
      chapterId: chapters_id,
      subjectId: subject_id,
      unitId: unit_id,
      visibility: "public",
      level: "Intermediate"
    });
    setTempFile(null);
  };

  const [tempFile, setTempFile] = useState(null);
  const [premiumOptions, setPremiumOptions] = useState([]);
  const [premium, setPremium] = useState();

  // video links
  const [videoLinksModal, setVideoLinksModal] = useState(false);

  const handlePremiumChange = (data, e) => {
    setPremium(data);
    setValue("premium", data.value);
  };

  useEffect(() => {
    const premiumValues = [
      {
        value: 1,
        label: "True"
      },
      {
        value: 0,
        label: "False"
      }
    ];

    setValue("premium", 1);
    setPremium({
      value: 1,
      label: "True"
    });
    setPremiumOptions(premiumValues);

    if (editform) {
      let premium = premiumValues.find(item => {
        let premiumValue = data.premium ? 1 : 0;
        if (item.value === premiumValue) return item;
        return null;
      });
      setPremium(premium);
      setValue("premium", premium?.value);
    }
  }, [setValue, data, editform]);

  const { uploadFile } = useUploadContext();

  const handleClickSubmit = data => {
    if (!data.file && !data.link) {
      toast.error("Please upload a file or enter a link");
      return;
    } else {
      uploadFile(data, contentType, false);
      setImage("");
      reset({
        file: null,
        link: "",
        title: "",
        description: "",
        module_id,
        length:0,
        chapterId: chapters_id,
        subjectId: subject_id,
        unitId: unit_id,
        visibility: "public",
        level: "Intermediate"
      });
      setTempFile(null);
    }
  };

  const content_type_temp = String(contentType).toLocaleLowerCase();
  // capitalize first letter of content type
  const content_type =
    content_type_temp.charAt(0).toUpperCase() + content_type_temp.slice(1);

  return (
    <>
      <form
        className="content-form-container"
        onSubmit={handleSubmit(
          editform ? handleClickUpdate : handleClickSubmit
        )}
        // onSubmit={handleSubmit(uploadFile)}
      >
        <div className="row-container">
          <div className="col-container">
            <div className="row-container">
              <div className="content_image">
                {contentType === "Video" && (
                  <ImageInput
                    image={image}
                    editform={editform}
                    setImage={setImage}
                    {...register("thumbnail", {
                      required: false
                    })}
                  />
                )}
              </div>

              <div className="col-container">
                <div className="row-container">
                  <div className="fieldAndValidate">
                    <InputField
                      required
                      type="text"
                      name="title"
                      label={`${content_type} Title`}
                      placeholder={"Enter the content title"}
                      {...register("title", {
                        required: true
                      })}
                    />
                    {errors?.title?.type === "required" && (
                      <p>This field is required</p>
                    )}
                  </div>
                  <div className="fieldAndValidate premium">
                    <label htmlFor="premium">
                      Is Premium <span>*</span>
                    </label>
                    <CustomSelect
                      id="premium"
                      value={premium}
                      required={true}
                      disabled={false}
                      name="premium"
                      register={register}
                      options={premiumOptions}
                      handleChange={(e, data) => {
                        handlePremiumChange(e, data);
                      }}
                    />
                    {errors?.premium?.type === "required" && (
                      <p>This field is required</p>
                    )}
                  </div>
                </div>

                <div className="row-container">
                  <div className="video-field">
                    <div className="selector-tabs">
                      <p
                        className={`tab ${getSelectedTab("url")}`}
                        onClick={() => setSelectedTab("url")}
                      >
                        Url
                      </p>
                      <p
                        className={`tab ${getSelectedTab("file")}`}
                        onClick={() => setSelectedTab("file")}
                        style={{ textTransform: "capitalize" }}
                      >
                        {content_type} File
                      </p>
                    </div>
                    {selectedTab === "file" ? (
                      <div className="file_field">
                        <div className="label_edit">
                          <label>{content_type}</label>
                          {/* {editform && <InputField disabled {...register("link")} />} */}
                        </div>
                        <label htmlFor="file">
                          <div className="upload_container">
                            <div className="upload_icon">
                              <i className="fas fa-upload"></i>
                            </div>
                            <div className="upload_text">
                              <p>
                                {tempFile
                                  ? tempFile.name
                                  : `Upload ${content_type}`}
                              </p>
                            </div>
                          </div>
                        </label>
                        <input
                          type="file"
                          id="file"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        {errors?.file?.type === "required" && (
                          <p>This field is required</p>
                        )}
                      </div>
                    ) : (
                      <div
                        className="fieldAndValidate"
                        style={{ position: "relative" }}
                      >
                        <InputField
                          required={false}
                          type="text"
                          min={1}
                          name="link"
                          // readOnly
                          label={`${content_type} Link (If already uploaded)`}
                          placeholder={`Enter ${content_type} url of the already uploaded video`}
                          {...register("link")}
                          // onClick={() => setVideoLinksModal(true)}
                          style={
                            {
                              // cursor: "pointer"
                            }
                          }
                        />
                        {editform && contentType === "Video" && (
                          <div
                            className="preview-btn"
                            onClick={() => setIsPreview(true)}
                          >
                            <FaPlay className="play-icon" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row-container">
                  {contentType === "Video" && <div className="fieldAndValidate">
                    <InputField
                      required
                      type="number"
                      min={1}
                      name="length"
                      label={"Length (Seconds)"}
                      placeholder={"Enter video duration in seconds"}
                      {...register("length", {
                        required: true
                      })}
                    />
                    {errors?.length?.type === "required" && (
                      <p>This field is required</p>
                    )}
                  </div>}
                  <div className="fieldAndValidate">
                    <InputField
                      required
                      type="text"
                      name="level"
                      label={`Level`}
                      placeholder={"Enter the difficulty level"}
                      {...register("level")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fieldAndValidate">
              {/* <Textarea
                label="Description"
                rows={5}
                name="description"
                placeholder="Enter content description"
                {...register("description")}
              /> */}
              <RichTextarea
                required
                control={control}
                name="description"
                label="Description"
                editform={editform}
                placeholder={"Enter the video description"}
              />
            </div>
          </div>
        </div>

        <div className="row-container">
          <div className="button-wrapper">
            <Button
              type="submit"
              color="success"
              buttonName={
                isSubmitting
                  ? "Submitting"
                  : editform
                  ? `Update ${content_type}`
                  : `Add ${content_type}`
              }
              disabled={isSubmitting ? true : false}
            />
            {!editform && (
              <Button
                type="button"
                color="danger"
                buttonName="Clear"
                clickHandler={() => handleClear()}
              />
            )}
            {editform && (
              <Button
                type="button"
                color="danger"
                buttonName="Cancel"
                clickHandler={() => handleCancel()}
              />
            )}
          </div>
        </div>
      </form>
      {(isPreview || videoLinksModal) && <div className="backdrop"></div>}
      <Modal centered onHide={setVideoLinksModal} show={videoLinksModal}>
        <Modal.Body
          style={{
            position: "relative"
          }}
        >
          <VideoLinksModal
            contentType={contentType}
            setValue={setValue}
            setVideoLinksModal={setVideoLinksModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContentForm;
