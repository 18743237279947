import React from "react";

function Loader({
  error,
  errorTxt = "Error!",
  length,
  loading,
  loadingTxt = "Loading...",
  children
}) {
  return error === undefined || !error ? (
    loading === undefined || loading ? (
      length === undefined || length !== 0 ? (
        children
      ) : (
        <div>Item Not Found.</div>
      )
    ) : (
      <div>{loadingTxt}</div>
    )
  ) : (
    <div>{errorTxt}</div>
  );
}

export default Loader;
