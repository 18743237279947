import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { getAccessToken } from "../services/token";
import PremiumWithData from "../pages/PremiumWithData/PremiumWithData";
import GiveAccess from "../pages/PremiumWithData/GiveAccess";

export default function PremiumWithDataRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<GiveAccess />} />
    </Routes>
  );
}
