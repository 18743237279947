import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./TableActions.scss";
import DeleteModal from "../DeleteModal/DeleteModal";

import { Modal } from "react-bootstrap";
import Button from "../Button/Button";

type PropsType = {
  data: object | any;
  handleDelete: Function;
  handleClickUpdate: Function;
  formToEdit: ReactElement;
  isLoading?: boolean;
  notificationForm?: boolean;
  userForm?: boolean;
  setSelectedUser?: Function;
  setShowPaymentModal?: Function;
};

export default function TableAction({
  data,
  handleDelete,
  handleClickUpdate,
  formToEdit,
  isLoading,
  notificationForm,
  userForm,
  setSelectedUser,
  setShowPaymentModal
}: PropsType) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const handleClose = () => setShowDeleteModal(false);
  const handleShow = () => setShowDeleteModal(true);

  const toggleModal = () => setShowEditModal(!showEditModal);

  return (
    <>
      {userForm ? (
        <td>
          <div className="payment_button">
            <Button
              buttonName="Add Payment"
              color="success"
              type="button"
              clickHandler={() => {
                setSelectedUser(data);
                setShowPaymentModal(true);
              }}
            />
          </div>
        </td>
      ) : (
        <td className="actions">
          {notificationForm ? (
            <i className="fas fa-redo-alt" onClick={toggleModal}></i>
          ) : (
            <FontAwesomeIcon
              onClick={toggleModal}
              className="edit_btn"
              icon={faEdit}
            />
          )}
          <FontAwesomeIcon
            onClick={handleShow}
            className="trash_btn"
            icon={faTrashCan as IconProp}
          />
        </td>
      )}

      <Modal
        size="xl"
        show={showEditModal}
        onHide={toggleModal}
        dialogClassName={"modal_container"}
        centered
      >
        <Modal.Header className="modalTitle" closeButton>
          <Modal.Title>
            {notificationForm ? "Resend Notification" : "Update Form"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modalBody">
          {formToEdit &&
            React.cloneElement(formToEdit, {
              data: data,
              editform: 1,
              handleCancel: toggleModal,
              handleClickUpdate,
              isLoading
            })}
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        handleClose={handleClose}
        id={data.id}
        module_id={data.module_id}
        name={
          data.name ||
          data.first_name ||
          data.subject_name ||
          data.title ||
          data?.user?.phone ||
          data?.user?.name ||
          data?.user?.first_name
        }
        handleDelete={handleDelete}
      />
    </>
  );
}
