import React, { useEffect, useState } from "react";
import EnrollmentBarChart from "./EnrollmentChart";
import DonutChart from "./DonutChart";
import AntdStatistics from "./AntdStatistics";
import "./DashboardAnalytics.scss";
import config from "../../../config";
import httpMethods from "../../../utils/http";
import UserTrendChart from "./UserTrendChart";
import PaymentChart from "../analytics/PaymentChart";
import AntdTable from "./AntdTable";
import ProgressBar from "./ProgressBar";
import coinIcon from "../../../assets/icons/coinIcon.png";
import courseIcon from "../../../assets/icons/courseIcon.png";
import moneybagIcon from "../../../assets/icons/moneybagIcon.png";
import userIcon from "../../../assets/icons/userIcon.png";
import PaymentLineChart from "../analytics/PaymentLineChart";

import { getUser } from "../../../utils/storage";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/routes";

interface CountProps {
  users: number;
  courses: number;
  last30DaysPayment: number;
  last30DaysTestPayment: number;
  totalRevenue: number;
  totalTestRevenue: number;
  feedbackCount: number;
  subscriptionCount: number;
}

const DashboardAnalytics = () => {
  let user = getUser();
  let navigate = useNavigate();
  const [numberCount, setNumberCount] = useState<CountProps>({
    users: 0,
    courses: 0,
    last30DaysPayment: 0,
    last30DaysTestPayment: 0,
    totalRevenue: 0,
    totalTestRevenue: 0,
    feedbackCount: 0,
    subscriptionCount: 0
  });

  const apiUrl = `${config.baseURI}/api/v1/count/dataCount`;

  useEffect(() => {
    if (user.role !== "administration") {
      navigate(PATH.USER);
    } else {
      httpMethods.GET(apiUrl).then(response => {
        let numbers = response.data.data;
        setNumberCount(numbers);
      });
    }
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="top_container">
          <section className="count_container">
            <div className="label_card">
              <div className="icon">
                <img src={userIcon} alt={userIcon} />
              </div>
              <div className="text-container">
                <h6>{numberCount?.users}</h6>
                <p>Total Users</p>
              </div>
            </div>
            <div className="label_card">
              <div className="icon">
                <img src={courseIcon} alt={courseIcon} />
              </div>
              <div className="text-container">
                <h6>{numberCount?.courses}</h6>
                <p>Total Courses</p>
              </div>
            </div>
            <div className="label_card">
              <div className="icon">
                <img src={coinIcon} alt={coinIcon} />
              </div>
              <div className="text-container">
                <h6>Rs. {numberCount?.last30DaysPayment}</h6>
                <p>Last 30 Days</p>
              </div>
            </div>
            <div className="label_card">
              <div className="icon">
                <img src={moneybagIcon} alt={moneybagIcon} />
              </div>
              <div className="text-container">
                <h6>Rs. {numberCount?.totalRevenue}</h6>
                <p>Total Revenue</p>
              </div>
            </div>
          </section>
          <section className="antdstat_container">
            <AntdStatistics
              feedbackCount={numberCount?.feedbackCount}
              subscriptionCount={numberCount?.subscriptionCount}
            />
          </section>
        </div>
        <div className="middle_chart_container">
          <section className="left_chart_container">
            <div className="barChart_container">
              <PaymentChart />
            </div>
            <section className="recent_container">
              <div className="progress_bar_container">
                <ProgressBar />
              </div>
              <div className="antdtable_container">
                <AntdTable />
              </div>
            </section>
          </section>
          <section className="enrollment_chart_bar">
            <EnrollmentBarChart />
          </section>
        </div>
        <div className="bottom_container">
          <section className="user_trend_container">
            <UserTrendChart />
          </section>
          <div className="payment_container">
            <DonutChart />
          </div>
          <div className="barChart_container">
            <PaymentLineChart />
          </div>
          <section className="count_container testPayment">
            <div className="label_card">
              <div className="icon">
                <img src={coinIcon} alt={coinIcon} />
              </div>
              <div className="text-container">
                <h6>Rs. {numberCount?.last30DaysTestPayment}</h6>
                <p>Last 30 Days Test Payment</p>
              </div>
            </div>

            <div className="label_card">
              <div className="icon">
                <img src={moneybagIcon} alt={moneybagIcon} />
              </div>
              <div className="text-container">
                <h6>Rs. {numberCount?.totalTestRevenue}</h6>
                <p>Total Test Revenue</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DashboardAnalytics;
