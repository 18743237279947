import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import Button from "../../../../components/Button/Button";
// import Textarea from "../../../../components/Textarea/Textarea";
import ImageInput from "../../../../components/ImageInput/ImageInput";
import InputField from "../../../../components/InputField/InputField";
import RichTextarea from "../../../../components/Textarea/RichTextarea";

import "./UnitForm.scss";
import "react-toastify/dist/ReactToastify.css";
import useFetch from "../../../../hooks/useFetch";

import config from "../../../../config";
import http from "../../../../utils/http";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";

let subjectApi = config.endpoints.api.subject;

type UnitType = {
  description: string;
  id: number;
  name: string;
};

type PropsType = {
  data?: UnitType | any;
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const UnitForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const [image, setImage] = useState(
    editform
      ? data.logo_id &&
          `${process.env.REACT_APP_IMAGE_BASE_URL}/unit/${data.logo_id}`
      : ""
  );

  const {
    reset,
    watch,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data.id,
        unit_name: data && data.unit_name,
        subject_id: data && data.subject_id,
        logo_id: data && data.logo_id,
        description: data && data.description,
        module_id: data && data.module_id
      }
    }
  );

  let courseApi = config.endpoints.api.course;

  const watchCourse = watch("module_id");

  const { fetchedData, fetchNewData } = useFetch();

  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<any[]>([]);

  const [selectedCourse, setSelectedCourse] = useState(
    editform && data.module_id
  );

  const [selectedSubject, setSelectedSubject] = useState(
    editform && data.subject_id
  );

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  const convertToSubjectOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.subject_id,
        label: item.subject_name
      };
    });
    return options;
  };

  useEffect(() => {
    async function fetchCourses() {
      let courses = await http.GET(courseApi.list);

      if (courses?.data?.data?.length) {
        let options = convertToOptions(courses.data.data);
        setCourseOptions(options);
        setValue("course", options[0].value);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
        }
      } else {
        setCourseOptions([]);
        setValue("course", undefined);
      }
    }
    fetchCourses();
  }, [courseApi, data, editform, setValue]);

  useEffect(() => {
    watchCourse && fetchNewData(subjectApi.list(watchCourse));
  }, [fetchNewData, watchCourse]);

  useEffect(() => {
    if (fetchedData.data.length) {
      let options = convertToSubjectOptions(fetchedData.data);
      setSubjectOptions(options);

      if (editform) {
        let subject = options.find(item => {
          if (item.value === data?.subject_id) return item;
          return null;
        });
        setSelectedSubject(subject);
      }
    } else {
      setSubjectOptions([]);
    }
  }, [fetchedData, editform, data?.subject_id]);

  const handleReset = () => {
    reset({
      unit_name: "",
      logo_id: "",
      description: "",
      module_id: null,
      subject_id: null
    });
  };

  const handleClear = () => {
    handleReset();
    setSelectedCourse(null);
    setSelectedSubject(null);

    setImage("");
  };

  return (
    <form
      className="unit-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="unit_image">
              <ImageInput
                image={image}
                editform={editform}
                setImage={setImage}
                {...register("logo_id", {
                  required: false
                })}
              />
            </div>

            <div className="col-container">
              <div className="row-container">
                <div className="fieldAndValidate">
                  <label>
                    Course <span>*</span>
                  </label>
                  <CustomSelect
                    id="module_id"
                    required={true}
                    register={register}
                    name="module_id"
                    placeholder="Select Course"
                    value={selectedCourse || ""}
                    handleChange={data => {
                      setSelectedCourse(data);
                      setValue("module_id", data.value);
                    }}
                    options={courseOptions}
                    disabled={false}
                  />
                  {errors?.module_id?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
                <div className="fieldAndValidate">
                  <label>
                    Subject <span>*</span>
                  </label>
                  <CustomSelect
                    id="subject_id"
                    required={true}
                    register={register}
                    name="subject_id"
                    placeholder="Select Subject"
                    value={selectedSubject || ""}
                    handleChange={data => {
                      setSelectedSubject(data);
                      setValue("subject_id", data.value);
                    }}
                    options={subjectOptions}
                    disabled={false}
                  />
                  {errors?.subject_id?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              </div>
              <div className="fieldAndValidate">
                <InputField
                  required
                  type="text"
                  label="Unit Name"
                  placeholder={"Enter the unit name"}
                  {...register("unit_name", {
                    required: true
                  })}
                />
                {errors?.unit_name?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
            </div>
          </div>
          <div className="fieldAndValidate">
            {/* <Textarea
              label="Description"
              rows={4}
              placeholder="Enter course description"
              required={true}
              {...register("description")}
            /> */}
            <RichTextarea
              required
              control={control}
              name="description"
              label="Description"
              editform={editform}
              placeholder={"Enter the unit description"}
            />
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            clickHandler={() => {}}
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading ? "Submitting" : editform ? "Update Unit" : "Add Unit"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => handleCancel()}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default UnitForm;
