import React, { useState, useEffect } from "react";

import http from "../../utils/http";
import config from "../../config";

import "./Quiz.scss";
import QuizDetails from "./components/QuizDetails";
import QuizSchedule from "./components/QuizSchedule";
import Loader from "../../components/Preloader/Loader";
import QuizResult from "./components/QuizResult";
import QuizForm from "./components/QuizForm";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

import { toast } from "react-toastify";

function Quiz() {
  const [selectedCourse, setSelectedCourse] = useState();

  let { moduleId, url } = {
    moduleId: selectedCourse,
    url: `${config.baseURI}/api/v1/${selectedCourse}`
  };

  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [tests, setTests] = useState(null);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("all");
  const [moreTest, setMoreTest] = useState(true);
  const [activeTest, setActiveTest] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showQuizForm, setShowQuizForm] = useState(false);
  const [refreshTests, setRefreshTests] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTestData, setDeleteTestData] = useState();
  const [editform, setEditform] = useState(false);
  const [updateData, setUpdateData] = useState();

  const activeId = activeTest && activeTest.id;
  const fetchTests = (offset, filter) => {
    return http
      .GET(
        `${url}/teacher/test?limit=${limit}&offset=${offset}$filter=${filter}`
      )
      .then(res => {
        let tests = res.data.tests;
        tests && tests.length < limit ? setMoreTest(false) : setMoreTest(true);
        return tests;
      })
      .catch(e => {
        setError("Error Loading quizzes");
        return null;
      });
  };

  const duplicateTest = testId => {
    return http
      .POST(`${url}/teacher/test/duplicate`, { testId })
      .then(res => {
        setRefreshTests(pre => !pre);
        toast.success("Quiz duplicated as draft!");
      })
      .catch(error => {
        setError("Error Duplicating quiz!");
        return null;
      });
  };

  const handleDelete = test => {
    setShowDeleteModal(true);
    setDeleteTestData(test);
  };

  const deleteTest = testId => {
    http
      .REMOVE(`${url}/teacher/test?testId=${testId}`)
      .then(res => {
        toast.success("Quiz deleted successfully!");
        setRefreshTests(pre => !pre);
      })
      .catch(e => {
        toast.success("Error deleting quiz!");
      });
  };

  const showMoreTest = () => {
    setLoadingMore(true);
    setOffset(offset + limit);
    fetchTests(offset + limit, filter).then(newTests => {
      newTests && setTests([...tests, ...newTests]);
      setLoadingMore(false);
    });
  };

  const filterChanged = filter => {
    setTests(null);
    setFilter(filter);
    setMoreTest(true);
    setOffset(0);
    fetchTests(0, filter).then(tests => {
      setTests(tests);
      tests && tests[0] && setActiveTest(tests[0]);
    });
  };

  useEffect(() => {
    fetchTests(offset, filter).then(tests => {
      setTests(tests);
      tests && tests[0] && setActiveTest(tests[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, refreshTests, filter, selectedCourse]);

  return (
    <div className="row test_page">
      <div className="col-md-5 mb-2 px-0 schedule_container">
        <QuizSchedule
          tests={tests}
          moreTest={moreTest}
          filter={filter}
          filterChanged={filterChanged}
          showMoreTest={showMoreTest}
          setActiveTest={setActiveTest}
          loadingMore={loadingMore}
          handleDelete={handleDelete}
          duplicateTest={duplicateTest}
          error={error}
          editLink={`/${moduleId}/teacher/test/setup`}
          activeId={activeId}
          setShowQuizForm={setShowQuizForm}
          setEditform={setEditform}
          setUpdateData={setUpdateData}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
        />
      </div>
      {showQuizForm ? (
        <div className="col-md-7 result_container">
          <div className="add_quiz_form">
            <QuizForm
              data={updateData}
              editform={editform}
              setShowQuizForm={setShowQuizForm}
              setRefreshTests={setRefreshTests}
            />
          </div>
        </div>
      ) : (
        <div className="col-md-7 result_container">
          <div className="result_header">
            <Loader loading={activeTest}>
              <QuizDetails
                activeTest={activeTest}
                selectedCourse={selectedCourse}
              />
            </Loader>
          </div>

          <div className="result_body">
            {activeTest && (
              <QuizResult
                url={url}
                testId={activeTest.id}
                selectedCourse={selectedCourse}
              />
            )}
          </div>
        </div>
      )}
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={deleteTestData?.id}
        module_id={"demo"}
        name={deleteTestData?.name}
        handleDelete={deleteTest}
      />
    </div>
  );
}

export default Quiz;
