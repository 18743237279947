import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";
import Notification from "../pages/Notification/Notification";
import AddNotification from "../pages/Notification/AddNotification";

import { getAccessToken } from "../services/token";

export default function NotificationRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Notification />} />
      <Route path={PATH.ADD_NOTIFICATION} element={<AddNotification />} />
    </Routes>
  );
}
