import React, { useEffect, useState } from "react";
import { Empty, Segmented } from "antd";
import { Spin } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import config from "../../../config";
import httpMethods from "../../../utils/http";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: { intersect: false },
  plugins: {
    legend: {
      display: false
    },
    title: { display: true, text: "Users Trends" }
  },
  layout: {
    padding: {
      bottom: 30
    }
  },
  scales: {
    x: {
      grid: { display: false }
    },
    y: {
      grid: { display: false }
    }
  }
};
interface UserProps {
  userTrendsDaily: {
    date: string;
    users: number;
  }[];
  userTrendsMonthly: {
    date: string;
    users: number;
  }[];
}

const UserTrendChart = () => {
  const [userValue, setUserValue] = useState<UserProps>({
    userTrendsDaily: [],
    userTrendsMonthly: []
  });
  const apiUrl = `${config.baseURI}/api/v1/count/userTrendCount`;

  useEffect(() => {
    httpMethods.GET(apiUrl).then(response => {
      let resData = response.data.data;
      setUserValue(resData);
    });
  }, []);

  const [selectedDuration, setSelectedDuration] = useState<string>("");
  const handleSelectDuration = (value: string) => {
    setSelectedDuration(value);
  };

  if (!userValue) {
    return <Spin tip="Loading" size="large" />;
  }

  let monthlyUsersData =
    userValue?.userTrendsDaily?.length > 0
      ? userValue?.userTrendsDaily?.map(item => item.users)
      : "No weekly data available";
  let yearlyUsersData =
    userValue?.userTrendsMonthly?.length > 0
      ? userValue?.userTrendsMonthly?.map(item => item.users)
      : "No Monthly data available";

  let labels: any, data: any;

  switch (selectedDuration) {
    case "Daily":
      labels = userValue?.userTrendsDaily?.map(item => item.date);
      data = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Users",
            data: monthlyUsersData,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)"
          }
        ]
      };
      break;

    case "Monthly":
      labels = userValue?.userTrendsMonthly?.map(item => item.date);
      data = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Users",
            data: yearlyUsersData,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)"
          }
        ]
      };
      break;

    default:
      labels = userValue?.userTrendsDaily?.map(item => item.date);
      data = {
        labels,
        datasets: [
          {
            fill: true,
            label: "Users",
            data: monthlyUsersData,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)"
          }
        ]
      };
      break;
  }

  return (
    <>
      <Segmented
        options={["Daily", "Monthly"]}
        onChange={handleSelectDuration}
      />
      {labels?.length > 0 ? (
        <Line options={options} data={data} />
      ) : (
        <Empty description="No Records" />
      )}
    </>
  );
};

export default UserTrendChart;
