import React, { useState, useEffect } from "react";

import moment from "moment";
import { useForm } from "react-hook-form";

import config from "../../../config";
import http from "../../../utils/http";
import toast from "../../../utils/toast";
import useFetch from "../../../hooks/useFetch";
import { getDateTimeAfterYears } from "../../../utils/getDateTimeNow";

import "./QuizForm.scss";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import CustomSelect from "./../../../components/CustomSelect/CustomSelect";

type optionType = {
  value: string | number;
  label: string;
};

type optionSeriesType = {
  value: string | number;
  label: string;
  isQuestionSet: boolean;
};

let courseApi = config.endpoints.api.course;
let testSeriesApi = config.endpoints.api.testseries;

function QuizForm({ data, editform, setRefreshTests, setShowQuizForm }) {
  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  let isPremium = Boolean(watch("is_premium"));
  let watchCourse = watch("module_id");

  let { url } = {
    url: `${config.baseURI}/api/v1/${watchCourse}`
  };

  const handleClear = () => {
    setSelectedCourse(null);
    setSelectedQuizType(null);
    setSelectedTestSeries(null);
    reset({
      end: "",
      name: "",
      type: "",
      start: "",
      mark: null,
      total: null,
      is_premium: 0,
      module_id: "",
      duration: null,
      testSeriesId: null
    });
  };

  const [coursesOptions, setCoursesOptions] = useState<optionType[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<optionType | null>();

  const [testSeriesOptions, setTestSeriesOptions] = useState<optionSeriesType[]>([]);
  const [selectedTestSeries, setSelectedTestSeries] =
    useState<optionSeriesType | null>();

  const [quizTypeOptions, setQuizTypeOptions] = useState<optionType[]>([]);
  const [selectedQuizType, setSelectedQuizType] = useState<optionType | null>();

  const [premiumOptions, setPremiumOptions] = useState<optionType[]>([]);
  const [premium, setPremium] = useState<optionType | null>();

  const { fetchedData: fetchedCourse, fetchNewData: fetchNewCourse } =
    useFetch();

  const { fetchedData: fetchedTestSeries, fetchNewData: fetchNewTestSeries } =
    useFetch();

  const convertToOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  const convertToSeriesOptions = lists => {
    let options = lists.map(item => {
      return {
        value: item.id,
        label: item.title,
        isQuestionSet: item.is_questionSet
      };
    });

    options = [
      {
        value: null,
        label: "None",
        isQuestionSet: false
      },
      ...options
    ];
    return options;
  };

  useEffect(() => {
    watchCourse && fetchNewTestSeries(testSeriesApi.listData(watchCourse));
  }, [fetchNewTestSeries, watchCourse]);

  useEffect(() => {
    if (fetchedTestSeries.data) {
      if (fetchedTestSeries.data.length) {
        let options = convertToSeriesOptions(fetchedTestSeries.data);
        setTestSeriesOptions(options);

        if (editform) {
          let testSeries = options.find(item => {
            if (item.value === data?.testSeriesId) return item;
            return null;
          });
          setSelectedTestSeries(testSeries);
          setValue("testSeriesId", testSeries?.value);
        }
      } else {
        setTestSeriesOptions([]);
        setValue("testSeriesId", null);
      }
    }
  }, [fetchedTestSeries, setValue, data, editform]);

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCoursesOptions(options);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
          setValue("module_id", course?.value);
        }
      } else {
        setCoursesOptions([]);
        setValue("module_id", undefined);
      }
    }
  }, [fetchedCourse, setValue, data, editform]);

  const handleSelectChange = (data, e) => {
    setSelectedCourse(data);
    setValue("module_id", data.value);
  };

  const handleSelectSeriesChange = (data, e) => {
    setSelectedTestSeries(data);
    setValue("testSeriesId", data.value);
  };

  const handleQuizTypeChange = (data, e) => {
    setSelectedQuizType(data);
    setValue("type", data.value);
  };

  const handlePremiumChange = (data, e) => {
    setPremium(data);
    setValue("is_premium", data.value);
  };

  useEffect(() => {
    reset(data);

    const handleClear = () => {
      setSelectedCourse(null);
      setSelectedQuizType(null);
      setSelectedTestSeries(null);
      reset({
        end: "",
        name: "",
        type: "",
        start: "",
        mark: null,
        total: null,
        is_premium: "",
        module_id: "",
        duration: null,
        testSeriesId: null
      });
    };

    const quizTypes = [
      { value: "daily", label: "Daily Test" },
      { value: "weekly", label: "Weekly Test" },
      { value: "monthly", label: "Monthly Test" },
      { value: "instant", label: "Instant Test" },
      { value: "comprehensive2024", label: "Test Series 2024" }
    ];

    const premiumValues = [
      {
        value: 0,
        label: "False"
      },
      {
        value: 1,
        label: "True"
      }
    ];

    setValue("is_premium", 0);
    setQuizTypeOptions(quizTypes);
    setPremiumOptions(premiumValues);

    if (editform) {
      let type = quizTypes.find(item => {
        if (item.value === data?.type) return item;
        return null;
      });
      setSelectedQuizType(type);

      let premium = premiumValues.find(item => {
        let premiumValue = data.is_premium ? 1 : 0;
        if (item.value === premiumValue) return item;
        return null;
      });
      setPremium(premium);
      setValue("is_premium", premium?.value);

      setValue("start", moment(data.start).format("YYYY-MM-DD HH:mm"));
      setValue("end", moment(data.end).format("YYYY-MM-DD HH:mm"));
    } else {
      handleClear();
    }
  }, [setValue, editform, data, reset]);

  const handleClickSubmit = test => {
    
    test.end = test.end ? moment(test.end).utc() : moment.utc();
    test.start =test.start ? moment(test.start).utc() : moment.utc();    

    if (editform) delete test.created_at;

    http.POST(`${url}/teacher/test`, test).then(res => {
      handleClear();
      setShowQuizForm(pre => !pre);
      setRefreshTests(pre => !pre);
      toast.success(
        editform
          ? "Quiz Updated Successfully"
          : "Quiz Draft Created Successfully"
      );
    });
  };

  return (
    <div className="quiz_form_container">
      <div className="bg-white quiz_form_header">
        <div className="flex-column quiz_form_title">
          <h6>{data ? `Edit ${data.name} Details` : "Add Quiz Details"}</h6>
          <p className="text-muted">
            Please fill in the required details for the Quiz.
          </p>
        </div>
        <div className="back_button">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() => {
              setShowQuizForm(pre => !pre);
            }}
          />
        </div>
      </div>

      <form className="quiz_form" onSubmit={handleSubmit(handleClickSubmit)}>
        <div className="row-container quiz_inputs">
          <div className="col-container">
            <div className="row-container">
              <div className="fieldAndValidate course">
                <label htmlFor="course">
                  Course <span>*</span>
                </label>
                <CustomSelect
                  id="module_id"
                  required={true}
                  name="module_id"
                  disabled={false}
                  register={register}
                  value={selectedCourse}
                  options={coursesOptions}
                  handleChange={(e, data) => {
                    handleSelectChange(e, data);
                  }}
                />
                {errors?.module_id?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
              <div className="fieldAndValidate course">
                <label htmlFor="type">
                  Type <span>*</span>
                </label>
                <CustomSelect
                  id="type"
                  name="type"
                  required={true}
                  register={register}
                  value={selectedQuizType}
                  handleChange={(e, data) => {
                    handleQuizTypeChange(e, data);
                  }}
                  options={quizTypeOptions}
                  disabled={false}
                />
                {errors?.type?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
            </div>
            <div className="row-container">
              <div className="fieldAndValidate course">
                <label htmlFor="testSeriesId">Test Series</label>
                <CustomSelect
                  id="testSeriesId"
                  required={false}
                  name="testSeriesId"
                  disabled={false}
                  register={register}
                  value={selectedTestSeries}
                  options={testSeriesOptions}
                  handleChange={(e, data) => {
                    handleSelectSeriesChange(e, data);
                  }}
                />
                {errors?.testSeriesId?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
            </div>
            <div className="row-container">
              <div className="fieldAndValidate title">
                <InputField
                  required
                  type="text"
                  label="Quiz Title"
                  placeholder={"Enter the title of the quiz"}
                  {...register("name", {
                    required: true
                  })}
                />
                {errors?.name?.type === "required" && (
                  <p className="error">This field is required</p>
                )}
              </div>
              <div className="fieldAndValidate course">
                <label htmlFor="is_premium">
                  Is Premium <span>*</span>
                </label>
                <CustomSelect
                  id="is_premium"
                  value={premium}
                  required={true}
                  disabled={false}
                  name="is_premium"
                  register={register}
                  options={premiumOptions}
                  handleChange={(e, data) => {
                    handlePremiumChange(e, data);
                  }}
                />
                {errors?.is_premium?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
            </div>
            <div className="row-container">
              <div className="fieldAndValidate">
                <InputField
                  required
                  type="number"
                  min="0"
                  label="Duration (Minutes)"
                  placeholder={"Enter the duration of the quiz"}
                  {...register("duration", {
                    required: true
                  })}
                />
                {errors?.duration?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
              {
                <div className="fieldAndValidate title">
                  <InputField
                    disabled={isPremium && true}
                    type="number"
                    min="0"
                    label="Quiz Price"
                    placeholder={
                      isPremium
                        ? "**Can't set price for premium quiz"
                        : "Enter the price for the non premium quiz"
                    }
                    {...register("price", {
                      required: true
                    })}
                  />
                  {errors?.price?.type === "required" && (
                    <p>This field is required</p>
                  )}
                </div>
              }
            </div>
            {!selectedTestSeries?.isQuestionSet && <div className="row-container">
              <div className="fieldAndValidate date">
                <InputField
                  label="Start Time"
                  type="datetime-local"
                  {...register("start", {
                    required: true
                  })}
                  max={getDateTimeAfterYears(1)}
                  min={getDateTimeAfterYears(-1)}
                />
                {errors?.start?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
              <div className="fieldAndValidate date">
                <InputField
                  label="End Time"
                  type="datetime-local"
                  {...register("end", {
                    required: true
                  })}
                  max={getDateTimeAfterYears(1)}
                  min={getDateTimeAfterYears(-1)}
                />
                {errors?.end?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
            </div>}

            <div className="row-container">
              <div className="fieldAndValidate">
                <InputField
                  required
                  type="number"
                  label="Total Questions"
                  min="0"
                  placeholder={"Enter no of questions"}
                  {...register("total", {
                    required: true
                  })}
                />
                {errors?.total?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
              <div className="fieldAndValidate">
                <InputField
                  required
                  type="number"
                  min="0"
                  label="Full Marks"
                  placeholder={"Enter the full marks"}
                  {...register("mark", {
                    required: true
                  })}
                />
                {errors?.mark?.type === "required" && (
                  <p>This field is required</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row-container">
          <div className="button-wrapper">
            {!editform && (
              <Button
                type="button"
                color="danger"
                buttonName="Clear"
                clickHandler={() => handleClear()}
              />
            )}
            {editform && (
              <Button
                type="button"
                color="danger"
                buttonName="Cancel"
                clickHandler={() => setShowQuizForm(false)}
              />
            )}
            <Button
              type="submit"
              color="success"
              buttonName={editform ? "Update Quiz" : "Add Quiz"}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default QuizForm;
