import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button/Button";
import NotificationForm from "../Notification/components/notificationForm/NotificationForm";

import "./AddNotification.scss";
import addNotification from "./sendNotification";

const AddNotification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = data => {
    addNotification(data, navigate, setIsLoading);
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Notification | Add Notification</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            color="success"
            buttonName="< Back"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_notification_form">
        <NotificationForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AddNotification;
