import React from "react";
import emptyImage from "../../assets/images/empty.png";
import searching from "../../assets/images/searching.png";
import errorImage from "../../assets/images/error.png";
import "./ErrorPages.scss";

function ErrorPages({ isFetching, data, error }) {
  let show = isFetching || !data || error;
  return (
    <div className={`container ${show && "show"}`}>
      <div className="contents">
        {isFetching && !data && !error && (
          <>
            <img
              src={searching}
              alt="no-data"
              width="75px"
              style={{ marginTop: "6em" }}
            />
            <h5>
              <p style={{ fontWeight: 600 }}>Requesting . . .</p>
            </h5>
          </>
        )}

        {!isFetching && !data && !error && (
          <>
            <img src={emptyImage} alt="no-data" width="300px" />
            <h5>
              <p style={{ fontWeight: 600 }}>No data to show currently.</p>
            </h5>
          </>
        )}

        {error && !data && (
          <>
            <img
              src={errorImage}
              alt="no-data"
              width="340px"
              style={{ marginTop: "2em" }}
            />
            <h5>
              <strong>
                Oops! Something went wrong. Please refresh or try again later!
              </strong>
            </h5>
          </>
        )}
      </div>
    </div>
  );
}

export default ErrorPages;
