import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { PATH } from "../../../constants/routes";

import config from "../../../config";
import useFetch from "../../../hooks/useFetch";

import "./QuizSchedule.scss";
import Tag from "../../../components/Tag/Tag";
import testStatus from "../../../utils/testStatus";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Preloader/Loader";

let courseApi = config.endpoints.api.course;

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function QuizSchedule({
  tests,
  showMoreTest,
  filterChanged,
  loadingMore,
  handleDelete,
  moreTest,
  setActiveTest,
  activeId,
  error,
  editLink,
  duplicateTest,
  setShowQuizForm,
  setEditform,
  setUpdateData,
  selectedCourse,
  setSelectedCourse
}) {
  let currentDate = moment.utc();
  let history = useNavigate();

  const { fetchedData: fetchedCourse, fetchNewData: fetchNewCourse } =
    useFetch();

  const [courseOptions, setCourseOptions] = useState([]);

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        setCourseOptions(fetchedCourse.data);
        setSelectedCourse(fetchedCourse.data[0].module_id);
      }
    }
  }, [fetchedCourse, setSelectedCourse]);

  return (
    <div className="test_container bg-white">
      <div className="add_quiz_button">
        <Button
          buttonName={"✚ Add New Quiz"}
          color="success"
          type="button"
          clickHandler={() => {
            setEditform(false);
            setShowQuizForm(true);
          }}
        />
      </div>

      <div className="all_tests_header mb-3">
        <h6 className="py-2" style={{ fontWeight: 600 }}>
          Test Schedule
        </h6>
        <div className="test_filters">
          <form>
            <select
              onChange={e => {
                setSelectedCourse(e.target.value);
              }}
              className="custom-select"
            >
              {courseOptions.map(each => {
                return (
                  <option key={each.module_id} value={each.module_id}>
                    {each.module_id.toUpperCase()}
                  </option>
                );
              })}
            </select>
            {/* <select
              defaultValue="all"
              onChange={e => {
                filterChanged(e.target.value);
              }}
              className="custom-select"
            >
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="scheduled">Scheduled</option>
              <option value="completed">Completed</option>
            </select> */}
          </form>
        </div>
      </div>

      <div className="tests_list">
        <Loader error={error} loading={tests} length={tests && tests.length}>
          {tests &&
            tests.map((test, index) => (
              <SingleSchedule
                test={test}
                activeId={activeId}
                setActiveTest={setActiveTest}
                index={index}
                key={index}
                handleDelete={handleDelete}
                editLink={editLink}
                currentDate={currentDate}
                history={history}
                duplicateTest={duplicateTest}
                setShowQuizForm={setShowQuizForm}
                setEditform={setEditform}
                setUpdateData={setUpdateData}
                selectedCourse={selectedCourse}
              />
            ))}
        </Loader>
      </div>

      <Button
        buttonName={loadingMore ? "Loading.." : "⮟ Show More"}
        disabled={!moreTest}
        color="success"
        type="button"
        clickHandler={() => showMoreTest()}
      />
    </div>
  );
}

const SingleSchedule = ({
  test,
  activeId,
  currentDate,
  setActiveTest,
  handleDelete,
  index,
  editLink,
  history,
  duplicateTest,
  setShowQuizForm,
  setEditform,
  setUpdateData,
  selectedCourse
}) => {
  const { duration, id, end, start, mark, total, name, type, is_draft, isQuestionSet } = test;
  let startDate = moment.utc(start);
  let endDate = moment.utc(end);
  let testData = { ...test };
  delete testData.id;
  let active = activeId === id;
  let { status } = testStatus(currentDate, startDate, endDate, isQuestionSet);

  const navigate = useNavigate();

  const navigateToPreview = () => {
    navigate(PATH.PREVIEW_QUIZ, {
      state: {
        testId: id,
        selectedCourse
      }
    });
  };

  return (
    <div
      key={index}
      onClick={() => {
        setActiveTest(test);
        setShowQuizForm(false);
      }}
      className={`singleQuiz p-3 mb-2 ${active && "active"}`}
    >
      <div className="quiz_header">
        <h5 className="quiz_name">{name}</h5>
        <div className="status_tag">
          <Tag
            text={(is_draft && "Draft") || status || "Unknown"}
            color={
              is_draft
                ? "grey"
                : status === "Expired"
                ? "red"
                : status === "Active"
                ? "green"
                : status === "Scheduled"
                ? "orange"
                : "black"
            }
          />
        </div>
      </div>

      <ul className="description list-inline text-muted">
        <li className="list-inline-item">
          <i className="far fa-clock" />
          <span>{duration} Mins</span>
        </li>
        <li className="list-inline-item">
          <i className="fas fa-bullseye" />
          <span>{mark} Marks</span>
        </li>
        <li className="list-inline-item">
          <i className="fas fa-desktop" />
          <span>{total} Questions</span>
        </li>
        <li className="list-inline-item">
          <i className="fas fa-users" />
          <span>{capitalize(type)}</span>
        </li>
      </ul>

      <div className="column d-flex justify-content-between">
        <div className="dates">
          <small className="text-muted d-block p-0">
            <span>Start: </span>
            {moment(start).local().format("LLL")}
          </small>
          <small className="text-muted">
            <span>End: </span>
            {moment(end).local().format("LLL")}
          </small>
        </div>

        <div className="action_buttons">
          <div onClick={() => navigateToPreview()}>
            <i className="fas fa-eye" title="Preview Test"></i>
          </div>

          <div onClick={() => duplicateTest(test.id)}>
            <i className="fas fa-clone" title="Duplicate Test" />
          </div>

          <div
            onClick={e => {
              setActiveTest(test);
              setEditform(true);
              setUpdateData(test);
              setShowQuizForm(true);
              e.stopPropagation();
            }}
          >
            <i className="fas fa-edit" title="Edit Test Details" />
          </div>

          <div onClick={() => handleDelete(test)}>
            <i className="fas fa-trash" title="Delete Test" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizSchedule;
