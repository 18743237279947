import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import DashboardAnalytics from "../pages/Dashboard/analytics/DashboardAnalytics";

import { PATH } from "../constants/routes";

import UserRoutes from "./UserRoutes";
import InstructorRoutes from "./InstructorRoutes";
import CourseRoutes from "./CourseRoutes";
import CourseGroupRoutes from "./CourseGroupRoutes";
import SubjectsRoutes from "./SubjectRoutes";
import UnitsRoutes from "./UnitRoutes";
import ProfileRoutes from "./ProfileRoutes";
import ContentRoutes from "./ContentRoute";
import LoginRoutes from "./LoginRoutes";
import SubsRoutes from "./SubscriptionRoute";
import ReferralRoutes from "./ReferralRoutes";
import NotifRoutes from "./NotificationRoute";
import FeedbackRoutes from "./FeedbackRoutes";
import PaymentLogRoutes from "./PaymentLogRoutes";
import QuizRoutes from "./QuizRoutes";
import LiveRoutes from "./LiveRoutes";
import GroupRoutes from "./GroupRoute";
import PremiumWithDataRoutes from "./PremiumWithData";
import TestSeriesRoute from "./TestSeries";

export default function BaseRoutes() {
  return (
    <Routes>
      {/* //public routes */}
      <Route path={PATH.LOGIN + "/*"} element={<LoginRoutes />} />

      {/* private routes */}
      <Route element={<PrivateRoute />}>
        <Route path={PATH.DASHBOARD} element={<DashboardAnalytics />} />
        <Route path={PATH.USER + "/*"} element={<UserRoutes />} />
        <Route path={PATH.INSTRUCTOR + "/*"} element={<InstructorRoutes />} />
        <Route path={PATH.SUBJECT + "/*"} element={<SubjectsRoutes />} />
        <Route
          path={PATH.COURSE_GROUP + "/*"}
          element={<CourseGroupRoutes />}
        />
        <Route path={PATH.COURSE + "/*"} element={<CourseRoutes />} />
        <Route path={PATH.UNIT + "/*"} element={<UnitsRoutes />} />
        <Route path={PATH.CHAPTER + "/*"} element={<ContentRoutes />} />
        <Route path={PATH.PROFILE + "/*"} element={<ProfileRoutes />} />
        <Route path={PATH.SUBSCRIPTION + "/*"} element={<SubsRoutes />} />
        <Route path={PATH.REFERRAL + "/*"} element={<ReferralRoutes />} />
        <Route path={PATH.LIVE + "/*"} element={<LiveRoutes />} />
        <Route path={PATH.GROUP + "/*"} element={<GroupRoutes />} />
        <Route path={PATH.NOTIFICATION + "/*"} element={<NotifRoutes />} />
        <Route path={PATH.FEEDBACK + "/*"} element={<FeedbackRoutes />} />
        <Route path={PATH.PAYMENTLOG + "/*"} element={<PaymentLogRoutes />} />
        <Route path={PATH.QUIZ + "/*"} element={<QuizRoutes />} />
        <Route path={PATH.TESTSERIES + "/*"} element={<TestSeriesRoute />} />
        <Route
          path={PATH.PREMIUM_WITH_DATA + "/*"}
          element={<PremiumWithDataRoutes />}
        />
      </Route>

      {/* fallback route */}
      <Route path="*" element={<Navigate to={PATH.DASHBOARD} />} />
    </Routes>
  );
}
