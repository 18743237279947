import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import config from "../../config";
import http from "../../utils/http";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Button from "../../components/Button/Button";

import "./QuizPreview.scss";

function TeacherUserResult({ location }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const testId = state.testId;
  const selectedCourse = state.selectedCourse;

  let { url } = {
    moduleId: selectedCourse,
    url: `${config.baseURI}/api/v1/${selectedCourse}`
  };

  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    http.GET(`${url}/teacher/test/question?testId=${testId}`).then(res => {
      setTitle(res.data.test.name);
      setQuestions(res.data.test.questions || []);
    });
  }, [url, testId]);

  return (
    <div className="row">
      <div className="page_header">
        <h4 className="page_title">{`Preview for ${title}`}</h4>
        <div className="button_wrapper ">
          <Button
            buttonName="< Back"
            color="success"
            type="button"
            clickHandler={() => {
              navigate("../");
            }}
          />
        </div>
      </div>
      {questions ? (
        <div className="preview_body">
          {questions.length !== 0 ? (
            questions.map((question, index) => (
              <ResultQuestionCard
                key={question.id}
                question={question}
                count={questions.length}
                index={index + 1}
              />
            ))
          ) : (
            <>Loading the questions...</>
          )}
        </div>
      ) : (
        <>nothing</>
      )}
    </div>
  );
}

export default TeacherUserResult;

function ResultQuestionCard({ question, count, index }) {
  let { answer } = question;

  return (
    <div className="card p-4 mb-4">
      {question && (
        <form style={{ border: "none" }}>
          <div className="test-question">
            <h5>
              <strong>
                Question {index} of {count}
              </strong>
            </h5>
            <br />
            <h5 className="card-title pb-2">
              <ReactMarkdown
                children={question.question}
                rehypePlugins={[rehypeRaw]}
              />
            </h5>
            <p>
              <strong>Choose the correct answer below : &nbsp;</strong>
            </p>
          </div>
          <div className="row">
            <div className="col">
              <ReactMarkdown
                children={`<b>A.</b> ${question.optionA}`}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
            <div className="col">
              <ReactMarkdown
                children={`<b>B.</b> ${question.optionB}`}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <ReactMarkdown
                children={`<b>C.</b> ${question.optionC}`}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
            <div className="col">
              <ReactMarkdown
                children={`<b>D.</b> ${question.optionD}`}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </div>

          <br />
          <div className="boxed">
            <div className="d-flex">
              <strong>ANSWER : </strong>
              &emsp;
              <ReactMarkdown
                children={question[`option${answer.trim().toUpperCase()}`]}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
            <div>
              <strong>Description : </strong>
              &emsp;
              <ReactMarkdown
                children={question.description}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
