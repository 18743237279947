import React from "react";
import { Routes, Route } from "react-router-dom";
import Profile from "../pages/Profile/Profile";
import { PATH } from "../constants/routes";
import EditProfile from "../pages/Profile/EditProfile";
import ProfileSidebar from "../pages/Profile/ProfileSidebar";
import { Navigate } from "react-router-dom";

export default function ProfileRoutes() {
  return (
    <Routes>
      <Route element={<ProfileSidebar />}>
        <Route index element={<Profile />} />
        <Route path={PATH.SETTING} element={<EditProfile />} />
      </Route>
      <Route path="*" element={<Navigate to={`/${PATH.PROFILE}`} />} />
    </Routes>
  );
}
