import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import SubjectForm from "./components/SubjectForm/SubjectForm";

import Button from "../../components/Button/Button";
import http from "../../utils/http";
import toast from "../../utils/toast";
import uploadImage from "../../utils/uploadImage";

import "./AddSubject.scss";

import config from "../../config";
let subjectApi = config.endpoints.api.subject;

export default function AddSubject() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickSubmit = data => {
    setIsLoading(true);

    let image = data.thumbnail;
    delete data.thumbnail;

    http
      .POST(subjectApi.create(data.module_id), data)
      .then(async res => {
        if (image?.length) {
          await uploadImage(
            image[0],
            data.subject_name.toLowerCase(),
            `subjectLight/icons/${data.module_id}`
          );
        }
        toast.success("Subject Added Successfully!");
        setTimeout(() => {
          navigate("../");
        }, 1000);
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="page_header">
        <h4 className="page_title">Subjects | Add Subject</h4>

        <div className="button_wrapper ">
          <Button
            type="button"
            buttonName="< Back"
            color="success"
            clickHandler={() => navigate("../")}
          />
        </div>
      </div>

      <div className="add_subject_form">
        <SubjectForm
          editform={false}
          handleClickSubmit={data => handleClickSubmit(data)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}
