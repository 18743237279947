import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PATH } from "../constants/routes";
import Referral from "../pages/Referral/Referral";
import AddReferral from "../pages/Referral/AddReferral";

import { getAccessToken } from "../services/token";

export default function ReferralRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const access = getAccessToken();
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route index element={<Referral />} />
      <Route path={PATH.ADD_REFERRAL} element={<AddReferral />} />
    </Routes>
  );
}
