import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import config from "../../../../config";
import toasts from "../../../../utils/toast";
import useFetch from "../../../../hooks/useFetch";

import Button from "../../../../components/Button/Button";
import InputField from "../../../../components/InputField/InputField";
import CustomSelect from "./../../../../components/CustomSelect/CustomSelect";

import "./SubscriptionForm.scss";
import "react-toastify/dist/ReactToastify.css";

let courseApi = config.endpoints.api.course;
type courseType = {
  label: string;
  value: string;
};

type PropsType = {
  data?: {
    id: number;
    title: string;
    module_id: string;
    period: number;
    amount: number;
    discount: number;
  };
  editform?: boolean | any;
  handleCancel?: Function | any;
  handleClickSubmit?: Function | any;
  handleClickUpdate?: Function | any;
  isLoading?: boolean;
};

const SubscriptionForm = ({
  data,
  editform,
  handleCancel,
  handleClickSubmit,
  handleClickUpdate,
  isLoading
}: PropsType) => {
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm(
    editform && {
      defaultValues: {
        id: data && data?.id,
        title: data && data?.title,
        module_id: data && data?.module_id,
        period: data && data?.period,
        amount: data && data?.amount,
        discount: data && data?.discount
      }
    }
  );

  const handleReset = () => {
    setSelectedCourse(undefined);
    setValue("period", undefined);
    setValue("amount", undefined);
    setValue("discount", undefined);
    reset({
      title: "",
      module_id: ""
    });
  };

  const handleClear = () => {
    handleReset();
  };

  const handleSelectChange = (data, e) => {
    setSelectedCourse(data);
    setValue("module_id", data.value);
  };

  const {
    error: courseError,
    fetchedData: fetchedCourse,
    fetchNewData: fetchNewCourse
  } = useFetch();

  courseError && toasts.error("Something went wrong!");

  const [coursesOptions, setCourseOptions] = useState<courseType[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<courseType | null>();

  const convertToOptions = lists => {
    let options: { value: string; label: string }[] = lists.map(item => {
      return {
        value: item.module_id,
        label: item.module_id.toUpperCase()
      };
    });
    return options;
  };

  useEffect(() => {
    fetchNewCourse(courseApi.list);
  }, [fetchNewCourse]);

  useEffect(() => {
    if (fetchedCourse.data) {
      if (fetchedCourse.data.length) {
        let options = convertToOptions(fetchedCourse.data);
        setCourseOptions(options);
        setValue("module_id", options[0].value);

        if (editform) {
          let course = options.find(item => {
            if (item.value === data?.module_id) return item;
            return null;
          });
          setSelectedCourse(course);
          setValue("module_id", course?.value);
        }
      } else {
        setCourseOptions([]);
        setValue("module_id", undefined);
      }
    }
  }, [fetchedCourse, setValue, data, editform]);

  return (
    <form
      className="subscription-form-container"
      onSubmit={handleSubmit(
        editform ? handleClickUpdate()(handleCancel) : handleClickSubmit
      )}
    >
      <div className="row-container">
        <div className="col-container">
          <div className="row-container">
            <div className="fieldAndValidate">
              <label htmlFor="course">
                Subscription Module <span>*</span>
              </label>
              <CustomSelect
                id="module_id"
                required={true}
                register={register}
                name="module_id"
                value={selectedCourse || ""}
                handleChange={(e, data) => {
                  setValue("module_id", data.value);
                  handleSelectChange(e, data);
                }}
                options={coursesOptions}
                disabled={false}
              />
              {errors?.module_id?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate title">
              <InputField
                required
                type="text"
                label="Subscription Title"
                placeholder={"Enter the subscription title"}
                {...register("title", {
                  required: true
                })}
              />
              {errors?.title?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate period">
              <InputField
                required
                type="number"
                label="Period (Days)"
                placeholder={"Enter the period in days"}
                {...register("period", {
                  required: true
                })}
              />
              {errors?.period?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
          </div>
          <div className="row-container">
            <div className="fieldAndValidate title">
              <InputField
                required
                type="number"
                label="Discounted Amount"
                placeholder={"Enter the subscription cost after discount"}
                {...register("amount", {
                  required: true
                })}
              />
              {errors?.amount?.type === "required" && (
                <p>This field is required</p>
              )}
            </div>
            <div className="fieldAndValidate discount">
              <InputField
                type="number"
                label="Discount %"
                placeholder={"Enter the discount percentage"}
                {...register("discount")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row-container">
        <div className="button-wrapper">
          <Button
            type="submit"
            color="success"
            disabled={isLoading ? true : false}
            buttonName={
              isLoading ? "Submitting" : editform ? "Update Plan" : "Add Plan"
            }
          />
          {!editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Clear"
              clickHandler={() => handleClear()}
            />
          )}
          {editform && (
            <Button
              type="button"
              color="danger"
              buttonName="Cancel"
              clickHandler={() => (handleCancel ? handleCancel() : null)}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default SubscriptionForm;
